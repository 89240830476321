import * as S from './styles';

import { Field, reduxForm } from 'redux-form';
import {
  FieldBool,
  Input,
  InputDate,
  RadioList,
  Textarea,
} from 'components/Form';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';
import { Col, Row } from 'react-flexbox-grid';
import { Fields } from 'features/Financial';
import useFormValue from 'hooks/useFormValue';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openModalStoreCategory } from '../../../ModalStoreCategory';
import { addCurrency, removeCurrency } from 'lib/money-helpers';

function FormStorePosting({
  reopenModal,
  type,
  configs,
  handleSubmit,
  handleClose,
  change,
  moviment,
  getAccounts,
}) {
  const [hasCreditCard, setHasCreditCard] = useState(false);
  const dispatch = useDispatch();

  const isDebit = type === 2;

  const values = useFormValue([
    'people_id',
    'value',
    'date',
    'account_id',
    'category_id',
    'repetition_type',
    'repeatment_type',
    'recurrency_type_id',
    'installments',
    'description',
    'is_credit_card',
  ]);

  const repetitionCalculation = useMemo(() => {
    let text = '';
    const parsedValue = removeCurrency(values.value);

    if (
      values.value &&
      values.installments &&
      values.repetition_type === 'split'
    ) {
      return `* Serão lançadas ${values.installments} parcelas de ${addCurrency(
        parsedValue / values.installments
      )}`;
    }

    return text;
  }, [values]);

  const onNewCategory = (value) => {
    dispatch(
      openModalStoreCategory({
        isDebit,
        isCategory: true,
        data: {
          title: value.title,
        },
        onSuccess: (res) => {
          reopenModal({
            ...values,
            category_id: res.data.id,
          });
        },
        onClose: () => {
          reopenModal(values);
        },
      })
    );
  };

  const handleChangeRepetitionType = (value) => (e, type) => {
    if (type === value) {
      change('repetition_type', null);
    }
  };

  const { canShowInstallments, collumnRecurrencyField } = useMemo(() => {
    if (values.repetition_type === 'split') {
      return {
        canShowInstallments: true,
        collumnRecurrencyField: 6,
      };
    }

    return {
      canShowInstallments: false,
      collumnRecurrencyField: 12,
    };
  }, [values]);

  return (
    <form onSubmit={handleSubmit}>
      {configs.canShowRepeatmentType || !configs.isEditting ? (
        <Row>
          <Field
            maxLength={50}
            xs={12}
            label="Descrição"
            name="description"
            component={Input}
          />
        </Row>
      ) : null}
      <Row>
        <Field
          xs={6}
          valueFormat
          label="Valor"
          name="value"
          component={Input}
          prefix="R$ "
          inputProps={{
            decimalScale: 2,
          }}
          autoComplete="off"
        />
        <Field
          autoComplete={false}
          xs={6}
          label="Data"
          name={configs.date_field_name}
          component={InputDate}
        />
      </Row>

      <Row>
        <Col xs={12}>
          <S.Wrapinput>
            <Fields.Category
              creatable
              onNewOptionClick={onNewCategory}
              isDebit={isDebit}
              name="category_id"
              label="Categoria"
            />
          </S.Wrapinput>
        </Col>
      </Row>

      {isDebit ? (
        <Row>
          <FieldBool
            disabled={hasCreditCard}
            buttonTemplate
            xs={6}
            name="is_credit_card"
            label="Lançar no cartão de crédito"
            onChange={(value) => {
              if (!value) {
                change('account_id', null);
              }
            }}
          />
          <Fields.Account
            disabled={!values.is_credit_card}
            xs={6}
            type={2}
            name="account_id"
            label="Selecione o cartão"
            onLoadOptions={(options) => {
              if (!options.length <= 0) {
                setHasCreditCard(false);
              } else {
                setHasCreditCard(true);
              }
            }}
          />
        </Row>
      ) : (
        <Row>
          <Fields.Account
            xs={12}
            type={1}
            name="account_id"
            label="Selecione a conta bancária que receberá o crédito"
          />
        </Row>
      )}

      <Row>
        <Field
          xs={12}
          label="Observação"
          type="simple"
          name="notes"
          component={Textarea}
        />
      </Row>

      {configs.canShowRepeatmentType ? (
        <RadioList
          variant="simple"
          label="Esse lançamento de repete em outras datas:"
          name="repeatment_type"
          options={[
            { label: 'Editar apenas este lançamento', value: 'unic' },
            { label: 'Editar este e os próximos', value: 'forward' },
            {
              label: 'Editar todos os lançamentos (Mudará o passado)',
              value: 'all',
            },
          ]}
        />
      ) : null}

      {!configs.isEditing ? (
        <>
          <RadioList
            canRemoveSelf={false}
            variant="simple"
            label="Repetir"
            name="repetition_type"
            options={[
              { label: 'Não repete', value: 'unic' },
              { label: configs.repetition_type_label, value: 'recurrency' },
              { label: 'Lançamento parcelado', value: 'split' },
            ]}
          />
          <Row className="no-transition h-margin-top--15">
            {canShowInstallments ? (
              <Field
                type="number"
                xs={6}
                name="installments"
                component={Input}
                placeholder="Número de parcelas"
              />
            ) : null}
            <Fields.RecurrencyType
              disabled={
                !values.repetition_type || values.repetition_type === 'unic'
              }
              xs={collumnRecurrencyField}
            />
          </Row>
          {repetitionCalculation}
        </>
      ) : null}

      <ModalFooter>
        <Button type="button" color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Salvar
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({
  form: 'FormStorePosting',
  enableReinitialize: true,
  validate: (values, props) => {
    let errors = {};

    if (props.type === 2 && values.is_credit_card && !values.account_id) {
      errors.account_id = ['Campo obrigatório'];
    }

    return errors;
  },
})(FormStorePosting);
