import React, { useMemo } from 'react';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { Step } from 'containers/ModalRoot/ModalPayment/styles';
import Button from 'components/Button';
import { useModalUser } from '../../contexts/ModalUserContext';
import PlanItem from '../../components/PlanItem';
import { Infos } from '../../components/PlanItem/styles';
import { usePreview2 } from 'containers/ModalRoot/ModalContractExtra/pages/UserAndEmail/hooks/usePreview2';
import Loading from 'components/Loading';
import useFormValue from 'hooks/useFormValue';
import { useModalContractExtra } from 'containers/ModalRoot/ModalContractExtra/contexts/modalContractExtra';
import { addCurrency, removeCurrency } from 'lib/money-helpers';
import { ITEM_TYPES } from 'services/financialv2/contracts';

// import { Wrapper } from './styles';

function Preview() {
  const plan = useFormValue('plan');
  const { data, isFetching } = usePreview2();
  const { handleClose, setPage } = useModalUser();
  const { onSuccess, item, changeDate } = useModalContractExtra();

  const text = useMemo(() => {
    switch (item) {
      case ITEM_TYPES.DWV:
        return 'Integração com DWV';
      case ITEM_TYPES.FINANCIAL:
        return 'Integração com o Financeiro';
      default:
        return 'Integração com Órulo';
    }
  }, [item]);

  const handleProgress = async () => {
    // Se ele escolher usar o saldo tem que já
    if (plan === 2) {
      await changeDate(1);
      if (onSuccess) onSuccess();
      return null;
    }

    setPage('Payment');
  };

  // Retorna numero em float
  const costWithoutCurrency = removeCurrency(data?.total_cost);

  // Pega os valor do parcelamento
  const installmentsValue = useMemo(() => {
    return addCurrency(costWithoutCurrency / data?.max_Installments);
  }, [data]);

  return (
    <ModalTemplate
      title="Alteração de contrato"
      text={text}
      handleClose={handleClose}
      titleActions={
        <Step>
          Etapa
          <br />
          1/2
        </Step>
      }
    >
      {isFetching ? (
        <Loading isVisible isBlock />
      ) : (
        <>
          <p>
            Atualmente seu contrato terminará em <strong>{data?.end_at}</strong>
            , daqui <strong>{data?.end_in}</strong> dias.
          </p>
          <p>
            O novo recurso adicionado, tem um custo de{' '}
            <strong>{data?.monthly_cost}</strong> ao mês.
          </p>
          <p>
            O total deste novo recurso até seu prazo final, tem um custo de{' '}
            <strong>{data?.total_cost}</strong>
          </p>

          <Infos className="h-margin-top--15">
            <PlanItem
              label="1 - Fazer um pagamento hoje e preservar o prazo final"
              text={
                <>
                  O valor pode ser pago em <strong>1x</strong> no Pix
                  <br />
                  ou em até <strong>{data?.max_Installments}x</strong> de{' '}
                  <strong>{installmentsValue}</strong> no cartão
                </>
              }
              value={1}
              price={
                <>
                  Valor à pagar
                  <br /> {data?.total_cost}
                </>
              }
            />
            <PlanItem
              label="2 - Usar meu saldo e reduzir a data final do meu crédito"
              text={
                <>
                  Utilizar seu saldo irá reduzir sua data final em
                  <br />
                  Sua nova data de crédito final irá mudar para
                </>
              }
              value={2}
              price={
                <>
                  -{data?.change_days} dias
                  <br /> {data?.new_end_at}
                </>
              }
            />
          </Infos>

          <ModalFooter>
            <Button type="button" color="white" onClick={handleClose}>
              Voltar
            </Button>
            <span className="h-flex__cell--grow" />
            <Button type="button" color="success" onClick={handleProgress}>
              Prosseguir
            </Button>
          </ModalFooter>
        </>
      )}
    </ModalTemplate>
  );
}

export default Preview;
