import styled, { css } from 'styled-components';

export const WrapperTable = styled.div`
  .Table {
    filter: drop-shadow(-1px 1px 5px rgba(0, 0, 0, 0.1));
  }

  .Table tr th {
    background: #f5f5f6;
    padding: 15px;
  }

  .Table tr td {
    padding: 15px;
  }
`;

export const Description = styled.div`
  display: flex;
  align-items: center;
`;

export const Ball = styled.span`
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
  width: 12px;
  height: 12px;
  background: ${(p) => p.color};
  border-radius: 50%;
`;

const themeSituation = ({ variant }) => {
  switch (variant) {
    case 'Vencido':
      return css`
        border-color: ${(p) => p.theme.colors.danger};
      `;
    case 'Aberto':
      return css`
        border-color: #ffbd00;
      `;
    default:
      return css`
        border-color: #31d084;
      `;
  }
};

export const Situation = styled.button`
  display: block;
  width: 80px;
  height: 30px;
  font-size: 14px;
  margin: 0 auto;
  cursor: pointer;

  border: 1px solid #d3dceb;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background: #fff;

  svg {
    font-size: 16px;
    margin-top: -2px;
  }
`;

export const Actions = styled.div`
  display: flex;
  margin: -5px;
`;

export const Action = styled.button`
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  font-size: 20px;
  cursor: pointer;
  border-radius: 50%;

  svg {
    position: relative;
    left: 1px;
  }

  :hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

export const Date = styled.span`
  color: ${(p) => (p.situation === 'Vencido' ? 'red' : '#000')};
`;

export const Text = styled.div`
  text-align: center;
`;
