import api from 'services/index';
import { responseMessage } from 'lib/service-helpers';

/**
 * Adicionar uma conta
 */
export const storeAccount = ({
  title,
  icon_id,
  type,
  limit,
  closing_day,
  due_day,
  related_account_id,
  initial_balance,
}) => {
  const message =
    type === 1 || type === '1'
      ? 'Conta adicionada'
      : 'Cartão de crédito adicionado';

  return api
    .create('financial/accounts', {
      title,
      icon_id,
      type,
      limit,
      closing_day,
      due_day,
      related_account_id,
      initial_balance,
    })
    .then(responseMessage(message));
};

/**
 * Lista
 * @param type
 * @param offset
 * @param limit
 */
export const indexAccount = ({ type = 1, offset = 1, limit = 50 }) => {
  let filter = {
    type,
  };

  if (type === 'all') {
    delete filter.type;
  }

  return api.get('financial/accounts', {
    filter,
    include: 'icon',
    sort: '-updated_at',
    limit,
    offset,
  });
};

/**
 * Pega uma conta em específico
 * @param id
 */
export const showAccount = ({ id }) => api.getOne('financial/accounts', id);

/**
 * Atualiza uma conta
 * @param id
 * @param type
 * @param title
 * @param limit
 * @param icon_id
 */
export const updateAccount = ({ id, type, title, limit, icon_id }) => {
  const message =
    type === 1 || type === '1'
      ? 'Conta atualizada'
      : 'Cartão de crédito atualizado';

  return api
    .update('financial/accounts', {
      id,
      title,
      icon_id,
      limit,
    })
    .then(responseMessage(message));
};

/**
 * Atualiza o valor total
 * @param type
 * @param limit
 * @param id
 * @param new_balance
 */
export const updateAccountBalance = ({ type, limit, id, new_balance }) => {
  if (type === 1) {
    return api
      .patch(`financial/accounts/${id}/balance`, {
        new_balance,
      })
      .then(responseMessage('Saldo da conta bancária atualizado'));
  }

  return api
    .update('financial/accounts', {
      id,
      limit,
    })
    .then(responseMessage('Limite do cartão de crédito atualizado'));
};

/**
 * Deleta uma conta
 * @param id
 * @param type
 */
export const destroyAccount = ({ id, type }) => {
  const isAccount = type === '1' || type === 1;
  const message = isAccount ? 'Conta excluída' : 'Cartão de crédito excluído';

  return api.delete('financial/accounts', id).then(responseMessage(message));
};

/**
 * Transferir valor de uma conta pra outra
 * @param origin_id
 * @param destiny_id
 * @param value
 * @param date
 */
export const transferValue = ({ origin_id, destiny_id, value, date }) =>
  api.create('financial/accounts/transfer-value', {
    origin_id,
    destiny_id,
    value,
    date,
  });

/**
 * Para listar faturas do ano
 * @param id
 * @param year
 */
export const indexCreditCardBills = ({ id, year }) =>
  api.getList(`financial/accounts/${id}/credit-card-bills`, {
    year,
  });

/**
 * Detalhe da fatura
 * @param id
 * @param creditCardBill
 */
export const showCreditCardBills = ({ id, creditCardBill }) =>
  api.getList(`financial/accounts/${id}/credit-card-bills/${creditCardBill}`);

/**
 * Pagar uma fatura
 */
export const payCreditCardBill = ({ id, creditCardBill, ...values }) =>
  api.create(
    `financial/accounts/${id}/credit-card-bills/${creditCardBill}`,
    values
  );
