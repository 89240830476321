import { Field, reduxForm } from 'redux-form';
import { Input } from 'components/Form';
import { useRequest } from 'hooks/useRequest';
import * as slidesService from 'services/sites/slides';
import Loading from 'components/Loading';
import React, { useCallback, useEffect } from 'react';
import ListOptions from 'components/ListOptions';
import { minMax } from 'lib/formHelpers';
import { Form, Item } from './styles';
import debounce from 'lodash/debounce';

function FormConfigSlide({ handleSubmit, submit, initialize, change }) {
  const { meta, isFetching } = useRequest({
    request: slidesService.getList,
  });

  useEffect(() => {
    change('slide_home_interval', meta?.slide_home_interval);
  }, [meta, initialize]);

  // Debounce definido corretamente
  const debouncedSubmit = useCallback(
    debounce((values) => {
      submit(values);
    }, 500),
    [handleSubmit]
  );

  const handleIntervalChange = (event, newValue) => {
    console.log('value: ', newValue);
    change('slide_home_interval', newValue); // Atualiza o Redux Form
    debouncedSubmit({ slide_home_interval: newValue }); // Submete com debounce
  };

  if (isFetching) {
    return <Loading isVisible isFullScreen />;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <ListOptions className="h-margin-bottom--15">
        <ListOptions.item
          title="Tempo de transição entre slides"
          text="Defina o tempo de troca das imagens"
          renderOptions={() => (
            <Item className="h-flex">
              <Field
                name="slide_home_interval"
                type="number"
                component={Input}
                style={{ width: 65 }}
                normalize={minMax(1, 60)}
                onChange={handleIntervalChange} // Chama a função de mudança
              />
              <span>Segundos</span>
            </Item>
          )}
        />
      </ListOptions>
    </Form>
  );
}

export default reduxForm({
  form: 'FormConfigSlides',
  enableReinitialize: true,
  onSubmit: (values) => {
    return slidesService.updateConfig(values);
  },
})(FormConfigSlide);
