import * as S from './styles';
import { useModalPosting } from '../../../../hooks/useModalPosting';
import { useModalMovimentDestroy } from '../../../../hooks/useModalMovimentDestroy';
import { MdOutlineDelete, MdOutlineMode } from 'react-icons/all';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';
import { useModalMovimentDetail } from '../../../../hooks/useModalMovimentDetail';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import * as movimentsService from '../../../../services/moviments';

export function DetailActions({ moviment, onSuccess, handleClose }) {
  const dispatch = useDispatch();
  const { handleOpenModalPosting } = useModalPosting();
  const { handleOpenModalMovimentDetail } = useModalMovimentDetail();
  const { handleOpenModalMovimentDestroy } = useModalMovimentDestroy();

  const openCancelMoviment = ({ moviment, onSuccess }) => {
    if (!moviment.can_edit) return null;

    dispatch(
      openConfirmation({
        title: 'Cancelar pagamento',
        text: (
          <>
            Cancelar esse pagamento vai reabrir o lançamento.
            <br />
            Tem certeza de que deseja continuar?
          </>
        ),
        submitButtonText: 'Efetuar cancelamento',
        request: () => {
          return movimentsService.removePayment({
            movimentId: moviment?.id,
            postingId: moviment?.posting_id,
          });
        },
        onSuccess,
      })
    );
  };

  const handleCancel = () => {
    if (onSuccess) {
      onSuccess();
    } else {
      // Atualiza a listagem
      dispatch(submit('FilterPostings'));
    }

    // Fecha a modal primeiro pra poder chamar
    // o componentDidMount desse componente
    handleClose();

    // Abre em seguida com os dados atualizados
    // Sempre tem que abrir essa modal
    handleOpenModalMovimentDetail({
      movimentId: moviment.id,
      postingId: moviment.posting_id,
      onSuccess,
    });
  };

  if (moviment.payment_date) {
    return (
      <S.Actions>
        <S.Link
          onClick={() => {
            openCancelMoviment({
              moviment,
              onSuccess: onSuccess || handleCancel,
            });
          }}
        >
          Defazer pagamento
        </S.Link>
      </S.Actions>
    );
  }

  return (
    <S.Actions>
      <S.Action
        onClick={() => {
          handleOpenModalPosting({
            movimentId: moviment.id,
            postingId: moviment.posting_id,
            onSuccess: () => {
              dispatch(submit('FilterPostings'));
            },
            onCancel: handleCancel,
          });
        }}
      >
        <MdOutlineMode />
      </S.Action>
      <S.Action
        onClick={() => {
          handleOpenModalMovimentDestroy({
            movimentId: moviment.id,
            postingId: moviment.posting_id,
            onSuccess: () => {
              dispatch(submit('FilterPostings'));
            },
            onCancel: handleCancel,
          });
        }}
      >
        <MdOutlineDelete className="h-color--danger" />
      </S.Action>
    </S.Actions>
  );
}
