import React, { useEffect } from 'react';
import { ModalTemplate } from 'components/Modal';
import Modal from 'react-modal';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-flexbox-grid';
import { FieldBool, FormLabel, Input, Select, Textarea } from 'components/Form';
import ModalFooter from '../../../components/Modal/components/ModalFooter';
import Button from 'components/Button';
import { TYPES_MENU } from 'pages/Site/constants';
import * as navigationsService from 'services/sites/navigations';
import { fetchMenu } from 'pages/Site/pages/Layout/pages/Menu/module';

// import { Wrapper } from './styles';

function ModalNegotiationPageConfig({
  modalConfig,
  isOpen,
  modalType,
  handleClose,
  handleSubmit,
  menu,
  submitting,
  initialize,
}) {
  const isEditting = !!(menu && menu.id);

  const setInitialValues = async () => {
    const { data } = await navigationsService.getNegotiateProperty(menu.id);

    initialize({
      type: TYPES_MENU.LINK,
      opens_link_on_page: true,
      ...data,
      transaction_option: data?.transaction_option
        ? parseInt(data?.transaction_option, 10)
        : 3,
    });
  };

  useEffect(() => {
    setInitialValues();
  }, []);

  // Use formValueSelector to get the current value of is_terms_shown
  const selector = formValueSelector('FormNegotiatonPageConfig');
  const isTermsShown = useSelector((state) =>
    selector(state, 'is_terms_shown')
  );

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Configurar página" handleClose={handleClose}>
        <p className="h-margin-bottom--15">
          Você pode criar uma página a partir de um filtro do seu site, por
          exemplo, quando quiser criar uma página de um resultado específico. Ou
          então, pode adicionar também links para páginas externas.
        </p>
        <form onSubmit={handleSubmit}>
          <Row>
            <Field
              xs={6}
              label="Título do menu"
              name="label"
              component={Input}
            />
            <FieldBool
              xs={6}
              label="Abrir link na:"
              name="opens_link_on_page"
              options={[
                { label: 'Mesma página', value: true },
                { label: 'Outra página', value: false },
              ]}
            />
          </Row>
          <Row style={{ marginBottom: '-15px' }}>
            <Col xs={12}>
              <FormLabel>Tipo do negócio</FormLabel>
              <p>Selecione os tipos de negócio disponíveis na página</p>
            </Col>
            <Field
              canRemoveSelf={false}
              className="h-margin-top--10"
              xs={6}
              buttonTemplate
              name="transaction_option"
              options={[
                { label: 'Venda', value: 1 },
                { label: 'Aluguel', value: 2 },
                { label: 'Venda e Aluguel', value: 3 },
              ]}
              component={Select}
            />
          </Row>
          <Row style={{ marginTop: '15px' }}>
            <Col xs={12}>
              <FormLabel>Ativar link com termos de intermediação?</FormLabel>
              <p>
                Se ativado será exibido um link com os termos no formulário de
                captação
              </p>
            </Col>
            <Field
              canRemoveSelf={false}
              className="h-margin-top--10"
              xs={6}
              buttonTemplate
              name="is_terms_shown"
              options={[
                { label: 'Ativado', value: true },
                { label: 'Desativado', value: false },
              ]}
              component={Select}
            />
          </Row>

          {isTermsShown && (
            <Row>
              <Field
                xs={12}
                type="complex"
                label="Descrição dos termos"
                name="intermediation_terms"
                placeholder="Digite a descrição"
                component={Textarea}
                height={200}
              />
            </Row>
          )}

          <ModalFooter>
            <Button color="white" colorText="primary" onClick={handleClose}>
              Cancelar
            </Button>
            <span className="h-flex__cell--grow" />
            <Button type="submit" color="success" disabled={submitting}>
              {isEditting ? 'Editar' : 'Adicionar'}
            </Button>
          </ModalFooter>
        </form>
      </ModalTemplate>
    </Modal>
  );
}

export default reduxForm({
  form: 'FormNegotiatonPageConfig',
  onSubmit: (values) => {
    return navigationsService.createNegotiateProperty(values);
  },
  onSubmitSuccess: (response, dispatch, props) => {
    dispatch(fetchMenu());
    props.handleClose();
  },
})(ModalNegotiationPageConfig);
