import { createModule } from 'lib/reducer-helpers';
import * as financialService from '../services/financialModuleStatus';

const createAction = createModule('statusGuard');

// Actions
const REQUEST = createAction('REQUEST');
const RECEIVE = createAction('RECEIVE');

const initialState = {
  status: null,
  isFetching: true,
};

export const statusSelector = (state) => state.statusGuard;

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return { ...state, isFetching: true };
    case RECEIVE:
      return { ...state, status: action.status, isFetching: false };
    default:
      return state;
  }
}

export const ActionsStatusGuard = {
  request: () => ({ type: REQUEST }),
  receive: (status) => ({ type: RECEIVE, status }),
};

export const fetchStatusGuard = () => async (dispatch) => {
  dispatch(ActionsStatusGuard.request());

  const { data } = await financialService.getFinancialModuleStatus();

  dispatch(ActionsStatusGuard.receive(data.status));
};

export const askStatusGuard = () => async (dispatch) => {
  await financialService.financialAsk();
  dispatch(fetchStatusGuard());
};

export const financialStartFreeTrial = () => async (dispatch) => {
  await financialService.startFreeTrial();
  dispatch(fetchStatusGuard());
};
