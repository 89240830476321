import React from 'react';
import TableSort from 'components/TableSort';
import useCheckbox from 'hooks/useCheckbox';
import Check from 'components/Check';
import { openModalPerson } from 'modules/modal';
import { useDispatch } from 'react-redux';
import { FaWhatsapp } from 'react-icons/fa';
import Button from 'components/Button';
import * as S from './styles';
import { plural } from 'lib/text';
import { updateIsFavorite } from 'services/people';
import { whatsappSendUrl } from 'lib/share-helpers';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import Alert from 'react-s-alert';

// import { Wrapper } from './styles';

function TablePeople({ data, meta, fetchData }) {
  const dispatch = useDispatch();

  const {
    checkedItems,
    isChecked,
    isAllItemsChecked,
    handleToggle,
    handleToggleAll,
    clear,
  } = useCheckbox([]);

  const handleClick = (person, params) => () => {
    dispatch(
      openModalPerson(person, {
        ...params,
        afterClose: () => fetchData({}),
      })
    );
  };

  const removeFavorites = () => {
    return updateIsFavorite({
      peopleId: checkedItems,
      isFavorite: false,
    });
  };

  const handleRemoveAllSelecteds = () => {
    dispatch(
      openConfirmation({
        title: 'Deseja remover os favoritos',
        text: '',
        request: removeFavorites,
        onSuccess: () => {
          // Atualiza a lista de favoritos
          fetchData({});

          // Limpa os selecionados
          clear();

          Alert.success('Clientes removidos');
        },
      })
    );
  };

  return (
    <>
      <S.Header>
        <div>
          <strong>
            {checkedItems.length <= 0
              ? 'Nenhum selecionado'
              : plural(
                  checkedItems.length,
                  `(1) Cliente selecionado`,
                  `(${checkedItems.length}) Clientes selecionados`,
                  false
                )}
          </strong>
          <Button
            disabled={checkedItems.length <= 0}
            color="danger"
            onClick={handleRemoveAllSelecteds}
          >
            Remover
          </Button>
        </div>
      </S.Header>
      <TableSort
        data={data}
        meta={meta}
        fetchData={fetchData}
        renderHeader={() => (
          <tr>
            <th className="Table__cell--small">
              <Check
                checked={isAllItemsChecked(data)}
                onClick={() => handleToggleAll(data)}
              />
            </th>
            <th width={250} align="left">
              Cliente
            </th>
            <th width={300} align="center">
              Última interação
            </th>
            <th className="Table__cell--small" />
          </tr>
        )}
        renderItem={(data) => {
          const whatsappUrl = whatsappSendUrl(data.whatsapp_number, '', '');

          return (
            <tr>
              <td
                className="h-pointer Table__cell--small"
                onClick={() => handleToggle(data.id)}
              >
                <Check checked={isChecked(data.id)} />
              </td>
              <td
                align="left"
                className="h-pointer"
                onClick={handleClick(data, {
                  initialPage: 'Properties',
                })}
              >
                {data?.name}
              </td>
              <td
                align="center"
                className="h-pointer"
                onClick={handleClick(data)}
              >
                {data?.interacted_at}
              </td>
              <td className="Table__cell--small">
                <Button
                  color="white"
                  size="small"
                  className="btn"
                  tagName="a"
                  href={whatsappUrl}
                  target="_blank"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <FaWhatsapp color="#31D084" /> Whatsapp
                </Button>
              </td>
            </tr>
          );
        }}
      />
    </>
  );
}

export default TablePeople;
