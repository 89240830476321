import styled from 'styled-components';

export const Form = styled.form`
  .FormGroup {
    margin-bottom: 0;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
