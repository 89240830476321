import api from 'services';
import { responseMessage } from 'lib/service-helpers';
import * as featuredListsService from 'services/sites/featuredLists';

export const transformResponse = (res) => {
  let options = res?.data?.portal_real_estate_options;

  if (options?.length <= 0 || !options) {
    options = {};
  }

  res.data.portal_real_estate_options = options;
  return res;
};

export const getOne = (id) =>
  api.getOne(`properties/partial/${id}/publish`, '').then(transformResponse);

export const update = async ({ id, ...data }) => {
  let _data = { ...data };

  const { data: dataPropertyPublish } =
    await featuredListsService.propertyPublishForm();

  if (!dataPropertyPublish.should_show_featured_area) {
    _data.is_featured = false;
  }

  if (!data.is_network_published) {
    _data.is_network_published = false;
  }

  if (!data.is_should_send_owner_report) {
    _data.should_send_owner_report = null;
    _data.email = null;
  }

  return api.patch(`properties/partial/${id}/publish`, _data);
};

export const sendEmail = ({ id, ...data }) =>
  api
    .create(`properties/${id}/forwardings/owners`, data)
    .then(responseMessage('E-mail enviado'))
    .catch(responseMessage('Erro ao enviar E-mail'));
