export const useConfigAjustAccount = ({ type }) => {
  if (type === 1) {
    return {
      titleModal: 'Ajustar Saldo',
      fieldLabel: 'Novo saldo da conta bancária',
      fieldName: 'new_balance',
    };
  }

  return {
    titleModal: 'Ajustar Limite',
    fieldLabel: 'Novo limite',
    fieldName: 'limit',
  };
};
