import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import _ from 'lodash';
// Services
import * as peopleService from 'services/people';
import * as characteristicsService from 'services/characteristics';
import * as establishmentsService from 'services/establishments';
import * as typesService from 'services/types';
import * as condosService from 'services/condos';
import * as profileSearchService from 'services/profileSearch';

import createDataReducer from 'modules/data';
import createLocationReducer, {
  actionsCreators as createActionCreators,
  thunksCreator as createThunks,
} from 'modules/location';

export const {
  actionCreators: peopleActions,
  reducer: peopleReducer,
  selectors: peopleSelector,
} = createDataReducer('profile/people');

export const {
  actionCreators: condosActions,
  reducer: condosReducer,
  selectors: condosSelector,
} = createDataReducer('profile/condos');

export const {
  actionCreators: typesAndSubtypesActions,
  reducer: typesAndSubtypesReducer,
  selectors: typesAndSubtypesSelector,
} = createDataReducer('profile/typesAndSubtypes');

export const {
  actionCreators: characteristicsActions,
  reducer: characteristicsReducer,
  selectors: characteristicsSelector,
} = createDataReducer('profile/characteristics');

export const {
  actionCreators: condoCharacteristicsActions,
  reducer: condoCharacteristicsReducer,
  selectors: condoCharacteristicsSelector,
} = createDataReducer('profile/condoCharacteristics');

export const {
  actionCreators: establishmentsActions,
  reducer: establishmentsReducer,
  selectors: establishmentsSelector,
} = createDataReducer('profile/establishments');

export const locationReducer = createLocationReducer('profile/location');
export const locationActions = createActionCreators('profile/location');
export const locationThunks = createThunks('profile/location');

// Form Selectors
const selector = formValueSelector('ProfileForm');

// Campo que contem o valor tanto de um subtype quanto de um type
const getTypeOrSubtypeId = (state) => selector(state, 'type_or_subtype_id');

/**
 * Seleciona um type
 * @return type
 */
export const typeSelector = createSelector(
  [getTypeOrSubtypeId, typesAndSubtypesSelector.getAll],
  (typeOrSybtypeId, typesAndSubtypes) => {
    if (!typeOrSybtypeId) return null;

    let type = {};

    typesAndSubtypes.every((typeAndSubtype) => {
      type = typeAndSubtype.is_type ? typeAndSubtype : type;
      if (typeAndSubtype.id === typeOrSybtypeId) return false;
      return true;
    });

    return type;
  }
);

export const roomsSelector = createSelector([typeSelector], (currentType) => {
  try {
    return currentType.rooms_fields;
  } catch {
    return null;
  }
});

export const measuresSelector = createSelector(
  [typeSelector],
  (currentType) => {
    try {
      return currentType.area_fields;
    } catch {
      return null;
    }
  }
);

export default combineReducers({
  people: peopleReducer,
  characteristics: characteristicsReducer,
  condoCharacteristics: condoCharacteristicsReducer,
  establishments: establishmentsReducer,
  typesAndSubtypes: typesAndSubtypesReducer,
  condos: condosReducer,
  location: locationReducer,
});

export const fetchPeople = (params) => (dispatch) => {
  dispatch(peopleActions.request());

  return peopleService.getAll(params).then((res) => {
    dispatch(peopleActions.receive(res.data));
    return res;
  });
};

export const fetchCharacteristics = (params) => (dispatch) => {
  if (!params?.type_id) return false;

  dispatch(characteristicsActions.request());

  return characteristicsService.getList(params).then((res) => {
    dispatch(characteristicsActions.receive(res.data));
    return res;
  });
};

export const fetchCondoCharacteristics = (params) => (dispatch) => {
  if (!params?.type_id) return false;

  dispatch(condoCharacteristicsActions.request());

  return condosService.getCondosCharacteristics(params).then((res) => {
    dispatch(condoCharacteristicsActions.receive(res.data));
    return res;
  });
};

export const fetchEstablishments = (params) => (dispatch) => {
  dispatch(establishmentsActions.request());

  return establishmentsService.getList(params).then((res) => {
    dispatch(establishmentsActions.receive(res.data));
    return res;
  });
};

export const fetchCondos = (params) => (dispatch) => {
  dispatch(condosActions.request());

  return condosService.getList(params).then((res) => {
    dispatch(condosActions.receive(res.data));
    return res;
  });
};

/**
 * Busca os tipos e subtipos juntos
 * @param params
 * @return {Promise}
 */
export const fetchTypeAndSubtypes = (params) => (dispatch) => {
  dispatch(typesAndSubtypesActions.request());

  return typesService.getGroupedTypesSubtypes(params).then(({ data }) => {
    dispatch(typesAndSubtypesActions.receive(data));
    return data;
  });
};

export const handleSubmit =
  ({ people_id, ...values }, isSearching = false) =>
  (dispatch) => {
    let newValues = values;
    const isEditting = values.id;

    if (newValues.establishments) {
      newValues = {
        ...newValues,
        establishments: _.pickBy(newValues.establishments, _.identity),
      };
    }

    if (newValues.characteristics) {
      newValues = {
        ...newValues,
        characteristics: _.pickBy(newValues.characteristics, _.identity),
      };
    }

    if (newValues.condo_characteristics) {
      newValues = {
        ...newValues,
        condo_characteristics: _.pickBy(
          newValues.condo_characteristics,
          _.identity
        ),
      };
    }

    if (newValues.neighborhoods) {
      newValues = {
        ...newValues,
        neighborhoods: _.pickBy(newValues.neighborhoods, _.identity),
      };
    }

    if (isSearching) {
      return { data: newValues };
    }

    if (isEditting) {
      return profileSearchService.update(people_id, newValues);
    }

    return profileSearchService.create(people_id, newValues);
  };

export const getInitialData = () => (dispatch) => {
  return {
    country_id: 'cfe9db6d-3ad6-4c9f-abf3-4ffcef935054',
    state_id: '3d370463-39ba-4bcb-b93a-0af29de43879',
  };
};
