// import * as S from './styles'
import { Field } from 'redux-form';
import { FormLabel } from 'components/Form';
import * as S from './styles';

function LabelComponent({ label, options, input }) {
  const handleClick = (value) => {
    if (value === input.value) {
      return input.onChange(null);
    }

    return input.onChange(value);
  };

  return (
    <S.FormGroup>
      <FormLabel>{label}</FormLabel>
      <S.Options>
        {options.map(({ label, value }) => (
          <S.Item
            key={value}
            isChecked={value === input.value}
            onClick={() => {
              handleClick(value);
            }}
          >
            {label}
          </S.Item>
        ))}
      </S.Options>
    </S.FormGroup>
  );
}

export function FieldLabel({ label, name, options }) {
  return (
    <Field
      label={label}
      name={name}
      options={options}
      component={LabelComponent}
    />
  );
}
