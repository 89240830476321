import { useRequest } from 'hooks/useRequest';
import * as facebookService from 'services/settings/facebook';

export const usePages = () => {
  const { data, error, isFetching, setData, fetchData } = useRequest({
    request: (accessToken) =>
      facebookService.getPagesLeadsWithForms({ access_token: accessToken }),
  });

  return {
    pages: data,
    errorPages: error,
    isFetchingPages: isFetching,
    fetchPages: fetchData,
    setPages: setData,
  };
};
