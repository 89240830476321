import Button from 'components/Button';
import CondoMultiSelect from 'containers/CondoMultiSelect';
import ReferenceSearchField from 'containers/ReferenceSearchField';
import { isEmpty } from 'lib/object-helpers';
import { castArray } from 'lodash';
import { openModalPropertySearch } from 'modules/modal';
import { clearProperties, networkSelector } from 'modules/propertySearch';
import qs from 'qs';
import { useEffect, useMemo } from 'react';
import { MdInfo } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { reduxForm } from 'redux-form';
import FieldPersonOrulo from '../FieldPersonOrulo';
import FieldStreetAddress from '../FieldStreetAddress';
import { Title, Wrapper } from './styles';
import FieldRealEstates from 'containers/FieldRealEstates';

const HasExpiryDateTitle = () => (
  <>
    Pesquisa por referência{' '}
    <span
      data-tip={`
        Você pode procurar por mais<br />
        de uma referência ao mesmo tempo<br />
        separando-as por uma vírgula. Ex: 101, 102.<br />
        Ou então, por uma referência exata. Ex: "101", "102".`}
      data-place="right"
      className="h-color--secondary"
    >
      <MdInfo />
    </span>
  </>
);

const changeField = ({ history, queryString, callBack }) => {
  const params = new URLSearchParams(history.search || queryString);

  if (callBack) callBack(params);

  params.set('limit', 50);
  params.set('offset', 1);
  params.set('sort', '-calculated_price');

  history.replace({
    pathname: history.location.pathname,
    search: params.toString(),
  });
};

function CurrentSearch({ initialize, change, query, redirectTo }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const network = useSelector(networkSelector);

  const handleChangeCondo = (selectedCondos) => {
    const objSelectedCondos = {
      filter: {
        condominium_id: selectedCondos,
      },
    };

    const queryString = qs.stringify(objSelectedCondos);

    change('reference', '');

    changeField({
      history,
      queryString,
      callBack: (params) => {
        if (network === 'orulo') {
          params.set('filter[on_network][]', 'self');
          params.set('filter[on_network][]', 'orulo');
        }

        if (network === 'dwv') {
          params.set('filter[on_network][]', 'self');
          params.set('filter[on_network][]', 'dwv');
        }
      },
    });
  };

  const handleChangeStreetAddress = (input) => {
    changeField({
      history,
      callBack: (params) => {
        params.set('filter[street_address]', input.value);
      },
    });
  };

  // Lida com o evento quando pesquisa por referência
  const handleChangeReference = (input) => {
    changeField({
      history,
      callBack: (params) => {
        params.set('filter[reference]', input.value);
      },
    });
  };

  const handleChangeRealEstate = (value) => {
    const params = new URLSearchParams(window.location.search);

    if (value) {
      params.set('filter[real_estate_id]', value);
    } else {
      params.delete('filter[people_id]');
    }

    history.replace({
      pathname: history.location.pathname,
      search: params.toString(),
    });
  };

  // Lida com o click pra abrir a modal de pesquisa
  const handleClickSearch = () => {
    dispatch(
      openModalPropertySearch({
        isPortals: true,
        filter: query.filter,
        redirectTo,
        onSearch: () => {
          change('reference', '');
        },
      })
    );
  };

  // Limpa todos os campos da pesquisa
  const handleClearSearch = () => {
    history.replace({
      pathname: history.location.pathname,
      search: '',
    });

    dispatch(clearProperties());
    initialize({});
  };

  useEffect(() => {
    if (query.filter?.condominium_id) {
      // Inicializa corretamente o campo de condomínio do formulário
      initialize({ condominium_id: castArray(query.filter?.condominium_id) });
    }
  }, [query]);

  // Verifica se está buscando por condomínio
  const isSearchingByCondo = query?.filter?.condominium_id;

  // Verifica se está pesquisando por caracterísiticas
  // Se tiver o count e não tiver vindo no filtro o condominium_id
  const isSearchingByCharacteristics =
    !!query?.filter?.count && !isSearchingByCondo;

  // Verifica se está buscando por referencia
  const isSearchingByReference = !!query?.filter?.reference;
  const isSearchingByStreetAddress = !!query?.filter?.street_address;

  // Verifica se pode mostrar o campo de imobiliárias
  const canShowRealEstates = network === 'guru';

  // Verifica se pode mostrar o Select de condomínio
  const canShowCondo =
    network === 'orulo' ||
    network === 'dwv' ||
    (network !== 'guru' &&
      !isSearchingByReference &&
      !isSearchingByStreetAddress &&
      !isSearchingByCharacteristics);

  // Verifica se pode mostrar campo de endereço
  // 1- Quando estiver sem nenhuma pesquisa
  // 2 - Quando estiver buscando por endereço e não esteja buscando por
  // referencia e condomínio
  const canShowStreetAddress =
    isEmpty(query?.filter) ||
    (isSearchingByStreetAddress &&
      !isSearchingByReference &&
      !isSearchingByCondo);

  // Verifica se pode mostrar o botão de limpar pesquisa
  const canShowClearSearch = !!query?.filter;

  // Verifica se pode mostrar o campo de pesquisa por referencia
  const canSearchByReference = isEmpty(query?.filter) || isSearchingByReference;

  const title = useMemo(() => {
    if (isSearchingByReference) return <HasExpiryDateTitle />;
    if (isSearchingByCondo) return 'Pesquisa por condomínio';
    if (isSearchingByCharacteristics) return 'Pesquisa por características';
    if (isSearchingByStreetAddress) return 'Pesquisa por logradouro';

    return 'Pesquisa';
  }, [
    isSearchingByCharacteristics,
    isSearchingByReference,
    isSearchingByCondo,
    isSearchingByStreetAddress,
  ]);

  return (
    <Wrapper>
      <Title>{title}</Title>
      {canShowRealEstates && (
        <FieldRealEstates
          filter={query.filter}
          onChange={handleChangeRealEstate}
        />
      )}

      {canSearchByReference && (
        <ReferenceSearchField handleSearch={handleChangeReference} />
      )}
      {canShowStreetAddress && (
        <FieldStreetAddress handleSubmit={handleChangeStreetAddress} />
      )}
      <FieldPersonOrulo network={network} />
      {canShowCondo && (
        <CondoMultiSelect
          network={network}
          filter={{ status: 1 }}
          onChange={handleChangeCondo}
        />
      )}

      {!isSearchingByReference && (
        <>
          {!isEmpty(query) ? (
            <Button
              block
              size="medium"
              color="success"
              onClick={handleClickSearch}
            >
              Filtrar resultado da pesquisa
            </Button>
          ) : (
            <Button
              size="medium"
              block
              color="success"
              onClick={handleClickSearch}
            >
              Pesquisar por características
            </Button>
          )}
        </>
      )}

      {canShowClearSearch && (
        <Button block size="medium" onClick={handleClearSearch} color="danger">
          Limpar pesquisa
        </Button>
      )}
    </Wrapper>
  );
}

export default reduxForm({
  form: 'AsideFilterSearch',
})(CurrentSearch);
