import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { PROFILE, SITUATIONS, TYPE_NETWORK } from 'constants/options';

/**
 * Retorna as áreas primárias
 */
export const getPrimaryAreas = (areas) => {
  areas.filter(
    ({ name }) => ['ground_total_area', 'total_area'].indexOf(name) !== 0
  );
};

export const getArea = (areas) => {
  try {
    if (areas.total_area) return 'total_area';
    if (areas.ground_total_area) return 'ground_total_area';
    return null;
  } catch {
    return null;
  }
};

export const getPrimaryArea = (areas) => {
  try {
    const area = getArea(areas);

    return areas[area].value + areas[area].measure;
  } catch {
    return null;
  }
};

export const getSituationById = (id) => {
  try {
    return SITUATIONS[id];
  } catch {
    console.warn('Nenhuma situação encontrada');
    return null;
  }
};

/**
 * Mostra
 * @param situationId
 * @returns {boolean}
 */
export const canShowDeadline = (situationId) => {
  const allowedIds = [
    '24cd263d-539d-426c-b88d-61e684d249ab', // NOVO
    'df582dd4-169d-465d-8492-a2b2aaab68c9', // NA PLANTA
    'a5d9de20-ea2d-42f2-a84e-0130ed48e8ee', // LANCAMENTO
    'd0e9401d-5596-4af2-905b-5a7f50ffddfd', // EM CONSTRUCAO
    '9b80b3ba-2083-4782-aa91-7f5f258c1d43', // BREVE LANCAMENTO
  ];

  return allowedIds.indexOf(situationId) !== -1;
};

export const getPropertyInformations = ({ areas, rooms }) => {
  let infos = [];

  // Verifica se tem cômodos
  const hasRooms = !isEmpty(rooms);

  // Verifca se tem áreas
  const hasAreas = !isEmpty(areas);

  if (hasRooms) {
    // Se tiver quartos adiciona no array de informações
    if (rooms.bedroom)
      infos = [...infos, { ...rooms.bedroom, suite: rooms.suite }];

    // Se tiver garagem adiciona no array de informações
    if (rooms.garage) infos = [...infos, rooms.garage];
  }

  // Verifica se tem informações
  const hasInfos = infos && infos.length > 0;

  // Verifica se tem areas
  // se tiver adiciona as áreas principais
  if (hasAreas) {
    if (areas.total_area || areas.ground_total_area) {
      infos = [...infos, areas.total_area || areas.ground_total_area];
    }

    // Se tiver informações traz somente area total
    if (hasInfos) return infos;

    // Verifica se tem área construida
    if (areas.built_area) {
      infos = [...infos, areas.built_area];
    }

    if (areas.front_ground) {
      infos = [...infos, areas.front_ground];
    }

    if (areas.back_ground) {
      infos = [...infos, areas.back_ground];
    }
  }

  return infos;
};

/**
 * Retorna os comodos secundários
 * @param rooms
 * @returns {*}
 */
export const getSecondaryRooms = (rooms) => {
  return omit(rooms, ['bedroom', 'suite', 'garage']);
};

/**
 * Retorna a cor com base o status do imóvel
 * @param property
 * @return {Object}
 */
export const colorStatus = (property) => {
  switch (property.status) {
    case 'Excluído':
      return {
        color: 'danger',
        text: 'Inativado',
      };
    case 'Vendido':
      return {
        color: 'success',
        text: 'Vendido',
      };
    case 'Alugado':
      return {
        color: 'caution',
        text: 'Alugado',
      };
    default:
      return { color: '', text: '' };
  }
};

const getNetworkColor = (networkType) => {
  try {
    return TYPE_NETWORK[networkType].color;
  } catch {
    return '#0063C0';
  }
};

/**
 * Verifica se a referência mudou ou não
 * @param values
 * @param prevValues
 * @returns {boolean}
 */
export const isReferenceNotChanged = (values, prevValues) => {
  if (values?.reference === '') return true;
  return values?.reference === prevValues?.reference;
};

export const getPropertyStatus = (property) => {
  if (!property) return null;

  const { color, text } = colorStatus(property);

  if (
    property.status === 'Excluído' ||
    property.status === 'Vendido' ||
    property.status === 'Alugado'
  ) {
    return [{ text, color }];
  }

  if (property.is_blocked) {
    return [{ text: 'Bloqueado', color: 'danger' }];
  }

  if (property.created_on_site && property.is_draft) {
    return [
      { text: 'Rascunho', color: 'caution' },
      { text: 'Cadastrado via site', color: 'tertiary' },
    ];
  }

  if (property.is_draft) {
    return [{ text: 'Rascunho', color: 'caution' }];
  }

  if (property.is_published === false) {
    return [{ text: 'Não publicado', color: 'secondary' }];
  }

  if (property.is_under_negotiation) {
    return [{ text: 'Em atendimento', color: 'caution' }];
  }

  if (property.is_booked) {
    return [{ text: 'Reservado', color: 'danger' }];
  }

  if (property.status === 'Disponível') {
    if (property.network_type === 'Órulo') {
      return [{ text: 'Órulo', color: 'secondary' }];
    }

    if (property.network_type === 'DWV') {
      return [{ text: 'DWV', color: 'danger' }];
    }

    if (property.is_on_network && property.network_type === 'Guru') {
      return [{ text: 'Guru', color: getNetworkColor(property.network_type) }];
    }
  }

  return [
    {
      text,
      color,
    },
  ];
};

export const getProfileName = (profile) => {
  try {
    return PROFILE.find((item) => item.value === profile).label;
  } catch {
    console.error('Erro ao pegar o perfil do imóvel');
    return null;
  }
};
