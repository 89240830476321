// import * as S from './styles'

import { Field, reduxForm } from 'redux-form';
import { FileInput } from 'components/Form';
import Button from 'components/Button';
import React from 'react';

function FormUpload({ handleSubmit, handleClickSendSlide }) {
  return (
    <form onSubmit={handleSubmit} style={{ display: 'inline-block' }}>
      <Field name="files" component={FileInput}>
        <Button
          type="button"
          color="white"
          colorText="secondary"
          onClick={handleClickSendSlide}
        >
          Enviar Slide
        </Button>
      </Field>
    </form>
  );
}

export default reduxForm({
  form: 'FormUploadSlides',
  enableReinitialize: true,
})(FormUpload);
