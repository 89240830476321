import React from 'react';
import PropTypes from 'prop-types';
// Component
import Table from 'components/Table';
import DomainItem from '../DomainItem';
// Shape
import { DomainShape } from 'constants/shapes';

const propTypes = {
  domains: PropTypes.arrayOf(DomainShape),
  handleDeleteDomain: PropTypes.func,
  handleEditDomain: PropTypes.func,
  handleMainDomain: PropTypes.func,
};

const defaultProps = {
  domains: [],
  handleDeleteDomain: () => {},
  handleEditDomain: () => {},
  handleMainDomain: () => {},
};

const DomainsTable = ({
  domains,
  handleDeleteDomain,
  handleEditDomain,
  handleMainDomain,
  onClickCheckDNS,
}) => (
  <div>
    <Table
      data={domains}
      isFetching={false}
      renderTitle={() => (
        <tr>
          <th>Domínio</th>
          <th>Status</th>
          <th>Principal</th>
          <th colSpan={2} />
        </tr>
      )}
      renderItem={(domain) => (
        <DomainItem
          key={domain.id}
          domain={domain}
          onClickCheckDNS={onClickCheckDNS}
          handleDeleteDomain={handleDeleteDomain}
          handleEditDomain={handleEditDomain}
          handleMainDomain={handleMainDomain}
        />
      )}
    />
  </div>
);

DomainsTable.propTypes = propTypes;
DomainsTable.defaultProps = defaultProps;

export default DomainsTable;
