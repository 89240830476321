import React, { useEffect, useState } from 'react';
import { MdDashboard, MdFilter, MdFlag, MdList } from 'react-icons/all';
import { MdFavorite, MdHome, MdPerson } from 'react-icons/md';
import NavItem from './components/NavItem';
import { Aside, Nav, NavTitle, Title } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { countsSelector, fetchCounts } from '../../modules/asideCrm';
import FormUser, { useFormUserId } from '../../components/FormUser';
import { useFormFunnelId } from '../../pages/Deals/components/Filter';
import { useLocation } from 'react-router-dom';

function AsideCrm() {
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDashboard, setIsDashboard] = useState(false);
  const dispatch = useDispatch();
  const userId = useFormUserId();
  const location = useLocation();
  const funnelId = useFormFunnelId();
  const { data, isFetching } = useSelector(countsSelector);

  useEffect(() => {
    if (userId) {
      dispatch(fetchCounts(userId));
    }
  }, [userId]);

  useEffect(() => {
    setIsDisabled(false);

    if (
      location.pathname === '/crm/dashboard' ||
      location.pathname === '/crm/contacts'
    ) {
      setIsDashboard(true);
    } else {
      setIsDashboard(false);
    }

    if (location.pathname === '/crm/favorites') {
      setIsDisabled(true);
    }
  }, [location]);

  return (
    <Aside className="js-aside">
      <Title>Gestão do relacionamento</Title>
      <FormUser isDashboard={isDashboard} isDisabled={isDisabled} />
      <Nav>
        <NavItem
          to="/crm/dashboard"
          active="/crm/dashboard"
          icon={MdDashboard}
          text={`Visão geral`}
          className="js-visao-geral"
        />
        <NavItem
          to="/crm/contacts"
          active="/crm/contacts"
          icon={MdPerson}
          text={'Contatos recebidos'}
        />
        <NavItem
          to="/crm/favorites"
          active="/crm/favorites"
          icon={MdFavorite}
          text={'Clientes Favoritos'}
        />
      </Nav>
      <Nav>
        <NavTitle>Negócios em andamento</NavTitle>
        <NavItem
          to="/crm/deals"
          active="/crm/deals"
          icon={MdFilter}
          text={`Visão em etapas (${data.peopleCount})`}
        />
        <NavItem
          to="/crm/people"
          active="/crm/people"
          icon={MdList}
          text={`Lista de clientes (${data?.peopleCount})`}
        />
        <NavItem
          to="/crm/bookeds"
          active="/crm/bookeds"
          icon={MdFlag}
          text={`Imóveis reservados (${data?.bookedPropertiesCount?.properties_count})`}
        />
      </Nav>
      <Nav>
        <NavTitle>Radar de oportunidades</NavTitle>
        <NavItem
          to="/crm/matched-people"
          active="/crm/matched-people"
          icon={MdPerson}
          text={`Clientes compatíveis (${data?.matchedCount})`}
        />
        <NavItem
          to="/crm/matched-properties"
          active="/crm/matched-properties"
          icon={MdHome}
          text={`Imóveis compatíveis (${data?.matchedPropertiesCount})`}
        />
      </Nav>
      <Nav>
        <NavTitle>Relatórios</NavTitle>
        <NavItem
          to="/crm/history"
          active="/crm/history"
          icon={MdPerson}
          text={`Negócios encerrados`}
        />
        <NavItem
          to="/crm/report-shared-properties"
          active="/crm/report-shared-properties"
          icon={MdPerson}
          text={`Imóveis compartilhados`}
        />
      </Nav>
    </Aside>
  );
}

export default AsideCrm;
