import styled, { css } from 'styled-components';

export const Item = styled.div`
  background: #ffffff;
  border: 1px solid #d3dceb;
  border-radius: 4px;
  padding: 4px 8px;
  line-height: 17px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;

  ${(p) =>
    p.isChecked &&
    css`
      color: #0063c0;
      border: 1px solid #0063c0;
      border-radius: 4px;
    `}
`;

export const Options = styled.div`
  display: flex;
  align-content: flex-start;
  align-items: center;
  gap: 5px;
`;

export const FormGroup = styled.div`
  position: relative;
  margin-bottom: 15px;
`;
