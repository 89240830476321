import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
// Auth
import login from './login';
// Properties
import properties from './properties';
import mainSearch from './mainSearch';
import propertySearch from './propertySearch';
import propertyAdd from './propertyAdd';
import propertyGallery from './propertyGallery';
import modalProperty from './modalProperty';
import searchFilter from '../pages/Properties/SearchFilter/reducer';
import propertyDashboard from '../containers/PropertyDashboard/module';
import modalPropertySendMail from '../containers/ModalRoot/ModalPropertySendMail/module.js';
import propertiesMatched from 'pages/PropertyMatched/module';
import propertyPeopleMatched from 'pages/PropertyPeopleMatched/module';
import modalPersonProperties from 'containers/ModalRoot/ModalPerson/components/Properties/module.js';
import modalPropertyRent from 'containers/ModalRoot/ModalPropertyRent/module.js';
import modalPropertyGroup from 'containers/ModalRoot/ModalPropertyGroup/module';
// Properties - Other
import condos from './condos';
import characteristics from './characteristics';
import condosCharacteristics from './condosCharacteristics';
import establishments from './establishments';
import modalFinancial from './modalFinancial';
import modalCharacteristics from './modalCharacteristics';
// Realtors
import realtors from './realtors';
import realtorSelect from './realtorSelect';
// People
import peopleGroups from './peopleGroups';
import people from './people';
import peopleDashboard from '../containers/PeopleDashboard/module';
import personEvents from './personEvents';
import personNotes from './personNotes';
import personStories from './personStories';
import personDocuments from './personDocuments';
import personInfos from './personInfos';
import personCrm from './personCrm';
import receptionSources from './receptionSources';
import modalPerson from './modalPerson';
import modalPersonAdd from './modalPersonAdd';
import modalProfile from '../containers/ModalRoot/ModalProfile/module';
// People - Situations
import cadastralSituations from './cadastralSituations';
// Users
import users from './users';
import modalUser from './modalUser';
import modalUserPassword from './modalUserPassword';
// Invites
import invites from './invites';
// Email
import email from 'pages/EmailAccounts/module';
// Financial Indexes
import financialIndex from './financialIndex';
import modalIndexHistory from './modalIndexHistory';
// CRM
// import crm from '../pages/Crm/reducer';
import crm2 from '../pages/Crm2/reducer';
import funnel from './funnel';
import pipeline from './pipeline';
import modalDeal from '../containers/ModalRoot/ModalDeal/reducer';
import modalDealDelete from '../containers/ModalRoot/ModalDealDelete/reducer';
// Profiles
import profile from 'pages/Profile/module';
// Plan
import plan from './plan';
// Other
import portalRealEstate from 'containers/PropertiesPortal/module';
import site from 'pages/Site/module';
import locations from 'containers/LocationFields/module';
import reports from 'pages/Reports/module';
import watermark from './watermark';
import mapView from './mapView';
import modalLocation from 'containers/ModalRoot/ModalLocationAdd/module';
import domains from './domains';
import components from './components';
import events from './events';
import groups from './groups';
import permissions from './permissions';
// Global
import pagination from './pagination';
import modal from './modal';
import modalConfirmation from 'containers/ModalConfirmation/module.js';
import loading from './loading';
import recommend from './recommend';
import reception from './reception';
import notifications from './notifications';
import filter from 'pages/Properties/Filter/module';
import fileUpload from 'modules/fileUpload';
import visibility from './visibility';
import authentications from './authentications';
import googlefonts from './googlefonts';
import formData from './formData';
import selecteds from './selecteds';
import supportChat from './supportChat';

// Google
import google from 'modules/google';
import propertyPublication from './propertyPublication';
import prices from './prices';
import modalPropertySearch from 'modules/modalPropertySearch';
import modalImages from 'modules/modalImages';
import asideCrm from '../pages/Crm/modules/asideCrm';
import addressesLocations from 'pages/Site/pages/Locations/module';
import financial from './financial';
import configDocuments from './configDocuments';
import statusGuard from 'features/Financial/modules/statusGuard';

export default combineReducers({
  asideCrm,
  financial,
  mainSearch,
  modalPropertySearch,
  prices,
  properties,
  propertySearch,
  propertyAdd,
  propertyGallery,
  realtors,
  realtorSelect,
  login,
  users,
  invites,
  people,
  peopleGroups,
  personEvents,
  personNotes,
  personStories,
  personDocuments,
  personInfos,
  personCrm,
  recommend,
  reception,
  financialIndex,
  notifications,
  characteristics,
  cadastralSituations,
  establishments,
  googlefonts,
  condos,
  funnel,
  pipeline,
  condosCharacteristics,
  receptionSources,
  watermark,
  loading,
  mapView,
  // crm,
  crm2,
  searchFilter,
  pagination,
  visibility,
  authentications,
  email,

  // Modals
  modal,
  modalDeal,
  modalDealDelete,
  modalProperty,
  modalPropertyGroup,
  modalIndexHistory,
  modalUser,
  modalUserPassword,
  modalPerson,
  modalPersonProperties,
  modalPersonAdd,
  modalFinancial,
  modalCharacteristics,
  modalProfile,
  modalPropertySendMail,
  modalPropertyRent,
  modalConfirmation,
  peopleDashboard,
  propertyDashboard,
  reports,
  propertiesMatched,
  propertyPeopleMatched,
  domains,
  modalLocation,
  components,
  profile,
  portalRealEstate,
  groups,
  permissions,
  site,
  events,
  filter,
  fileUpload,
  google,
  plan,
  locations,
  formData,
  selecteds,
  propertyPublication,
  modalImages,
  form,
  addressesLocations,
  supportChat,
  configDocuments,
  statusGuard,
});
