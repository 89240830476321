import styled from 'styled-components';

export const Actions = styled.div`
  display: flex;
  gap: 5px;
`;

export const Action = styled.div`
  width: 18px;
  height: 18px;
  display: grid;
  place-items: center;
  cursor: pointer;

  font-size: 16px;
`;

export const Link = styled.button`
  font-size: 14px;
  color: #0084f4;
  text-decoration: underline;
  cursor: pointer;
`;
