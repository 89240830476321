import React from 'react';
import { Header } from 'features/Financial/components/Header';
import { Financial } from 'features/Financial';
import { PanelAccounts } from './components/PanelAccounts';
import { MovimentsMonthly } from './components/MovimentsMonthly';
import { PanelCreditcards } from './components/PanelCreditcards';
import { Col, Row } from 'react-flexbox-grid';
import { GraphProjection } from './components/GraphProjection';
import { HeaderActions } from './components/HeaderActions';
import WizardDashboard from './components/WizardDashboard';
import { DashboardProvider } from './hooks/useDashboardContext';

export function Dashboard() {
  return (
    <DashboardProvider>
      <Header.Header>
        <Header.Title>Visão geral</Header.Title>
        <HeaderActions />
      </Header.Header>
      <Financial.Content>
        {/*<QuickAccess />*/}
        <MovimentsMonthly />

        <Row>
          <Col xs={6}>
            <PanelAccounts />
          </Col>
          <Col xs={6}>
            <PanelCreditcards />
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <GraphProjection />
          </Col>
        </Row>
      </Financial.Content>
      <WizardDashboard />
    </DashboardProvider>
  );
}
