import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 945px;
  display: flex;
  background: #ffffff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
`;
export const Col = styled.div`
  flex-grow: 1;
  padding: 40px 0;
  display: flex;
  justify-content: center;
`;
export const Divider = styled.div`
  flex-shrink: 0;
  width: 1px;
  background: #cdd7e7;
  margin: 20px 0;
`;

export const Item = styled.div`
  width: 325px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  text-align: center;
  cursor: pointer;

  button svg {
    margin-right: 0.2em;
    margin-top: -0.2em;
  }
`;
