import Modal from 'react-modal';
import { ModalTemplate } from 'components/Modal';
import { openModal } from 'modules/modal';
import { useMemo } from 'react';
import FormAccountTransfer from './components/FormAccountTransfer';
import { useAccountTransfer } from '../../hooks/useAccounts';
import moment, { format } from 'lib/moment';

export const openModalAccountTransfer =
  ({ data, type = 1, onSuccess }) =>
  (dispatch) => {
    dispatch(
      openModal({
        type: 'ModalAccountTransfer',
        size: 470,
        position: 'center',
        props: {
          data,
          type,
          onSuccess,
        },
      })
    );
  };

export function ModalAccountTransfer({
  modalConfig,
  isOpen,
  handleClose,
  modalType,

  data,
  type,
  onSuccess,
}) {
  const { accountTransfer } = useAccountTransfer();

  const today = moment().format(format.date);

  const initialValues = useMemo(() => {
    if (data?.id) {
      return {
        origin_id: data?.id,
        date: today,
      };
    }

    return {};
  }, [data]);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title="Transferência entre contas bancárias"
        handleClose={handleClose}
      >
        <FormAccountTransfer
          data={data}
          initialValues={initialValues}
          handleClose={handleClose}
          onSubmit={accountTransfer}
          onSubmitSuccess={(res) => {
            if (onSuccess) onSuccess(res);
            handleClose();
          }}
        />
      </ModalTemplate>
    </Modal>
  );
}
