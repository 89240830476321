import React from 'react';
import { reduxForm } from 'redux-form';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import ListPagesFacebook from '../ListPagesFacebook';
import Button from 'components/Button';
import { useFacebookLeads } from '../../context';

function FormFacebookLeads({ change, handleClose, handleSubmit }) {
  const { pages, errorPages, isFetching, isFetchingPages } = useFacebookLeads();

  if (errorPages && !isFetching && !isFetchingPages) {
    return (
      <ModalTemplate
        handleClose={handleClose}
        title="Facebook - Integração com Formulários (Leads)"
      >
        <p>Erro na integração com o formulário do facebook</p>
        <p>Por favor, reconecte sua conta para continuar.</p>
        <ModalFooter>
          <Button type="button" color="white" onClick={handleClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalTemplate>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <ModalTemplate
        isLoading={isFetching || isFetchingPages}
        title="Facebook - Integração com Formulários (Leads)"
        handleClose={handleClose}
      >
        <p>
          Caso optar por corretor, será enviado notificação, ou se optar por
          imóvel, seguira fluxo de lead e abertura de negócio no CRM:
        </p>
        <ListPagesFacebook change={change} />
        <ModalFooter>
          <Button type="button" color="white" onClick={handleClose}>
            Cancelar
          </Button>
          <div className="h-flex__cell--grow" />
          <Button type="submit" color="success">
            Salvar
          </Button>
        </ModalFooter>
      </ModalTemplate>
    </form>
  );
}

export default reduxForm({
  form: 'FormFacebookLeads',
  enableReinitialize: true,
})(FormFacebookLeads);
