// import * as S from './styles'

import { Content } from 'pages/Crm/pages/Booked/styles';
import Layout from 'pages/Crm/components/Layout';
import React, { useEffect } from 'react';
import TablePeople from './components/TablePeople';
import { useFavorites } from './hooks/useFavorites';
import { EmptyState } from './components/EmptyState';

export function Favorites() {
  const { favorites, favoritesMeta, fetchFavorites } = useFavorites();

  useEffect(() => {
    fetchFavorites({});
  }, []);

  if (!favorites || favorites.length === 0) {
    return <EmptyState />;
  }

  return (
    <Layout title="Favoritos">
      <Content>
        <TablePeople
          data={favorites}
          meta={favoritesMeta}
          fetchData={fetchFavorites}
        />
      </Content>
    </Layout>
  );
}
