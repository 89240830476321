import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Field } from 'redux-form';
import _ from 'lodash';
// Components
import FormBox from 'components/FormBox';
import Checkbox from 'components/Checkbox';

const propTypes = {};
const defaultProps = {};

const Characteristics = ({
  name = 'characteristics',
  isCondo,
  data,
  type,
  cols = 4,
}) => {
  // Divide os dados em colunas usando lodash
  const chunks = _.chunk(data, Math.ceil(data.length / cols));

  return (
    <FormBox
      title={
        isCondo
          ? 'Características do condomínio'
          : `Características - ${type.title}`
      }
    >
      <Row>
        {chunks.map((chunk, chunkIndex) => (
          <Col key={`chunk-${chunkIndex}`} xs={parseInt(12 / cols, 10)}>
            {chunk.map((characteristic) => (
              <div className="ListCheck__item">
                <Field
                  key={characteristic.id}
                  id={characteristic.id}
                  type="checkbox"
                  name={`${name}.${characteristic.id}`}
                  component={Checkbox}
                  parse={(value) => {
                    if (!value) return null;
                    return characteristic;
                  }}
                >
                  {characteristic.title}
                </Field>
              </div>
            ))}
          </Col>
        ))}
      </Row>
    </FormBox>
  );
};

Characteristics.propTypes = propTypes;
Characteristics.defaultProps = defaultProps;

export default Characteristics;
