import { useCurrentUser } from 'hooks/useCurrentUser';

export const useUserStorage = () => {
  const currentUser = useCurrentUser();

  const getKey = () => `user-infos-${currentUser.id}`;

  const getValues = () => {
    try {
      return localStorage.getItem(getKey());
    } catch {
      return {};
    }
  };

  const get = (key) => {
    return getValues?.()?.[key];
  };

  const set = (key, value) => {
    try {
      const values = getValues();

      return localStorage.setItem(getKey(), {
        ...values,
        [key]: value,
      });
    } catch (err) {
      console.error('Problema ao setar o valor', err);
      return null;
    }
  };

  const remove = (key) => {
    try {
      const values = getValues();
      if (values.hasOwnProperty(key)) {
        delete values[key];
        localStorage.setItem(getKey(), values);
      }
    } catch (err) {
      console.error('Problema ao remover o valor', err);
      return null;
    }
  };

  return { get, set, remove, currentUser };
};
