import React from 'react';
import classnames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';
import {
  MdCancel,
  MdCheck,
  MdClose,
  MdKeyboardArrowDown,
} from 'react-icons/md';
// Modules
import {
  Actions as UploadActions,
  getFiles,
  getFilesErrors,
  getFilesFinished,
  getFilesUploading,
  getIsMinimized,
  getIsOpen,
  STATUS,
} from 'modules/fileUpload';
// Helpers
import { plural } from 'lib/text';

const getIconByStatus = (status) => {
  switch (status) {
    case STATUS.SUCCESS:
      return <MdCheck className="h-color--success" />;
    case STATUS.ERROR:
      return <MdCancel className="h-color--danger" />;
    case STATUS.UPLOADING:
    default:
      return <ReactLoading type="spin" color="#000" width={20} height={20} />;
  }
};

class FileUploadContainer extends React.Component {
  get className() {
    const { isOpen, isMinimized } = this.props;

    return classnames('FileUpload', {
      'FileUpload--minimized': isMinimized,
      'FileUpload--maximized': !isMinimized,
      'FileUpload--opened': isOpen,
      'FileUpload--closed': !isOpen,
    });
  }

  get totalErrorFiles() {
    try {
      return this.props.errorFiles.length;
    } catch {
      return 0;
    }
  }

  get totalFiles() {
    try {
      return this.props.allFiles.length;
    } catch {
      return 0;
    }
  }

  get totalFilesUploading() {
    try {
      return this.props.uploadingFiles.length;
    } catch {
      return 0;
    }
  }

  get title() {
    const totalFiles = this.totalFiles;
    const totalFilesUploading = this.totalFilesUploading;
    const totalErrors = this.totalErrorFiles;

    if (totalFilesUploading > 0) {
      return `Enviando ${plural(
        totalFilesUploading,
        'arquivo',
        'arquivos',
        true
      )}...`;
    }

    if (totalErrors > 0) {
      const errors = plural(
        totalErrors,
        'arquivo com erro',
        'arquivos com erros'
      );
      const success = plural(
        totalFiles - totalErrors,
        'concluído',
        'concluídos'
      );

      return totalFiles - totalErrors > 0
        ? `${errors} e ${success}`
        : `${errors}`;
    }

    return plural(totalFiles, 'concluído', 'concluídos', true);
  }

  get renderFiles() {
    const { allFiles } = this.props;

    return (
      <ul className="FilesList">
        {allFiles.map((file) => (
          <li className="FilesList__item">
            <span className="FilesList__fileName">{file.name}</span>
            <span className="h-flex__cell--grow" />
            <span>{getIconByStatus(file.status)}</span>
          </li>
        ))}
      </ul>
    );
  }

  handleClickMinimize = () => {
    const { isMinimized, minimize, maximize } = this.props;
    isMinimized ? maximize() : minimize();
  };

  handleClickClose = () => {
    this.props.close();

    setTimeout(() => {
      this.props.reset();
    }, 500);
  };

  render() {
    return (
      <div className={this.className}>
        <div className="FileUpload__header">
          <h1 className="FileUpload__title">{this.title}</h1>
          <span className="h-flex__cell--grow" />
          <button
            tabIndex={0}
            className="FileUpload__action FileUpload__action--rotate"
            type="button"
            onClick={this.handleClickMinimize}
          >
            <MdKeyboardArrowDown />
          </button>
          <button
            tabIndex={0}
            className="FileUpload__action"
            type="button"
            onClick={this.handleClickClose}
          >
            <MdClose />
          </button>
        </div>
        <div className="FileUpload__body">{this.renderFiles}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isOpen: getIsOpen(state),
  isMinimized: getIsMinimized(state),
  allFiles: getFiles(state),
  finishedFiles: getFilesFinished(state),
  errorFiles: getFilesErrors(state),
  uploadingFiles: getFilesUploading(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UploadActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileUploadContainer);
