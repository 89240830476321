import api from 'services/index';

export const getFinancialModuleStatus = () => {
  return api.get('real-estates/financial-module-status');
};

/**
 * Endpoint que pede pra entrar na fila de espera
 * @returns {Promise<AxiosResponse<*>>}
 */
export const financialAsk = () => {
  return api.create('real-estates/financial-ask');
};

/**
 * Acesso ao modulo liberado
 * Esse endpoint serve pra quando o cara ta com status
 * @returns {Promise<AxiosResponse<*>>}
 */
export const startFreeTrial = () => {
  return api.create('real-estates/financial-start-free-trial');
};
