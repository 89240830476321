import { useCallback, useEffect, useMemo, useState } from 'react';

export const useRequest = ({
  request,
  params,
  config,
  initialState = [],
  initialFetch = true,
  isFetching: _isFetching = false,
  onSuccess,
  onError,
}) => {
  const [isInitialized, setInitialized] = useState(false);
  const [currentOffset, setOffset] = useState(params?.offset || null);
  const [data, setData] = useState(initialState);
  const [meta, setMeta] = useState({});
  const [error, setHasError] = useState(null);
  const [isFetching, setIsFetching] = useState(_isFetching);

  // Verifica se tem paginação
  const hasPagination = useMemo(() => {
    return params?.limit && params?.offset;
  }, [params]);

  /**
   * Faz a busca dos dados
   */
  const fetchData = useCallback(async (...rest) => {
    // seta que está buscando algo
    setIsFetching(true);

    let requestParams = [params, config];

    // se estiver vindo parametros seta eles
    if (rest?.length > 0) {
      requestParams = rest;
    }

    try {
      const res = await request(...requestParams);

      if (res?.meta) setMeta(res?.meta);

      // pega a paginação da resposta
      const pagination = res?.meta?.pagination;

      // verifica se pode voltar para a página anterior
      // depois que deletou o ultimo resultado de uma página específica
      const canBackOffset =
        pagination?.total_pages < pagination?.current_page &&
        pagination?.total > 0;

      if (canBackOffset) {
        setOffset((currentOffset) => currentOffset - 1);
        return res;
      }

      setData(res.data);
      setIsFetching(false);
      setHasError(null);
      if (onSuccess) onSuccess(res);

      return res;
    } catch (err) {
      if (onError) onError(err);
      setHasError(err);
      console.log(err);
      return null;
    } finally {
      setIsFetching(false);
    }
  }, []);

  // Método que muda a pagina atual
  const changePage = useCallback((_offset) => {
    setOffset(_offset);
  }, []);

  // Remove um resultado da tela
  // se tiver paginação ele busca da api
  // se não tiver paginação ele somente remove da lista
  const remove = useCallback(
    async (id, valueKey = 'id') => {
      if (!hasPagination) {
        return setData((prevData) => {
          return prevData.filter((current) => current[valueKey] !== id);
        });
      } else {
        return fetchData({ ...params, offset: currentOffset });
      }
    },
    [data, hasPagination]
  );

  // Atualiza um resultado da tela
  // para atualizar precisa passar o item que está querendo ser editado
  const update = useCallback(
    (item, valueKey = 'id') => {
      const updatedData = data.map((current) => {
        if (current?.[valueKey] === item?.[valueKey]) {
          return item;
        }

        return current;
      });

      setData(updatedData);
    },
    [data]
  );

  // Effect que lida com a mudança de página
  // caso mude a página tem que buscar
  useEffect(() => {
    // pra não chamar o request 2x quando inicia
    // foi necessario criar esse estado
    if (isInitialized) {
      fetchData({
        ...params,
        offset: currentOffset,
      });
    }
  }, [currentOffset]);

  useEffect(() => {
    if (initialFetch) {
      // Busca as informações
      fetchData();
    }
    setInitialized(true);
  }, []);

  return {
    data,
    error,
    isFetching,
    setIsFetching,
    meta,
    fetchData,
    setData,
    setMeta,
    update,
    remove,
    changePage,
    currentOffset,
  };
};
