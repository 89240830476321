import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import * as facebookService from 'services/settings/facebook';
import * as userService from 'services/user';
import { usePages } from 'containers/ModalRoot/ModalFacebookLeads/hooks/usePages';
import { useRequest } from 'hooks/useRequest';

export const FacebookLeadsContext = createContext({
  isConnected: null,
  facebook: {
    accessToken: null,
    data_access_expiration_time: null,
    expiresIn: null,
    graphDomain: null,
    signedRequest: null,
    userID: null,
  },
  pages: null,
  isFetchingPages: null,
  fetchPages: null,
  setFacebook: null,
  noPermission: null,
  isFetching: null,
  setIsFetching: null,
  handleLoginFacebook: null,

  accessToken: null,
  facebookUserId: null,
});

export const FacebookLeadsProvider = ({ children }) => {
  const [facebook, setFacebook] = useState(null);
  const [noPermission, setNoPermission] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { pages, errorPages, isFetchingPages, setPages, fetchPages } =
    usePages();

  const { data: users, isFetching: isFetchingUsers } = useRequest({
    request: () => userService.getList({ filter: { realtor: true } }),
  });

  const { data } = useRequest({
    request: facebookService.getIntegration,
  });

  useEffect(() => {
    if (data && data?.facebook?.access_token) {
      setFacebook(data?.facebook);
      setPages(data?.pages);
    }
  }, [data]);

  /**
   * Retorna se está conectado s/n
   * @type {boolean}
   */
  const isConnected = useMemo(() => {
    return facebook?.accessToken || !!facebook?.lead_pages?.[0]?.id;
  }, [facebook]);

  const getAccessToken = () => {
    return facebook?.accessToken || facebook?.access_token;
  };

  const accessToken = useMemo(() => {
    try {
      return getAccessToken();
    } catch {
      return null;
    }
  }, [facebook]);

  const facebookUserId = useMemo(() => {
    try {
      return facebook?.userID || facebook.facebook_user_id;
    } catch {
      return null;
    }
  }, [facebook]);

  /**
   * Login no facebook
   * @returns {Promise<void>}
   */
  const handleLoginFacebook = async () => {
    setIsFetching(true);

    try {
      const response = await facebookService.login([
        'pages_show_list',
        'business_management',
        'catalog_management',
        'pages_show_list',
        'leads_retrieval',
        'pages_manage_metadata',
        'pages_read_engagement',
        'pages_manage_ads',
      ]);

      response.access_token = response.accessToken;

      setFacebook(response?.authResponse);
      setIsFetching(false);
    } catch (err) {
      setNoPermission(true);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    const token = getAccessToken();

    if (token) {
      fetchPages(token);
    }
  }, [fetchPages, facebook]);

  return (
    <FacebookLeadsContext.Provider
      value={{
        isConnected,
        setFacebook,
        noPermission,

        facebook,
        isFetching,
        setIsFetching,

        pages,
        errorPages,
        isFetchingPages,
        fetchPages,

        users,
        isFetchingUsers,

        accessToken,
        facebookUserId,

        handleLoginFacebook,
      }}
    >
      {children}
    </FacebookLeadsContext.Provider>
  );
};

export const useFacebookLeads = () => useContext(FacebookLeadsContext);
