import * as Panel from '../Panel';
import { MdOutlineReceipt } from 'react-icons/all';

export function EmptyState() {
  return (
    <Panel.Wrapper>
      <Panel.Content>
        <Panel.Empty>
          <MdOutlineReceipt />
          <h2>Nenhuma conta bancária cadastrada</h2>
          <p>Adicione uma conta bancária primeiro</p>
        </Panel.Empty>
      </Panel.Content>
      <Panel.Footer>
        <Panel.FullButton to="/financial/accounts">
          Gerenciar contas bancárias
        </Panel.FullButton>
      </Panel.Footer>
    </Panel.Wrapper>
  );
}
