import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px;

  > div {
    display: flex;
    align-items: center;
    gap: 15px;
  }
`;
