import styled from 'styled-components';
import Clipboard from 'react-clipboard.js';

export const Wrapper = styled.div`
  margin-bottom: 30px;
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  p {
    font-size: 14px;
  }
`;

export const DomainText = styled.span`
  color: ${(p) => p.theme.colors.secondary};
  text-decoration: underline;
  margin-left: 10px;
`;

export const Boxes = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;

  filter: drop-shadow(0 1px 10px rgba(0, 0, 0, 0.1));
`;

export const Box = styled.div`
  position: relative;
  height: 225px;
  background-color: #fff;
  padding: 25px;

  &:first-child {
    width: 29.22%;
    padding-right: 35px;
    clip-path: polygon(95% 0, 100% 50%, 95% 100%, 0 100%, 0 50%, 0 0);
  }

  &:nth-child(2) {
    width: 36.68%;
    padding-left: 35px;
    clip-path: polygon(95% 0, 100% 50%, 95% 100%, 0 100%, 5% 50%, 0 0);
  }

  &:last-child {
    width: 34.1%;
    padding-left: 35px;
    clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0 100%, 5% 50%, 0 0);
  }
`;

export const BoxTitle = styled.h2`
  font-size: 16px;
  margin-bottom: 15px;
`;

export const DnsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
export const Dns = styled(Clipboard)`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Define 2 colunas de tamanho igual */
  gap: 10px;

  span {
    white-space: nowrap;
    color: #16a34a;
  }
`;
