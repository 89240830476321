import React from 'react';
import PropTypes from 'prop-types';
import ReactToggle from 'react-toggle';
// Components
import Button from 'components/Button';
import ReactTooltip from 'react-tooltip';
import { MdDeleteOutline } from 'react-icons/md';

const propTypes = {
  id: PropTypes.string,
  domain: PropTypes.shape({
    domain: PropTypes.string,
    status: PropTypes.number,
    statusLabel: PropTypes.string,
    main: PropTypes.bool,
  }),
  handleEditDomain: PropTypes.func,
  handleDeleteDomain: PropTypes.func,
  handleMainDomain: PropTypes.func,
};

const defaultProps = {
  domain: {},
  handleEditDomain: () => {},
  handleDeleteDomain: () => {},
  handleMainDomain: () => {},
};

const STATUS = {
  1: {
    text: 'Propagando IP',
    color: 'caution',
  },
  2: {
    text: 'Falha',
    color: 'danger',
  },
  3: {
    text: 'Ativo',
    color: 'success',
  },
  4: {
    text: 'Falha',
    color: 'danger',
  },
};

class DomainItem extends React.Component {
  componentDidMount() {
    ReactTooltip.rebuild();
  }

  get canRemove() {
    return true;
    //const {
    //  domain: { is_primary }
    //} = this.props;
    //
    //// Pode remover se não for o dominio principal
    //return !is_primary;
  }

  /**
   * Retorna se pode checar o domínio como principal
   * @return {boolean}
   */
  get canCheckDomain() {
    const {
      domain: { dns_status },
    } = this.props;

    // Se estiver propagando o status
    // Não pode a
    return dns_status === 3;
  }

  get renderCheckDomain() {
    const {
      domain,
      domain: { is_primary, dns_status },
      handleMainDomain,
    } = this.props;

    if (dns_status === 4 || dns_status === '4') return null;

    let tooltipProps = {};

    if (!this.canCheckDomain) {
      tooltipProps = {
        'data-tip':
          "Apenas um domínio com status 'Ativo' <br/>pode ser marcado como principal.",
        style: { position: 'relative', display: 'inline-block' },
      };
    }

    return (
      <div {...tooltipProps}>
        <ReactToggle
          disabled={!this.canCheckDomain}
          checked={is_primary}
          onChange={handleMainDomain(domain)}
          icons={false}
          className={`react-toggle--medium`}
        />
      </div>
    );
  }

  render() {
    const {
      domain: { id, url, dns_status },
      domain,
      handleDeleteDomain,
      onClickCheckDNS,
    } = this.props;

    return (
      <tr key={id}>
        <td>
          <a
            href={url}
            rel="noopener noreferrer"
            target="_blank"
            className="Table__link"
          >
            {url}
          </a>
        </td>

        <td className={`h-color--${STATUS[dns_status].color}`}>
          {STATUS[dns_status].text}
        </td>
        <td>{this.renderCheckDomain}</td>
        <td width={1}>
          <div className="h-flex" style={{ justifyContent: 'flex-end' }}>
            {(dns_status === 4 || dns_status === 2) && (
              <Button
                onClick={onClickCheckDNS(domain.id)}
                className="h-margin-right--10"
              >
                Verificar DNS
              </Button>
            )}
            {this.canRemove && (
              <div>
                <Button
                  size="icon"
                  color="white"
                  onClick={handleDeleteDomain(domain)}
                >
                  <MdDeleteOutline className="h-color--danger" />
                </Button>
              </div>
            )}
          </div>
        </td>
      </tr>
    );
  }
}

DomainItem.propTypes = propTypes;
DomainItem.defaultProps = defaultProps;

export default DomainItem;
