import * as S from './styles';
import Button from 'components/Button';
import { FaRegCopy } from 'react-icons/all';
import { MdOutlineAccessTime } from 'react-icons/md';
import Alert from 'react-s-alert';

export function Item({ domain, onRemove }) {
  return (
    <S.Wrapper>
      <S.Header>
        <div>
          <h2>
            Quase lá! Conecte o seu domínio{' '}
            <S.DomainText>{domain.raw_url}</S.DomainText>
          </h2>
          <p>Siga os passos abaixo para conectar o domínio ao seu site.</p>
        </div>
        <div>
          <Button color="danger" onClick={onRemove}>
            Remover
          </Button>
        </div>
      </S.Header>

      <S.Boxes>
        <S.Box>
          <S.BoxTitle>1. Faça login no seu provedor</S.BoxTitle>
          <p>
            Seu provedor é o site onde você comprou o domínio, por exemplo:
            Registro.br, GoDaddy, entre outros.
          </p>
        </S.Box>
        <S.Box>
          <S.BoxTitle>2. Configure o DNS</S.BoxTitle>
          <p>Aponte as entradas DNS para:</p>
          <S.DnsWrapper>
            <S.Dns
              data-clipboard-text={domain.ns1}
              onSuccess={() => {
                Alert.success('DNS Master Copiado');
              }}
            >
              <span>DNS Master: {domain.ns1}</span> <FaRegCopy />
            </S.Dns>
            <S.Dns
              data-clipboard-text={domain.ns2}
              onSuccess={() => {
                Alert.success('DNS Slave 1 Copiado');
              }}
            >
              <span>DNS Slave 1: {domain.ns2}</span> <FaRegCopy />
            </S.Dns>
          </S.DnsWrapper>
        </S.Box>
        <S.Box>
          <S.BoxTitle>3 . Feito!</S.BoxTitle>
          <p>
            Após completar os passos ao lado, basta aguardar a propagação.
            <br />
            <br />
            Obs.: Domínios .com.br levam de 03 a 24 horas para ativarem, domínio
            .com podem levar de 24 a 48 horas.
          </p>
          <p className="h-color--caution h-margin-top--15">
            <MdOutlineAccessTime /> Aguarde ativação automática
          </p>
        </S.Box>
      </S.Boxes>
    </S.Wrapper>
  );
}
