import Modal from 'react-modal';
import { ModalTemplate, ModalTitle } from 'components/Modal';
import { openModal } from 'modules/modal';
import { useMemo, useState } from 'react';
import { useStoreAccount } from '../../hooks/useAccounts';
import FormStoreAccount from './components/FormStoreAccount';
import FormStoreCard from './components/FormStoreCard';
import { useTextsAccount } from './hooks/useTexts';
import { Col } from 'react-flexbox-grid';
import Button from 'components/Button';
import { useDispatch } from 'react-redux';

export const openModalStoreAccount =
  ({ data, canChangeType, type = 1, onSuccess }) =>
  (dispatch) => {
    dispatch(
      openModal({
        type: 'ModalStoreAccount',
        size: 470,
        position: 'center',
        props: {
          data,
          type,
          canChangeType,
          onSuccess,
        },
      })
    );
  };

export function ModalStoreAccount({
  modalConfig,
  isOpen,
  handleClose,
  modalType,

  data,
  canChangeType,
  type: _type = 1,
  onSuccess,
}) {
  const dispatch = useDispatch();
  const [type, setType] = useState(_type);
  const texts = useTextsAccount({ type, account: data });
  const isAccount = type === 1;
  const isEditting = !!data?.id;

  const { storeAccount } = useStoreAccount({
    type,
  });

  const reopenModal = ({ type, values }) => {
    dispatch(
      openModalStoreAccount({
        type,
        data: values,
        canChangeType,
        onSuccess,
      })
    );
  };

  const initialValues = useMemo(() => {
    if (data) {
      return data;
    }
    return {
      title: '',
    };
  }, [data]);

  const formProps = {
    isEditting,
    type,
    initialValues,
    handleClose,
    onSubmit: storeAccount,
    onSubmitSuccess: (res) => {
      handleClose();
      if (onSuccess) onSuccess(res);
    },
  };

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        renderHeader={() => (
          <ModalTitle
            actions={
              canChangeType ? (
                <Col className="col">
                  <Button
                    size="small"
                    color="white"
                    onClick={() => {
                      setType((currentType) => (currentType === 1 ? 2 : 1));
                    }}
                  >
                    {isAccount
                      ? 'Criar cartão de crédito'
                      : 'Criar conta bancária'}
                  </Button>
                </Col>
              ) : null
            }
          >
            {texts.titleModal}
          </ModalTitle>
        )}
        handleClose={handleClose}
      >
        {isAccount ? (
          <FormStoreAccount {...formProps} />
        ) : (
          <FormStoreCard reopenModal={reopenModal} {...formProps} />
        )}
      </ModalTemplate>
    </Modal>
  );
}
