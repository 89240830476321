// import styles from './styles'

import { Field, reduxForm } from 'redux-form';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';
import { Row } from 'react-flexbox-grid';
import { Fields } from 'features/Financial';
import { Input, InputDate } from 'components/Form';

function FormMovimentPay({ handleSubmit, handleClose }) {
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Fields.Account xs={12} label="Conta/cartão de crédito" />
      </Row>
      <Row>
        <Field
          xs={6}
          valueFormat
          label="Valor"
          name="value"
          component={Input}
          prefix="R$ "
          inputProps={{
            decimalScale: 2,
          }}
          autoComplete="off"
        />
        <Field
          autoComplete={false}
          xs={6}
          label="Data"
          name="billing_date"
          component={InputDate}
        />
      </Row>

      <ModalFooter>
        <Button type="button" color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Marcar como pago
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({ form: 'FormMovimentPay' })(FormMovimentPay);
