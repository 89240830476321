import Button from 'components/Button';
import { Checkbox, Input, InputDate } from 'components/Form';
import { ModalFooter } from 'components/Modal';
import FieldCep from 'containers/FieldCep';
import {
  CityField,
  NeighborhoodField,
  StateField,
} from 'containers/LocationFields';
import { useForm } from 'containers/ModalRoot/ModalPayment/contexts/form';
import { Form } from 'containers/ModalRoot/ModalPayment/pages/Payment/styles';
import { SectionHeader } from 'containers/ModalRoot/ModalPayment/styles';
import useFormValue from 'hooks/useFormValue';
import useReduxForm from 'hooks/useReduxForm';
import { normalizeCnpj, normalizeCpf, normalizeNumbers } from 'lib/formHelpers';
import { Row } from 'react-flexbox-grid';
import { Field } from 'redux-form';
import FieldInstallments from '../../components/FieldInstallments';
import { useRequest } from 'hooks/useRequest';
import * as bankSlipService from 'services/financial/bankSlip';
import { useModalPayment } from 'containers/ModalRoot/ModalPayment/contexts/payment';
import { useEffect, useMemo } from 'react';
import moment, { format } from 'lib/moment';

const required = (value) =>
  value || typeof value === 'number' ? undefined : 'Campo obrigatório';

const maxConsultantInstallments = (value) =>
  value && value > 6 ? 'Número máximo de parcelas é 6' : undefined;

export const max = (max) => (value) => {
  if (!value) return '';
  if (value < 1) return 1;
  if (value > max) return max;
  return value;
};

const PaymentSlip = () => {
  const { setPage } = useForm();

  const { change, dispatch, initialize, getValues } = useReduxForm();

  const stateId = useFormValue('state_id');
  const cityId = useFormValue('city_id');

  const {
    months,
    values: valuesModal,
    plan,
    plansPreview,
    receiving_method,
  } = useModalPayment();

  const { data: bankSlip } = useRequest({
    request: bankSlipService.getOne,
  });

  const consultantId = localStorage.getItem('@tecimob/consultant_id');

  const currentPlanPreview = useMemo(() => {
    try {
      return plansPreview[valuesModal.type_plan];
    } catch {
      return null;
    }
  }, [plansPreview, valuesModal]);

  const maxInstallments = useMemo(() => {
    return currentPlanPreview?.bs_max_installments;
  }, [currentPlanPreview]);

  useEffect(() => {
    const init = function () {
      let values = getValues();

      if (consultantId) {
        values = {
          ...values,
          initial_date: moment().format(format.date),
          consultant_installments: null,
        };
      }

      initialize({
        ...values,
        installments: maxInstallments,
        should_send_email: true,
        should_send_mail: true,
        should_send_whatsapp: true,
        ...bankSlip,
      });
    };

    init();
  }, [bankSlip, consultantId, maxInstallments]);

  function handleChangeCpfCnpj(value) {
    const normalizedValue = normalizeNumbers(value);
    return normalizedValue.length > 11
      ? normalizeCnpj(normalizedValue)
      : normalizeCpf(normalizedValue);
  }

  return (
    <>
      <SectionHeader>Dados do boleto</SectionHeader>

      <Form>
        <Row>
          <Field
            xs={8}
            label="Nome do pagador"
            name="name"
            component={Input}
            validate={[required]}
          />
          <Field
            xs={4}
            label="CPF ou CNPJ"
            name="cpf_cnpj"
            component={Input}
            validate={[required]}
            normalize={handleChangeCpfCnpj}
          />
        </Row>
        <Row>
          <FieldCep
            xs={4}
            name="zipcode"
            onChangeLocation={(d) => {
              if (d?.neighborhood)
                dispatch(change('neighborhood_id', d.neighborhood.id));
              if (d?.city) dispatch(change('city_id', d.city.id));
              if (d?.state) dispatch(change('state_id', d.state.id));
              if (d?.country) dispatch(change('country_id', d.country.id));
              if (d?.street_address)
                dispatch(change('street', d.street_address));
            }}
          />
          <StateField
            canUnregister={false}
            xs={3}
            label="UF"
            labelKey="acronym"
            name="state_id"
            cityName="city_id"
            neighborhoodName="neighborhood_id"
            noCountry
          />
          <CityField
            canUnregister={false}
            xs={5}
            name="city_id"
            stateId={stateId}
            neighborhoodName="neighborhood_id"
          />
        </Row>
        <Row>
          <NeighborhoodField
            canUnregister={false}
            canClearValues
            multi={false}
            xs={4}
            label="Bairro"
            name="neighborhood_id"
            cityId={cityId}
          />
          <Field xs={5} label="Logradouro" name="street" component={Input} />
          <Field xs={3} label="Número" name="street_number" component={Input} />
        </Row>
        <Row>
          <FieldInstallments typeInstallment="billet" xs={12} />
        </Row>

        {consultantId && (
          <>
            <SectionHeader
              style={{ background: 'red', color: '#fff', marginBottom: '10px' }}
            >
              Área do consultor
            </SectionHeader>
            <Field
              type="checkbox"
              name="consultant_should_not_sent"
              label="Não enviar dados da compra nem boleto para o cliente (Será editado manualmente)"
              component={Checkbox}
            />
            <Row>
              <Field
                xs={4}
                name="consultant_due_date"
                label="Data inicial do pagamento"
                component={InputDate}
              />
              <Field
                disabled={
                  months === 1 ||
                  valuesModal.type_plan === 'monthly' ||
                  valuesModal.type_plan === 'monthly_with_deployment'
                }
                xs={3}
                type="number"
                name="consultant_installments"
                label="Número de parcelas"
                component={Input}
                validate={[maxConsultantInstallments]}
                normalize={max(6)}
              />
            </Row>
          </>
        )}
      </Form>

      <ModalFooter>
        <Button
          color="white"
          colorText="primary"
          onClick={() => setPage('plan')}
        >
          Voltar
        </Button>
        <div className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Finalizar pagamento
        </Button>
      </ModalFooter>
    </>
  );
};

export default PaymentSlip;
