import Button from 'components/Button';
import Loading from 'components/Loading';
import { ModalFooter } from 'components/Modal';
import { SectionHeader } from 'containers/ModalRoot/ModalPayment/styles';
import { usePix } from '../../../../hooks/usePix';
import { useEffect, useState } from 'react';
import {
  FailedVerification,
  Instructions,
  PixWrapper,
  QrCode,
  Value,
} from './styles';
import Clipboard from 'react-clipboard.js';
import Alert from 'react-s-alert';
import { useDispatch } from 'react-redux';
import useFormValue from 'hooks/useFormValue';
import { PLANS } from '../../../SelectCredits';
import * as openaiService from 'services/openai';
import { openModalPaymentSuccess } from 'modules/modal';

const Pix = ({ goTo, onSuccess }) => {
  const dispatch = useDispatch();
  const plan = useFormValue('plan');
  const currentPlan = PLANS[plan];

  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationFailed, setVerificationFailed] = useState(false);

  const { code, imageUrl, contract, creditId } = usePix(plan);

  useEffect(() => {
    if (contract) contract();
  }, []);

  async function handleVerifyPayment() {
    setIsVerifying(true);

    const verification = await openaiService.getCredits(creditId);

    if (verification.data.received_at) {
      dispatch(
        openModalPaymentSuccess({
          onSuccess: ({ handleClose }) => {
            onSuccess();
            handleClose();
          },
        })
      );
    } else {
      setVerificationFailed(true);
    }

    setTimeout(() => {
      setIsVerifying(false);
    }, 1000);
  }

  if (!code) return <Loading isVisible isBlock />;

  return (
    <>
      <SectionHeader>Dados do Pix</SectionHeader>

      <PixWrapper>
        <QrCode>
          <img src={imageUrl} alt="QR Code Pix" />
          <Value>1 x ${currentPlan?.newPrice} (Nox Trading LTDA)</Value>
        </QrCode>

        {verificationFailed ? (
          <FailedVerification>
            <h2>Pagamento não encontrado</h2>
            <p>
              Após confirmação do seu banco, aguarde 30 segundos e clique em
              ”Verificar pagamento”.
            </p>
          </FailedVerification>
        ) : (
          <Instructions>
            <h2>Pagamento via QR Code</h2>
            <p>
              Abra o aplicativo do seu banco, vá a seção Pix e pagar com QR
              CODE.
            </p>

            <Clipboard
              data-clipboard-text={code}
              onClick={() => {
                Alert.success('Código copiado');
              }}
            >
              <Button tagName="span" color="tertiary" size="small">
                Copiar QR Code
              </Button>
            </Clipboard>
          </Instructions>
        )}
      </PixWrapper>

      <ModalFooter>
        <Button
          color="white"
          colorText="primary"
          onClick={goTo('SelectCredits')}
        >
          Voltar
        </Button>
        <div className="h-flex__cell--grow" />
        <Button
          type="button"
          color="success"
          disabled={isVerifying}
          onClick={handleVerifyPayment}
        >
          {isVerifying ? 'Verificando...' : 'Verificar pagamento'}
        </Button>
      </ModalFooter>
    </>
  );
};

export default Pix;
