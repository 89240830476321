import api from 'services/index';
import _ from 'lodash';

const transformResponse = (response) => {
  if (response?.data?.form_new_lead_option?.toString?.() === '1') {
    response.data.form_new_lead_user_ids =
      response?.data?.form_new_lead_user_ids?.[0];
  }

  if (response?.data?.form_already_registered_option?.toString?.() === '2') {
    response.data.form_already_registered_user_ids =
      response?.data?.form_already_registered_user_ids?.[0];
  }

  return response;
};

/**
 * Pega os dados da descrição
 * @param buildingId
 * @return {Promise<AxiosResponse<any>>}
 */
export const get = async (buildingId) => {
  const response = await api.getList(
    `settings/sites/buildings/${buildingId}/contact-form`
  );

  return transformResponse(response);
};

/**
 * Atualiza os dados da descrição
 * @return {Promise<AxiosResponse<any>>}
 */
export const update = async ({ id, ...values }) => {
  if (values?.form_new_lead_user_ids) {
    values.form_new_lead_user_ids = _.castArray(values.form_new_lead_user_ids);
  }

  if (values?.form_already_registered_user_ids) {
    values.form_already_registered_user_ids = _.castArray(
      values.form_already_registered_user_ids
    );
  }

  const response = await api.patch(
    `settings/sites/buildings/${id}/contact-form`,
    values
  );

  return transformResponse(response);
};
