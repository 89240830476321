import styled from 'styled-components';

export const Field = styled.div`
  width: 100%;
  display: flex;

  .FormGroup {
    flex: 1 0;
  }

  .FormControl {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0 none;
  }

  button {
    margin-top: 23px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const Text = styled.div`
  margin-top: -5px;
`;
