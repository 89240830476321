// import * as S from './styles'

import { ModalTemplate } from 'components/Modal';
import Modal from 'react-modal';
import FormBillPay from './components/FormBillPay';
import { usePayBill } from '../../hooks/useCreditCard';
import { useMemo } from 'react';

export function ModalBillPay({
  modalConfig,
  isOpen,
  handleClose,
  modalType,

  moviment,
  onSuccess,
}) {
  const { handlePayBill } = usePayBill();

  const initialValues = useMemo(() => {
    return {
      account_id: moviment?.account?.id,
      value: moviment?.value,
      billing_date: moviment?.billing_date,
    };
  }, [moviment]);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Confirmar pagamento" handleClose={handleClose}>
        <FormBillPay
          initialValues={initialValues}
          onSubmit={(values) => {
            return handlePayBill({
              id: moviment.credit_card_id,
              creditCardBill: moviment.id,
              ...values,
            });
          }}
          handleClose={handleClose}
          onSubmitSuccess={() => {
            if (onSuccess) onSuccess(moviment);
            handleClose();
          }}
        />
      </ModalTemplate>
    </Modal>
  );
}
