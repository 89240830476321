import styled from 'styled-components';

export const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
`;

export const Overlay = styled(Content)`
  display: grid;
  place-items: center;
  background: rgba(0, 0, 0, 0.5);
  //opacity: 0;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;

  &:hover ${Overlay} {
    opacity: 1;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
