import { useEffect } from 'react';
import { fetchStatusGuard, statusSelector } from '../modules/statusGuard';
import { useDispatch, useSelector } from 'react-redux';

export const STATUS = {
  WAITING_LIST: 1,
  ACCESS_AVAILABLE_SOON: 2,
  REQUEST_ACCESS_GRANTED: 3,
  ACCESS_GRANTED: 4,
  TRIAL_PERIOD_ENDED: 5,
};

export const useStatusGuard = () => {
  const { status, isFetching } = useSelector(statusSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStatusGuard());
  }, []);

  return {
    status,
    isFetching,
  };
};
