import Nodata from 'components/Nodata';
import imageFavorites from './image-favorites.png';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const Container = styled.div`
  flex-shrink: 0;
  width: 100%;
  max-width: 900px;
  margin: 45px auto 0;

  background: #ffffff;
  border-radius: 4px;
`;

const Inner = styled.div`
  flex-shrink: 0;
  max-width: 435px;
  margin: 0 auto;
  padding: 25px 0;
`;

export function EmptyState() {
  return (
    <Wrapper>
      <Container>
        <Inner>
          <Nodata
            image={imageFavorites}
            title="Você ainda não favoritou nenhum cliente"
            text="Identificou um cliente com potencial para fechar negócio e que merece atenção especial? Adicione-o aos favoritos e acompanhe de perto!"
          />
        </Inner>
      </Container>
    </Wrapper>
  );
}
