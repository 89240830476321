import Modal from 'react-modal';
import { ModalTemplate } from 'components/Modal';
import { openModal } from 'modules/modal';
import { useModalPosting } from './hooks/useModalPosting';
import FormStorePosting from './components/FormStorePosting';
import { useDispatch } from 'react-redux';
import { useStorePostings } from '../../hooks/usePostings';
import { useEffect, useMemo } from 'react';
import moment, { format } from 'lib/moment';
import { useShowMoviment } from '../../hooks/useMoviments';
import { useUserStorage } from 'hooks/useUserStorage';
import { useGetAccounts } from '../../hooks/useAccounts';

export const openModalPosting =
  ({ movimentId, postingId, type = 1, posting, onSuccess, onCancel }) =>
  (dispatch) => {
    dispatch(
      openModal({
        type: 'ModalPosting',
        size: 470,
        position: 'center',
        props: {
          movimentId,
          postingId,
          type,
          posting,
          onSuccess,
          onCancel,
        },
      })
    );
  };

export function ModalPosting({
  modalConfig,
  isOpen,
  handleClose: _handleClose,
  modalType,

  movimentId,
  postingId,
  type: _type = 1,
  posting,
  onSuccess,
  onCancel,
}) {
  const getAccounts = useGetAccounts({ type: 'all' });

  const { get, set } = useUserStorage();

  const handleClose = () => {
    if (onCancel) return onCancel();
    _handleClose();
  };

  const dispatch = useDispatch();
  // const {} = use;
  const { moviment, movimentIsFetching, fetchMoviment } = useShowMoviment();

  const type = useMemo(() => {
    if (moviment?.posting?.id) {
      return moviment?.posting?.is_debit ? 2 : 1;
    }

    return _type;
  }, [_type, moviment]);

  const { storePostings } = useStorePostings({
    isMoviment: !!movimentId && !!postingId,
    type,
  });

  const { configs } = useModalPosting({ type, posting, moviment });

  useEffect(() => {
    if (movimentId && postingId) {
      fetchMoviment({ postingId, movimentId });
    }
  }, [movimentId, postingId]);

  useEffect(() => {
    getAccounts.fetchAccounts({ type: 'all' });
  }, []);

  const reopenModal = (values) => {
    dispatch(
      openModalPosting({
        type,
        posting: values,
        onSuccess,
      })
    );
  };

  const handleOnCreatePerson = (values) => {
    reopenModal(values);
  };

  const initialValues = useMemo(() => {
    if (moviment?.id) {
      return {
        is_credit_card: !!moviment.account_id,
        ...moviment,
      };
    }

    const accountId = get('account_id');

    let anotherFields = {};

    // Verifica se esta retornando corretamente todas as contas
    if (getAccounts.allAccounts && accountId) {
      const hasAccountId = getAccounts.allAccounts.some(
        (account) => account.id === accountId
      );

      if (hasAccountId) {
        anotherFields = {
          account_id: accountId,
          is_credit_card: true,
        };
      }
    }

    return {
      [configs.date_field_name]: moment().format(format.date),
      repetition_type: 'unic',
      recurrency_type_id: '2ef7f7ec-697e-47a6-be8d-66758e370ea3',
      is_credit_card: false,
      ...anotherFields,
      ...posting,
    };
  }, [posting, moviment, getAccounts]);

  const onSubmitSuccess = (res, dispatch, form) => {
    // Verifica se tem o accountId
    if (form?.values?.account_id) {
      // Se tiver adiciona ele no localStorage
      set('account_id', form?.values?.account_id);
    }
    if (onSuccess) onSuccess(res);
    handleClose();
  };

  if (movimentIsFetching) return null;

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title={configs.titleModal} handleClose={handleClose}>
        <FormStorePosting
          getAccounts={getAccounts}
          moviment={moviment}
          initialValues={initialValues}
          type={type}
          configs={configs}
          handleClose={handleClose}
          onCreatePerson={handleOnCreatePerson}
          reopenModal={reopenModal}
          onSubmit={(values) => {
            return storePostings({
              postingId,
              movimentId,
              ...values,
            });
          }}
          onSubmitSuccess={onSubmitSuccess}
        />
      </ModalTemplate>
    </Modal>
  );
}
