import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { FaWhatsapp } from 'react-icons/fa';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
// Modules
import { openModalPersonAdd, openModalPersonRemove } from 'modules/modal';
// Components
import ListInfo from 'components/ListInfo';
import { ModalSubtitle } from 'components/Modal';
import Button from 'components/Button';
import Can from 'containers/Can';
import { PERSON_PERMISSIONS } from 'constants/rules';
import RequestPermission from 'containers/ModalRoot/ModalPerson/components/RequestPermission';
import { countries } from 'country-flag-icons';
import flags from 'country-flag-icons/react/3x2';

import { Phones } from './styles';
import { shareWhatsapp } from 'components/ChatBox';
import * as plenoService from 'services/integrations/pleno';
import Alert from 'react-s-alert';
import Dropdown, { Item, List } from 'components/Dropdown';
import iconPleno from 'assets/img/integrations/icon-pleno.png';
import iconSuperlogica from 'assets/img/integrations/icon-superlogica.png';
import { useSuperlogica } from 'hooks/useSuperlogica';
import { MdDelete } from 'react-icons/md';
import * as people from 'services/people';
import { openConfirmation } from 'containers/ModalConfirmation/module';

const propTypes = {
  openModalPersonAdd: PropTypes.func.isRequired,
};
const defaultProps = {};

const Flag = ({ size = 20, style, iso2, ...props }) => {
  const iso2Upper = iso2.toUpperCase();

  // Verifica se tem a bandeira na biblioteca
  // Também verifica se o número é brasileiro se for não aparece bandeira
  if (!countries.includes(iso2Upper) || iso2Upper === 'BR') return null;

  return React.createElement(
    flags[iso2Upper],
    { ...props, style: { width: size, ...style } },
    null
  );
};

// Infos
class InfosCmp extends React.Component {
  componentDidMount() {
    ReactTooltip.rebuild();
  }

  handleOpenModalPerson = (id, callBack) => () => {
    this.props.openModalPersonAdd(id, callBack);
  };

  handleReactivatePerson = () => {
    const {
      modalPerson: { person },
    } = this.props;

    this.props.openConfirmation({
      title: `Reativar cliente`,
      text: `Tem certeza que deseja reativar o cliente: ${person.name}?`,
      request: () => {
        return people.reactivateStatus(person.id);
      },
      onSuccess: () => {
        // Atualiza a modal
        this.props.fetchPerson();

        if (this.props.reopenModal) this.props.reopenModal();
      },
      onCancel: () => {
        if (this.props.reopenModal) this.props.reopenModal();
      },
      submitButtonText: 'Reativar',
      submitButtonColor: 'success',
    });
  };

  handleInativePerson = () => {
    const {
      modalPerson: { person },
    } = this.props;

    this.props.openConfirmation({
      size: '460px',
      title: `Deseja mover este cliente para lista de inativos?`,
      text: (
        <>
          <div>Ele deixará de aparecer nas listas de seleção de clientes</div>
          <div>
            Negócios existentes no CRM serão fechados e os perfis desativados
          </div>
        </>
      ),
      request: () => {
        return people.inactiveStatus(person.id);
      },
      submitButtonText: 'Inativar',
      submitButtonColor: 'danger',
      onSuccess: () => {
        // Atualiza a modal
        this.props.fetchPerson();
      },
    });
  };

  handleRemovePerson = () => {
    this.props.openModalPersonRemove({
      id: this.props.person.id,
      onSuccess: () => {
        if (this.props.afterClose) this.props.afterClose();
      },
      onCancel: () => {
        if (this.props.reopenModal) this.props.reopenModal();
      },
    });
  };

  renderGroupPerson = (groups) => {
    return groups ? groups.map((group) => group.name).join(', ') : '--';
  };

  renderPhones = (phones) => {
    if (isEmpty(phones)) return 'Nenhum telefone';

    return phones
      .map(({ number, ddi, iso2, description, is_whatsapp }) => {
        if (is_whatsapp) {
          return (
            <a
              target="_blank"
              href={shareWhatsapp(ddi + number, '')}
              style={{ color: '#000' }}
              rel="noreferrer"
            >
              <Flag iso2={iso2} style={{ marginRight: '5px' }} />
              {is_whatsapp && <FaWhatsapp className="h-color--success" />}
              {ddi !== 55 && ` +${ddi} `}
              {number} 
              {description ? ` - ${description} ` : null}
            </a>
          );
        }

        return (
          <span>
            <Flag iso2={iso2} style={{ marginRight: '5px' }} />
            {ddi !== 55 && `+${ddi} `}
            {number}
            {description ? `(${description})` : null}
            {is_whatsapp && <FaWhatsapp className="h-color--success" />}
          </span>
        );
      })
      .reduce((acc, x) => {
        return acc === null ? [x] : [acc, ', ', x];
      }, null);
  };

  get renderRelateds() {
    const {
      modalPerson: { person },
      openModalPerson,
    } = this.props;

    const allRelateds = person?.relateds;

    if (isEmpty(allRelateds)) return 'Nenhum cliente relacionado';

    return allRelateds
      .map(({ id, name, description }) => {
        return (
          <span>
            <span
              onClick={() => openModalPerson({ id }, { initialPage: 'Infos' })}
              className="h-link"
            >
              {name}
            </span>
            ({description})
          </span>
        );
      })
      .reduce((acc, x) => {
        return acc === null ? [x] : [acc, ', ', x];
      }, null);
  }

  get isPleno() {
    const currentUser = localStorage.getItem('current-user');
    return currentUser.real_estate.auths.plenoimob;
  }

  handleRegisterInPleno = () => {
    const { person } = this.props;

    this.props.openConfirmation({
      title: 'Enviar cliente ao Pleno Imob?',
      text: 'Deseja enviar esse cliente para o Pleno Imob?',
      request: () => {
        plenoService.sendPerson(person.id).then(() => {
          Alert.success('Cliente enviado no Pleno Imob.');
        });
      },
      submitButtonColor: 'success',
      submitButtonText: 'Enviar ao Pleno Imob',
    });
  };

  handleRegisterInSuperlogica = ({ type }) => {
    const { person, superlogica } = this.props;

    this.props.openConfirmation({
      title: 'Enviar cliente para Superlógica?',
      text: 'Deseja enviar esse cliente para a Superlógica?',
      request: () => {
        superlogica.sendPerson({ id: person.id, type }).then(() => {
          Alert.success('Cliente enviado no Superlógica.');
        });
      },

      submitButtonColor: 'success',
      submitButtonText: 'Enviar para Superlógica',
    });
  };

  render() {
    const {
      modalPerson: { person },
      onSubmitSuccessEdit,
    } = this.props;

    return (
      <Can
        run={PERSON_PERMISSIONS.PRIVATE}
        permissions={person.permissions}
        no={() => <RequestPermission person={person} />}
        yes={() => (
          <div>
            <ModalSubtitle title="Dados do cliente">
              <Can
                run={PERSON_PERMISSIONS.EDIT}
                permissions={person.permissions}
              >
                {(condition) => {
                  let tooltipProps = {};
                  if (!condition) {
                    tooltipProps = {
                      'data-tip':
                        'Você não tem permissão para editar esse cliente',
                    };
                  }
                  return (
                    <span style={{ display: 'inline-block' }} {...tooltipProps}>
                      {this.props.superlogica.isConnected && (
                        <Dropdown isDropHover className="h-margin-right--10">
                          <Button color="white" size="medium">
                            <img
                              src={iconSuperlogica}
                              alt=""
                              style={{
                                display: 'inline-block',
                                height: '14px',
                                verticalAlign: 'middle',
                                marginLeft: '-5px',
                              }}
                            />{' '}
                            Superlógica
                          </Button>
                          <List>
                            <Item>
                              <button
                                onClick={() => {
                                  this.handleRegisterInSuperlogica({
                                    type: '1',
                                  });
                                }}
                              >
                                Enviar dados como Proprietário
                              </button>
                            </Item>
                            <Item>
                              <button
                                onClick={() => {
                                  this.handleRegisterInSuperlogica({
                                    type: '2',
                                  });
                                }}
                              >
                                Enviar dados como Locatário
                              </button>
                            </Item>
                            <Item>
                              <button
                                onClick={() => {
                                  this.handleRegisterInSuperlogica({
                                    type: '3',
                                  });
                                }}
                              >
                                Enviar dados como Fiador
                              </button>
                            </Item>
                          </List>
                        </Dropdown>
                      )}
                      {this.isPleno && (
                        <Dropdown isDropHover className="h-margin-right--10">
                          <Button color="white" size="medium">
                            <img
                              src={iconPleno}
                              alt=""
                              style={{
                                display: 'inline-block',
                                height: '14px',
                                verticalAlign: 'middle',
                                marginLeft: '-5px',
                              }}
                            />{' '}
                            Pleno
                          </Button>
                          <List>
                            <Item>
                              <button onClick={this.handleRegisterInPleno}>
                                Enviar dados
                              </button>
                            </Item>
                          </List>
                        </Dropdown>
                      )}

                      {person.status === '0' && (
                        <>
                          <Button
                            size="medium"
                            color="success"
                            onClick={this.handleReactivatePerson}
                          >
                            Reativar
                          </Button>
                        </>
                      )}

                      <Button
                        disabled={!condition}
                        color="secondary"
                        size="medium"
                        onClick={this.handleOpenModalPerson(person, () => {
                          if (onSubmitSuccessEdit) onSubmitSuccessEdit();
                        })}
                      >
                        Editar Dados
                      </Button>

                      {person.status === '0' ? (
                        <Button
                          color="danger"
                          size="medium"
                          onClick={this.handleRemovePerson}
                        >
                          <MdDelete style={{ margin: '0 -10px' }} />
                        </Button>
                      ) : (
                        <Dropdown isDropHover className="h-margin-right--10">
                          <Button color="danger" size="medium">
                            <MdDelete style={{ margin: '0 -10px' }} />
                          </Button>
                          <List>
                            <Item>
                              <button onClick={this.handleInativePerson}>
                                Inativar Cliente (Arquivar)
                              </button>
                            </Item>
                            <Item onClick={this.handleRemovePerson}>
                              <button className="h-color--danger">
                                Excluir permanentemente
                              </button>
                            </Item>
                          </List>
                        </Dropdown>
                      )}
                    </span>
                  );
                }}
              </Can>
            </ModalSubtitle>
            <ListInfo>
              <ListInfo.row>
                <ListInfo.item
                  title="Categoria"
                  text={this.renderGroupPerson(person.groups)}
                />
                <ListInfo.item
                  title="Origem da captação"
                  text={person?.reception_source?.name}
                />
                <ListInfo.item
                  title="Telefones"
                  text={<Phones>{this.renderPhones(person.phones)}</Phones>}
                />
              </ListInfo.row>
              <ListInfo.row>
                <ListInfo.item title="E-mail" text={person.email || '--'} />
                <ListInfo.item
                  title="Aniversário"
                  text={person.birth_date || '--'}
                />
                <ListInfo.item title="Tipo" text={person.type_name || '--'} />
                <ListInfo.item title="CPF" text={person.cpf_cnpj || '--'} />
                <ListInfo.item title="RG" text={person.rg_ie || '--'} />
                <ListInfo.item
                  title="Profissão"
                  text={person.profession || '--'}
                />
              </ListInfo.row>
              <ListInfo.row>
                <ListInfo.item
                  title="UF"
                  text={(person.state && person.state.name) || '--'}
                />
                <ListInfo.item
                  title="Cidade"
                  text={(person.city && person.city.name) || '--'}
                />
                <ListInfo.item
                  title="Bairro"
                  text={
                    (person.neighborhood && person.neighborhood.name) || '--'
                  }
                />
                <ListInfo.item
                  title="Logradouro"
                  text={person.address_street || '--'}
                />
                <ListInfo.item
                  title="Complemento"
                  text={person.address_complement || '--'}
                />
                <ListInfo.item
                  title="Nº"
                  text={person.address_number || '--'}
                />
                <ListInfo.item title="CEP" text={person.address_cep || '--'} />
              </ListInfo.row>
            </ListInfo>
            <ListInfo>
              <ListInfo.row>
                <ListInfo.item
                  title="Clientes ligados"
                  text={this.renderRelateds}
                />
              </ListInfo.row>
            </ListInfo>
          </div>
        )}
      />
    );
  }
}

const Infos = ({ ...props }) => {
  const { data, isConnected, sendPerson } = useSuperlogica();

  return (
    <InfosCmp
      superlogica={{
        data,
        isConnected,
        sendPerson,
      }}
      {...props}
    />
  );
};

Infos.propTypes = propTypes;
Infos.defaultProps = defaultProps;

export default connect(null, {
  openModalPersonAdd,
  openModalPersonRemove,
  openConfirmation,
})(Infos);
