import Container from 'components/Container';
import MainTitle from 'components/MainTitle';
import Wrapper from 'components/Wrapper';
import React, { useEffect } from 'react';
// Containers - Integrations
import { FACEBOOK_APP_ID } from 'constants/config';
import PlenoIntegration from 'pages/Integrations/containers/PlenoIntegration';
import SuperlogicaIntegration from 'pages/Integrations/containers/SuperlogicaIntegration';
import AnalyticsIntegration from '../../containers/AnalyticsIntegration';
import CalendarIntegration from '../../containers/CalendarIntegration';
import FacebookLeadsIntegration from '../../containers/FacebookLeadsIntegration';
import FacebookStoreIntegration from '../../containers/FacebookStoreIntegration';
import OruloIntegration from '../../containers/OruloIntegration';
import DwvIntegration from '../../containers/DwvIntegration';
import RdStationIntegration from '../../containers/RdStationIntegration';
import RdStationProvider from '../../containers/RdStationIntegration/context';
import { LeadfyIntegration } from '../../containers/LeadfyIntegration';

import { List } from './styles';
import BoxHelp from 'components/BoxHelp';
import SmtpIntegration from 'pages/Integrations/containers/SmtpIntegration';
import { useCan } from 'hooks/useCan';
import { useCurrentItems } from 'hooks/api/contracts';

// Components
const FB = window.FB;

const Integrations = () => {
  const { currentItems, fetchCurrentItems } = useCurrentItems();

  const canEditSMTP = useCan({ run: 'EDIT_SMTP' });

  useEffect(() => {
    FB?.init({
      appId: FACEBOOK_APP_ID,
      cookie: true, // Enable cookies to allow the server to access the session.
      xfbml: true, // Parse social plugins on this webpage.
      version: 'v12.0', // Use this Graph API version for this call.
    });
  }, []);

  return (
    <RdStationProvider>
      <Wrapper.container>
        <MainTitle
          title="Integrações"
          text="Gerencie os aplicativos que estão integrados com o seu sistema."
        />
        <BoxHelp
          storageKey="integrations"
          topics={[
            {
              text: 'Integrar com Facebook formulários',
              link: 'https://scribehow.com/page/Como_integrar_com_Facebook_Formularios__LNPCPzXtRvCE50MEd8wCKg',
            },
            {
              text: 'Integrar com Facebook catálogos',
              link: 'https://scribehow.com/page/Como_integrar_com_o_Facebook_Catalogo__gheDZqHzQ-2e28voSZ6aNg',
            },
            {
              text: 'Integrar com a Órulo',
              link: 'https://scribehow.com/shared/Integrar_com_a_Orulo__m9I9WG0NRTuiX1by33sCcA',
            },
            {
              text: 'Integrar com DWV',
              link: 'https://scribehow.com/shared/Integrar_com_DWV__zab_-zX_RM29ebv4pfMhZQ',
            },
          ]}
          helpLink="https://tecimob.com.br/ajuda/integracoes/"
        />
        <Container padding>
          <List style={{ marginBottom: '40px' }}>
            <CalendarIntegration />
            <AnalyticsIntegration />
            <OruloIntegration
              currentItems={currentItems}
              fetchCurrentItems={fetchCurrentItems}
            />
            <DwvIntegration
              currentItems={currentItems}
              fetchCurrentItems={fetchCurrentItems}
            />
            <RdStationIntegration />
            <LeadfyIntegration />
            <FacebookStoreIntegration />
            <FacebookLeadsIntegration />
            <PlenoIntegration />
            <SuperlogicaIntegration />
            {canEditSMTP && <SmtpIntegration />}
          </List>
        </Container>
      </Wrapper.container>
    </RdStationProvider>
  );
};

export default Integrations;
