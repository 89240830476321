import { openModal } from 'modules/modal';
import { useDispatch } from 'react-redux';

export const openModalBillPay =
  ({ moviment, onSuccess }) =>
  (dispatch) => {
    dispatch(
      openModal({
        type: 'ModalBillPay',
        size: 470,
        position: 'center',
        props: {
          moviment,
          onSuccess,
        },
      })
    );
  };

export const useModalBillPay = () => {
  const dispatch = useDispatch();

  const handleOpenModalBillPay = ({ moviment, onSuccess }) => {
    // Abre a modal se o tipo de movimento for BILL
    dispatch(
      openModalBillPay({
        moviment,
        onSuccess,
      })
    );
  };

  return { handleOpenModalBillPay };
};
