import { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import PropertyItem from './components/PropertyItem';
import noHistory from './no-history.png';
import home from './HomeIcon.svg';
import {
  Content,
  IconSearch,
  LeftContent,
  NoContent,
  Title,
  Wrapper,
} from './styles';

function LastProperties({ data }) {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const noData = !data || data?.length <= 0;
  const hasShowMore = data.length > 3;

  const toggleFullscreen = () => {
    setIsFullScreen((prevState) => !prevState);
  };

  return (
    <ThemeProvider
      theme={{
        isFullScreen,
      }}
    >
      <Wrapper>
        <Title>
          <LeftContent>
            <IconSearch src={home} alt="home" />
            <span>Últimos imóveis</span>{' '}
          </LeftContent>
          {hasShowMore && (
            <button className="h-link" onClick={toggleFullscreen}>
              {isFullScreen ? 'Voltar' : 'Ver mais'}
            </button>
          )}
        </Title>
        <Content>
          {noData ? (
            <NoContent>
              <img src={noHistory} alt="Sem histórico" />
            </NoContent>
          ) : (
            data.slice(0, isFullScreen ? 10 : 3).map((property, i) => (
              <PropertyItem
                key={property.id + i + 'last-properties'}
                property={property}
                onClick={() => {
                  setIsFullScreen(false);
                }}
              />
            ))
          )}
        </Content>
      </Wrapper>
    </ThemeProvider>
  );
}

export default LastProperties;
