import { useMemo } from 'react';

export const useTextsAccount = ({ account, type = 1 }) => {
  return useMemo(() => {
    // Conta
    if (type === 1) {
      if (account?.id) {
        return {
          titleModal: 'Editar conta bancária',
        };
      }

      return {
        titleModal: 'Nova conta bancária',
      };
    }

    // Cartão de crédito
    if (account?.id) {
      return {
        titleModal: 'Editar cartão de crédito',
      };
    }

    return {
      titleModal: 'Novo cartão de crédito',
    };
  }, [account, type]);
};
