import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  border-top: 1px solid rgba(0, 0, 0, 0.1);

  & + & {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

export const Wrapinput = styled.div`
  width: 100%;

  .Select-menu {
    max-height: 395px !important;
  }
`;

export const Subcategory = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 20px;
`;

export const Ball = styled.div`
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(p) => p.color};
`;
export const Text = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Label = styled.div`
  display: inline-block;
  padding: 3px 8px;
  border-radius: 25px;
  color: #fff;
  background: #000;
  font-size: 12px;
`;
