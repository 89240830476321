import React, { useEffect, useMemo } from 'react';
import { Row } from 'react-flexbox-grid';
import { Field, FieldArray, reduxForm } from 'redux-form';

// Component
import MainTitle from 'components/MainTitle';
import SeeExample from 'pages/EnterpriseStore/components/SeeExample';
import Container from 'components/Container';
import FormFixedBar from '../../components/FormFixedBar';
import { Input } from 'components/Form';
import ListOptions from 'components/ListOptions';
import TableProgress from './components/TableProgress';
import PopoverProgress from '../../components/PopoverProgress';
import houses from './houses.svg';

// Constants
import { FORM_NAME } from 'pages/EnterpriseStore/constants';
import Button from 'components/Button';
import { NoInformation } from './styles';
import withProvider from 'HOC/withProvider';
import { ProgressProvider, useProgress } from './contexts/ProgressContext';
import * as progressBuilding from 'services/buildings/progress';
import { compose } from 'redux';
import Loading from 'components/Loading';
import PhotosProgress from 'pages/EnterpriseStore/pages/Progress/components/PhotosProgress';
import Alert from 'react-s-alert';

const ProgressPage = ({ handleSubmit, initialize }) => {
  const { data, isFetching, fetchData } = useProgress();

  // Inicializa os dados do formulário
  useEffect(() => {
    initialize(data);
  }, [data]);

  const hasProgress = useMemo(() => {
    try {
      return !!data?.progresses && data?.progresses?.length > 0;
    } catch {
      return false;
    }
  }, [data]);

  if (isFetching) {
    return (
      <form onSubmit={handleSubmit}>
        <MainTitle
          title="Etapas do progresso"
          text="Diga, em porcentagem, como está sendo o progresso de cada área."
        >
          <SeeExample />
        </MainTitle>
        <Container>
          <Loading isVisible isBlock />
        </Container>
      </form>
    );
  }

  if (!hasProgress)
    return (
      <>
        <MainTitle
          title="Etapas do progresso"
          text="Diga, em porcentagem, como está sendo o progresso de cada área."
        >
          <SeeExample />
        </MainTitle>
        <form onSubmit={handleSubmit}>
          <Container>
            <NoInformation
              image={houses}
              position="left"
              title="Progresso"
              text={
                <>
                  Defina em que estágio está
                  <br /> cada parte do empreendimento.
                </>
              }
            >
              <PopoverProgress
                onSubmitSuccess={() => {
                  fetchData();
                }}
              >
                <Button color="secondary" className="h-margin-top--15">
                  Adicionar Progresso
                </Button>
              </PopoverProgress>
            </NoInformation>
          </Container>
        </form>
      </>
    );

  return (
    <form onSubmit={handleSubmit}>
      <MainTitle
        title="Progresso"
        text="Adicione como está o progresso de cada parte do empreendimento."
      >
        <PopoverProgress
          onSubmitSuccess={() => {
            fetchData();
          }}
        >
          <Button color="secondary">Adicionar Progresso</Button>
        </PopoverProgress>
        <SeeExample />
      </MainTitle>

      <Container padding>
        <ListOptions>
          <ListOptions.item title="Título da área">
            <Row className="h-margin-top--15">
              <Field xs={12} name="progress_title" component={Input} />
            </Row>
          </ListOptions.item>
        </ListOptions>
      </Container>

      <FieldArray
        axis="xy"
        name="images"
        component={PhotosProgress}
        rerenderOnEveryChange
      />

      <Container padding style={{ marginTop: '15px' }}>
        <ListOptions>
          <ListOptions.item>
            <TableProgress data={data?.progresses} />
          </ListOptions.item>
        </ListOptions>
      </Container>

      <FormFixedBar />
    </form>
  );
};

export default compose(
  withProvider(ProgressProvider),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmit: progressBuilding.update,
    onSubmitFail: (err) => {
      if (err.images[0] === 'Campo obrigatório.') {
        Alert.success('Voce precisa enviar pelo menos uma imagem');
      }
    },
  })
)(ProgressPage);
