import * as Panel from '../Panel';
import * as S from './styles';
import { useGetAccounts } from 'features/Financial/hooks/useAccounts';
import { useEffect } from 'react';
import { useDashboardContext } from '../../hooks/useDashboardContext';
import { EmptyState } from './EmptyState';

export function PanelAccounts() {
  const { refreshKey } = useDashboardContext();
  const { accounts, metaAccounts, fetchAccounts, isFetchingAccounts } =
    useGetAccounts({
      type: 1,
    });

  useEffect(() => {
    fetchAccounts({});
  }, [refreshKey]);

  if (accounts.length <= 0 && !isFetchingAccounts) {
    return <EmptyState />;
  }

  return (
    <Panel.Wrapper>
      <Panel.Header>
        <Panel.Title>Saldo geral</Panel.Title>
        <Panel.Price>{metaAccounts.general_balance}</Panel.Price>
      </Panel.Header>
      <Panel.Content>
        <Panel.ContentTitle>Minhas contas bancárias</Panel.ContentTitle>
        <S.Table>
          <tbody>
            {accounts.map((account) => (
              <tr key={account.id}>
                <td>
                  <S.Account>
                    <S.Icon src={account?.icon?.file_url} />
                    <S.Text>{account.title}</S.Text>
                  </S.Account>
                </td>
                <S.CellSmall>
                  <S.Price>{account.balance}</S.Price>
                </S.CellSmall>
              </tr>
            ))}
          </tbody>
        </S.Table>
      </Panel.Content>
      <Panel.Footer>
        <Panel.FullButton to="/financial/accounts">
          Gerenciar contas bancárias
        </Panel.FullButton>
      </Panel.Footer>
    </Panel.Wrapper>
  );
}
