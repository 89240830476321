import { Financial } from 'features/Financial';
import { MdCheck } from 'react-icons/md';
import * as S from './styles';
import Button from 'components/Button';
import { askStatusGuard } from '../../../../modules/statusGuard';
import { useDispatch } from 'react-redux';
import { useRef } from 'react';

export function WaitingList() {
  const dispatch = useDispatch();

  const videoRef = useRef();

  const requestFullScreen = () => {
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    } else if (videoRef.current.webkitRequestFullscreen) {
      // Para navegadores baseados no WebKit (Safari)
      videoRef.current.webkitRequestFullscreen();
    } else if (videoRef.current.mozRequestFullScreen) {
      // Para navegadores baseados no Firefox
      videoRef.current.mozRequestFullScreen();
    } else if (videoRef.current.msRequestFullscreen) {
      // Para navegadores baseados no IE
      videoRef.current.msRequestFullscreen();
    }
  };

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        requestFullScreen();
      } else {
        videoRef.current.pause();
      }
    }
  };

  return (
    <Financial.Content>
      <S.Box>
        <S.Wrapper>
          <S.BoxLeft>
            <S.Title>Controle Financeiro Total em um Só Lugar</S.Title>
            <S.Text>
              Conheça o novo recurso do Tecimob: o Módulo Financeiro. Uma
              ferramenta prática e eficiente para simplificar sua gestão
              financeira. Com ele, você pode:
            </S.Text>
            <S.List>
              <li>
                <S.Ball>
                  <MdCheck />
                </S.Ball>
                Realizar lançamentos financeiros de forma organizada;
              </li>
              <li>
                <S.Ball>
                  <MdCheck />
                </S.Ball>
                Acompanhar o fluxo de caixa com clareza;
              </li>
              <li>
                <S.Ball>
                  <MdCheck />
                </S.Ball>
                Cadastrar contas bancárias e cartões de crédito para um controle
                completo.
              </li>
            </S.List>
            <p>
              Teste gratuitamente o nosso novo módulo financeiro por 7 dias!
              Para garantir o suporte adequado, o acesso será liberado
              gradualmente por meio de uma fila de espera. Após o período de
              teste, o módulo estará disponível por apenas R$29,90 mensais.
              Aproveite essa novidade para otimizar sua gestão financeira e
              alcançar mais resultados!
            </p>
            <Button
              block
              color="success"
              onClick={() => {
                dispatch(askStatusGuard());
              }}
            >
              Entrar na fila de espera
            </Button>
          </S.BoxLeft>
          <S.BoxRight>
            <S.WrapVideo>
              <video
                ref={videoRef}
                muted={false}
                width="100%"
                poster="/video-financeiro.png"
                onClick={handleVideoClick}
              >
                <source src="/video-financeiro.mp4" type="video/mp4" />
                Seu navegador não suporta o elemento de vídeo.
              </video>
            </S.WrapVideo>
          </S.BoxRight>
        </S.Wrapper>
      </S.Box>
    </Financial.Content>
  );
}
