import { STATUS, useStatusGuard } from '../../hooks/useStatusGuard';
import { WaitingList } from './components/WaitingList';
import { AccessAvailableSoon } from './components/AccessAvailableSoon';
import { RequestAccessGranted } from './components/RequestAccessGranted';
import { TrialPeriodEnded } from './components/TrialPeriodEnded';

export function StatusGuard({ children }) {
  const { status, isFetching } = useStatusGuard();

  if (isFetching) {
    return null;
  }

  // Verifica se o cliente está na lista de espera
  if (status === STATUS.WAITING_LIST) {
    return <WaitingList />;
  }

  // Cliente pediu pra entrar na fila de espera
  if (status === STATUS.ACCESS_AVAILABLE_SOON) {
    return <AccessAvailableSoon />;
  }

  // Suporte já aprovou só que só vai começar a passar o tempo mesmo quando
  // estiver no status 4 que é depois dessa tela
  if (status === STATUS.REQUEST_ACCESS_GRANTED) {
    return <RequestAccessGranted />;
  }

  // Verifica se o período de teste terminou
  // Se terminou tem que mostrar a tela com alguma forma dele poder contratar
  if (status === STATUS.TRIAL_PERIOD_ENDED) {
    return <TrialPeriodEnded />;
  }

  // Se não for nenhum status acima é o status 4 que é o de liberação
  // Cliente vai poder ver tudo do financeiro
  return children;
}
