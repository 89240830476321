import styled from 'styled-components';

export const Text = styled.div`
  width: 364px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 1.4em;
  color: #3c4858;

  h2 {
    font-size: 24px;
  }
`;

export const Content = styled.div`
  padding-top: 40px;
  padding-bottom: 10px;
`;
