import React, { useState } from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// Components
import { Input } from 'components/Form';
import ModalFooter from 'components/Modal/components/ModalFooter';
import Button from 'components/Button';
import ModalTemplate from 'components/Modal/components/ModalTemplate';
import * as S from './styles';
import * as domainsService from 'services/settings/domains';
import useFormValue from 'hooks/useFormValue';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const PreRegisterDomainForm = ({ url, handleClose, handleSubmit }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [message, setMessage] = useState(null);

  const urlValue = useFormValue('url');

  const handleCheckDomain = async () => {
    const res = await domainsService.checkDomain(urlValue);

    console.log(res);
  };

  const handleCheck = async () => {
    try {
      await handleCheckDomain();

      setIsChecked(true);
      setMessage({
        text: 'Disponível para registro!',
        color: 'success',
      });
    } catch (err) {
      const errorFromApi = err?.errors?.url?.[0];

      setIsChecked(false);
      setMessage({
        text: errorFromApi
          ? errorFromApi
          : 'O domínio não esta disponível, tente outro.',
        color: 'danger',
      });
    }
  };

  const handleChangeField = () => {
    setIsChecked(false);
    setMessage(null);
  };

  return (
    <ModalTemplate title="Verificar disponibilidade" handleClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <S.Field>
          <Field
            label="Domínio"
            name="url"
            component={Input}
            onChange={handleChangeField}
          />
          <Button type="button" color="success" onClick={handleCheck}>
            Verificar
          </Button>
        </S.Field>
        {message ? (
          <S.Text className={`h-color--${message?.color}`}>
            {message?.text}
          </S.Text>
        ) : null}
        <ModalFooter>
          <Button color="white" colorText="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <span className="h-flex__cell--grow"></span>
          <Button disabled={!isChecked} type="submit" color="success">
            Prosseguir
          </Button>
        </ModalFooter>
      </form>
    </ModalTemplate>
  );
};

PreRegisterDomainForm.propTypes = propTypes;

const selector = formValueSelector('PreRegisterDomainForm');

const mapStateToProps = (state) => ({
  url: selector(state, 'url'),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'PreRegisterDomainForm',
    enableReinitialize: true,
  })(PreRegisterDomainForm)
);
