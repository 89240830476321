// import styles from './styles'
import { Financial } from 'features/Financial';
import Button from 'components/Button';

export function Nodata({ onAddAccount }) {
  return (
    <Financial.Nodata title="Você ainda não possui nenhum cartão de crédito cadastrado.">
      <Button color="secondary" onClick={onAddAccount({})}>
        Adicionar cartão de crédito
      </Button>
    </Financial.Nodata>
  );
}
