import PropTypes from 'prop-types';
import { Component, createElement } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import Alert from 'react-s-alert';
import ModalPersonOrulo from './components/ModalPersonOrulo';

import { closeModal, openModalPerson, openModalPersonAdd } from 'modules/modal';
import {
  clearModal,
  fetchPersonAudits,
  fetchPersonCountRelations,
  getPerson,
  getPersonInfo,
  relationsSelector,
  setPage,
  toggleFavorite,
} from 'modules/modalPerson';
// Components
import { ModalTemplate } from 'components/Modal';
import SelectOwner from 'containers/SelectOwner';
// Containers
import Can from 'containers/Can';
// Pages
import TextLoading from 'components/TextLoading';
import TextOverflow from 'components/TextOverflow';
import { PERSON_PERMISSIONS } from 'constants/rules';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { removeParam } from 'lib/url-helpers';
import CopyDataPerson from './components/CopyDataPerson';
import Crm from './components/Crm';
import Dashboard from './components/Dashboard';
import Documents from './components/Documents';
import Emails from './components/Emails';
import Events from './components/Events';
import Infos from './components/Infos';
import Notes from './components/Notes';
import Properties from './components/Properties';
import SearchProfile from './components/SearchProfile';
// import ModalPersonOrulo from 'containers/ModalRoot/ModalPersonOrulo';
import * as plenoService from 'services/integrations/pleno';
import { whatsappSendUrl } from 'lib/share-helpers';
import { ModalActions } from 'containers/ModalRoot/ModalPerson/components/ModalActions';

const propTypes = {
  modalType: PropTypes.string.isRequired,
  modalConfig: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmitSuccessEdit: PropTypes.func.isRequired,
};

const defaultProps = {
  person: {
    id: null,
  },
  handleSubmitSuccessEdit: null,
  initialPage: 'Dashboard',
};

const Pages = {
  Dashboard,
  Documents,
  Events,
  Infos,
  Notes,
  Properties,
  SearchProfile,
  Crm,
  Emails,
};

const style = {
  menu: {
    display: 'flex',
    margin: '-20px -20px 20px -20px',
    borderBottom: '1px solid #e0e5eb',
  },
  item: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    padding: '17px 15px',
    color: 'rgba(62, 88, 124, 0.54)',
  },
  itemActive: {
    color: '#0063c0',
    backgroundColor: 'rgba(0, 99, 192, 0.1)',
  },
};

class ModalPerson extends Component {
  state = {
    isFetchingPerson: false,
  };

  handleEsc = (event) => {
    if (event.keyCode == 27) {
      this.props.closeModal();
    }
  };

  async componentDidMount() {
    const { setPage, initialPage } = this.props;
    setPage(initialPage);
    await this.fetchPerson();

    document.addEventListener('keydown', this.handleEsc, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEsc, false);
  }

  async componentDidUpdate(prevProps) {
    const { person, setPage, initialPage } = this.props;

    if (initialPage !== prevProps.initialPage) {
      setPage(initialPage);
    }

    if (person.id !== prevProps.person.id) {
      setPage(initialPage);
      await this.fetchPerson();
    }
  }

  fetchPerson = async () => {
    const { getPersonInfo, person } = this.props;

    try {
      this.setState({ isFetchingPerson: true });

      await getPersonInfo(person.id, {
        include:
          'groups,neighborhood,city,state,country,user.default_crm_stage,relateds,phones',
        count:
          'deals,matcheds_new,matcheds_sent,matcheds_discarted,properties,earned_deals,search_profiles_active,documents,calendars,notes,bookings',
      });

      this.setState({ isFetchingPerson: false });
    } catch (err) {
      Alert.success('Esse cliente não existe mais');
      this.handleClose();
      throw err;
    }
  };

  handleChangePage = (page) => () => this.props.setPage(page);

  styleNavItem = (link, currentPage) => {
    let currentStyle = {
      flexGrow: 1,
      whiteSpace: 'nowrap',
      padding: '20px 10px',
      color: 'rgba(62, 88, 124, 0.54)',
      transition: 'background-color .2s',
      willChange: 'background-color',
      cursor: 'pointer',
    };

    if (link === currentPage) {
      currentStyle = {
        ...currentStyle,
        color: '#0063c0',
        backgroundColor: 'rgba(0, 99, 192, 0.1)',
      };
    }

    return currentStyle;
  };

  handleClose = () => {
    removeParam('_openModalPerson');

    this.props.handleClose();

    if (this.props.person && this.props.person.afterClose) {
      this.props.person.afterClose();
    }

    this.props.clearModal();
  };

  reopenModal = (params) => {
    const { openModalProperty, openModalPerson, person } = this.props;

    openModalPerson(
      {
        ...person,
        afterClose: () => {
          if (openModalProperty) openModalProperty();
        },
      },
      params
    );
  };

  get totalProperties() {
    const {
      deals_count,
      matcheds_new_count,
      matcheds_sent_count,
      matcheds_discarted_count,
      properties_count,
      earned_deals_count,
    } = this.props.modalPerson.person;

    try {
      if (
        matcheds_discarted_count === null ||
        matcheds_discarted_count === undefined
      )
        return 0;

      return (
        parseInt(deals_count, 10) +
        parseInt(matcheds_new_count, 10) +
        parseInt(matcheds_sent_count, 10) +
        parseInt(matcheds_discarted_count, 10) +
        parseInt(properties_count, 10) +
        parseInt(earned_deals_count, 10)
      );
    } catch {
      return 0;
    }
  }

  handleClickTitle = () => {
    const {
      modalPerson: { person },
      openModalPersonAdd,
      openModalPerson,
    } = this.props;

    // abre a modal de edição do cliente
    openModalPersonAdd(person, () => {
      // quando finaliza a edição retorna para a modal do cliente
      openModalPerson(person);
    });
  };

  get phoneWhatsapp() {
    const {
      modalPerson: { person },
    } = this.props;

    try {
      return person.whatsapp_number;
    } catch (e) {
      return null;
    }
  }

  get whatsappUrl() {
    const whatsapp = this.phoneWhatsapp;

    return whatsappSendUrl(whatsapp, '', '');
  }

  handleRegisterInPleno = () => {
    const { person } = this.props;

    this.props.openConfirmation({
      title: 'Enviar cliente ao Pleno Imob?',
      text: 'Deseja enviar esse cliente para o Pleno Imob?',
      request: () => {
        plenoService.sendPerson(person.id).then(() => {
          Alert.success('Cliente enviado no Pleno Imob.');
        });
      },
      submitButtonColor: 'success',
      submitButtonText: 'Enviar ao Pleno Imob',
    });
  };

  render() {
    const {
      modalConfig,
      isOpen,
      modalType,
      modalPerson,
      modalPerson: {
        currentPage,
        person,
        meta: { isFetching },
      },
    } = this.props;

    const whatsapp = this.phoneWhatsapp;

    const buttonProps = (link) => ({
      type: 'button',
      style: this.styleNavItem(link, currentPage),
      onClick: this.handleChangePage(link),
    });

    if (person.is_on_network && person.network_type === 'Órulo') {
      return (
        <ModalPersonOrulo
          modalConfig={modalConfig}
          isOpen={isOpen}
          modalType={modalType}
          handleClose={this.handleClose}
          propsModal={this.props}
          reopenModal={this.reopenModal}
        />
      );
    }

    return (
      <Modal
        {...modalConfig}
        className={{
          ...modalConfig.className,
          base: 'Modal Modal--person',
        }}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={this.handleClose}
      >
        <ModalTemplate
          isLoading={modalPerson.meta.isFetching}
          handleClose={this.handleClose}
          renderHeader={() => (
            <header className="Modal__header Modal__header--custom h-flex h-flex--center-center">
              <Can
                run={PERSON_PERMISSIONS.EDIT}
                permissions={person.permissions}
              >
                {(condition) => {
                  let props = {};

                  if (condition) {
                    props = {
                      ...props,
                      onClick: this.handleClickTitle,
                    };
                  }

                  return (
                    <div
                      {...props}
                      className="Modal__title h-flex h-flex--center-center h-pointer"
                      style={{
                        gap: '8px',
                        flexGrow: 1,
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>
                        <h4 style={{ display: 'inline-block' }}>
                          <TextOverflow
                            width={240}
                            style={{
                              verticalAlign: 'middle',
                              marginTop: '-5px',
                            }}
                          >
                            {person.name}
                          </TextOverflow>
                          {person.status === '0' ? (
                            <div
                              className="h-color--danger"
                              style={{
                                marginBottom: '-14px',
                                fontSize: '12px',
                              }}
                            >
                              Inativo
                            </div>
                          ) : null}
                        </h4>{' '}
                        <CopyDataPerson person={person} />
                      </div>
                      <ModalActions
                        person={person}
                        onToggleFavorite={({ data }) => {
                          this.props.toggleFavorite(data);
                        }}
                      />
                    </div>
                  );
                }}
              </Can>

              <div className="h-flex__cell h-flex__cell--grow" />
              <Can
                run={PERSON_PERMISSIONS.CHANGE_REALTOR}
                permissions={person.permissions}
              >
                <div className="h-flex">
                  {!isFetching ? (
                    <SelectOwner
                      personId={person.id}
                      userId={person.user_id}
                      onChange={this.fetchPerson}
                    />
                  ) : (
                    <TextLoading width={218} height={33} />
                  )}
                </div>
              </Can>
            </header>
          )}
        >
          <div style={style.menu}>
            <button {...buttonProps('Dashboard')}>Início</button>
            <button {...buttonProps('Properties')}>
              Imóveis ({this.totalProperties})
            </button>
            <Can run="DYNAMIC_NOTES" permissions={person?.permissions}>
              <button {...buttonProps('Notes')}>
                Anotações ({person?.notes_count || 0})
              </button>
            </Can>
            <button {...buttonProps('Infos')}>Dados</button>
            <button {...buttonProps('SearchProfile')}>
              Perfis ({person?.search_profiles_active_count || 0})
            </button>
            <button {...buttonProps('Documents')}>
              Documentos ({person?.documents_count || 0})
            </button>
            <button {...buttonProps('Events')}>
              Agenda ({person?.calendars_count || 0})
            </button>
          </div>
          {!isFetching && !this.state.isFetchingPerson ? (
            createElement(Pages[currentPage], {
              ...this.props,
              fetchPerson: this.fetchPerson,
              reopenModal: this.reopenModal,
              onSubmitSuccessEdit: this.props.person.onSubmitSuccessEdit,
            })
          ) : (
            <div style={{ height: 300 }} />
          )}
        </ModalTemplate>
      </Modal>
    );
  }
}

ModalPerson.propTypes = propTypes;
ModalPerson.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  modalPerson: state.modalPerson,
  relations: relationsSelector(state),
});
const mapDispatchToProps = {
  closeModal,
  setPage,
  clearModal,
  getPersonInfo,
  getPerson,
  fetchPersonAudits,
  fetchPersonCountRelations,
  openModalPersonAdd,
  openModalPerson,
  openConfirmation,
  toggleFavorite,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalPerson);
