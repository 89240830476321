import React from 'react';
import PropTypes from 'prop-types';
// Components
// Styles
import * as S from './styles';
import Button from 'components/Button';
import { MdAdd } from 'react-icons/md';
import iconNoDomain from './icon-no-domain.svg';

// Assets

const defaultProps = {
  handleClickRegisterDomain: null,
  handleClickOpenAddDomain: null,
};

const propTypes = {
  handleClickRegisterDomain: PropTypes.func,
  handleClickOpenAddDomain: PropTypes.func,
};

const DomainsPreRegister = ({
  handleClickRegisterDomain,
  handleClickOpenAddDomain,
}) => (
  <S.Wrapper>
    <S.Col>
      <S.Item onClick={handleClickRegisterDomain}>
        <img src={iconNoDomain} alt="" />
        <div>
          <p>Ainda não tem um domínio?</p>
          <p>Nós ajudamos você a registrar o seu!</p>
        </div>
        <Button color="white">
          <MdAdd className="h-color--success" />
          Registrar domínio
        </Button>
      </S.Item>
      {/*<CardAction*/}
      {/*  image={registerDomain}*/}
      {/*  title={'Quero Registrar um Dominío'}*/}
      {/*  text={*/}
      {/*    'Ajudamos você a verificar a disponibilidade e registrar seu domínio .com.br'*/}
      {/*  }*/}
      {/*  onClick={() => handleClickRegisterDomain()}*/}
      {/*>*/}
      {/*  <Button color="success">Registrar domínio</Button>*/}
      {/*</CardAction>*/}
    </S.Col>
    <S.Divider />
    <S.Col>
      <S.Item onClick={handleClickOpenAddDomain}>
        <img src={iconNoDomain} alt="" />
        <div>
          <p>Já tem um domínio e quer usá-lo com a gente?</p>
          <p>Clique em adicionar domínio e siga o passo-a-passo</p>
        </div>
        <Button color="white">
          <MdAdd className="h-color--success" />
          Adicionar domínio
        </Button>
      </S.Item>
      {/*<CardAction*/}
      {/*  image={addDomain}*/}
      {/*  title={'Já possuo um domínio'}*/}
      {/*  text={'Já possui um domínio e quer utilizá-lo conosco?'}*/}
      {/*  onClick={() => handleClickOpenAddDomain()}*/}
      {/*>*/}
      {/*  <Button color="success">Adicionar domínio</Button>*/}
      {/*</CardAction>*/}
    </S.Col>
  </S.Wrapper>
);

DomainsPreRegister.defaultProps = defaultProps;
DomainsPreRegister.propTypes = propTypes;

export default DomainsPreRegister;
