import React from 'react';
import {
  MdAlignHorizontalCenter,
  MdFormatAlignLeft,
  MdOutlineAccountBalanceWallet,
  MdOutlineCreditCard,
  MdSwapVert,
} from 'react-icons/md';
import NavAside from 'components/NavAside';
import { useLocation, useRouteMatch } from 'react-router';
import { MdDashboard, MdHistory } from 'react-icons/all';

export function Aside() {
  const { path } = useRouteMatch();
  const location = useLocation();

  // Verifica se a URL atual contém '/categories'
  const isCategories = location.pathname.includes('/categories/');
  const isReports = location.pathname.includes('/reports/');

  return (
    <NavAside>
      <NavAside.link icon={MdDashboard} to={`${path}/dashboard`}>
        Visão geral
      </NavAside.link>
      <NavAside.link
        icon={MdSwapVert}
        to={`${path}/postings`}
        className="NavAside__item js-aside-postings"
      >
        Lançamentos
      </NavAside.link>
      <NavAside.link
        icon={MdOutlineAccountBalanceWallet}
        to={`${path}/accounts`}
        className="NavAside__item js-aside-accounts"
      >
        Meus bancos
      </NavAside.link>
      <NavAside.link
        icon={MdOutlineCreditCard}
        to={`${path}/credit-card`}
        className="NavAside__item js-aside-cards"
      >
        Meus cartões
      </NavAside.link>
      <NavAside.link icon={MdHistory} to={`${path}/history`}>
        Histórico
      </NavAside.link>
      <NavAside.group
        isOpen={isReports}
        icon={MdFormatAlignLeft}
        text="Relatórios"
      >
        <NavAside.item to={`${path}/reports/categories`}>
          Categorias
        </NavAside.item>
        <NavAside.item to={`${path}/reports/flow`}>Fluxo</NavAside.item>
      </NavAside.group>
      <NavAside.group
        isOpen={isCategories}
        icon={MdAlignHorizontalCenter}
        text="Categorias"
      >
        <NavAside.item to={`${path}/categories/income`}>Receita</NavAside.item>
        <NavAside.item to={`${path}/categories/expense`}>Despesa</NavAside.item>
      </NavAside.group>
    </NavAside>
  );
}
