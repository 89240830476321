import React from 'react';
import PropTypes from 'prop-types';
import { MdArrowDropDown as Arrow } from 'react-icons/md';
// Components
import Table from 'components/Table';
import Button from 'components/Button';
import Dropdown, { Item, List } from 'components/Dropdown';

const propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      account: PropTypes.string,
    })
  ),
  isFetching: PropTypes.bool,

  handleUpdatePassword: PropTypes.func.isRequired,
  handleCreateAlias: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

const defaultProps = {
  data: [],
  isFetching: false,

  handleUpdatePassword: null,
  handleCreateAlias: null,
  handleDelete: null,
};

const TableAccounts = ({
  data,
  isFetching,

  handleUpdatePassword,
  handleCreateAlias,
  handleDelete,
}) => (
  <Table
    isFetching={isFetching}
    data={data}
    renderTitle={() => (
      <tr>
        <th>Nome</th>
        <th colSpan={2}>E-mail</th>
      </tr>
    )}
    renderItem={({ id, name, username, webmail_url, domain }) => (
      <tr key={id}>
        <td>{name}</td>
        <td>{username}</td>
        <td width={0} className="Table__cell--small">
          {webmail_url ? (
            <Button
              tagName="a"
              href={webmail_url}
              target="_blank"
              rel="noopener noreferrer"
              size="medium"
            >
              Acessar
            </Button>
          ) : null}
          <Dropdown isDropHover>
            <Button color="white" colorText="primary" size="medium">
              Opções <Arrow />
            </Button>
            <List>
              <Item>
                <button onClick={() => handleUpdatePassword(username)}>
                  Alterar senha
                </button>
              </Item>
              <Item>
                <button onClick={() => handleCreateAlias(username)}>
                  Criar alias
                </button>
              </Item>
              <Item>
                <button onClick={() => handleDelete(username)}>Excluir</button>
              </Item>
            </List>
          </Dropdown>
        </td>
      </tr>
    )}
  />
);

TableAccounts.propTypes = propTypes;
TableAccounts.defaultProps = defaultProps;

export default TableAccounts;
