import { Financial } from 'features/Financial';
import * as S from './styles';
import Button from 'components/Button';
import { fetchStatusGuard } from '../../../../modules/statusGuard';
import { useDispatch } from 'react-redux';
import imageTrialEnded from './imageTrialEnded.svg';
import { ITEM_TYPES } from 'services/financialv2/contracts';
import { closeModal, openModalContractExtra } from 'modules/modal';
import { usePreview1 } from 'hooks/api/contracts';

export function TrialPeriodEnded() {
  const dispatch = useDispatch();
  const { fetchPreview1 } = usePreview1();

  const handleContract = async () => {
    const { data: preview } = await fetchPreview1({
      item: ITEM_TYPES.EMAIL,
      is_adding: false,
    });

    dispatch(
      openModalContractExtra({
        item: ITEM_TYPES.FINANCIAL,
        preview,
        onSuccess: () => {
          dispatch(fetchStatusGuard());
          dispatch(closeModal());
        },
      })
    );
  };

  return (
    <Financial.Content>
      <S.Box>
        <S.Wrapper>
          <S.BoxLeft>
            <S.Title>Seu período de teste chegou ao fim.</S.Title>
            <S.Text>
              Seu período de testes do Módulo Financeiro chegou ao fim. Para
              continuar utilizando este recurso é necessário contratá-lo.
              <br />
              <br />O módulo tem um custo de R$29,90 e a contratação pode ser
              feita de forma prática, utilizando os créditos da sua conta
              bancária ou realizando o pagamento à parte. Garanta já o acesso ao
              Módulo Financeiro e mantenha suas finanças organizadas com
              facilidade!
            </S.Text>
            <Button color="success" onClick={handleContract}>
              Contratar
            </Button>
          </S.BoxLeft>
          <S.BoxRight>
            <img src={imageTrialEnded} alt="" />
          </S.BoxRight>
        </S.Wrapper>
      </S.Box>
    </Financial.Content>
  );
}
