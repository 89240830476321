import api from '../index';

export const ITEM_TYPES = {
  USERS: 'Usuários',
  EMAIL: 'Caixas de email',
  ORULO: 'Integração Órulo',
  DWV: 'Integração DWV',
  FINANCIAL: 'Financeiro',
};

/**
 * Pega os dados do contrato
 */
export const getCurrentItems = () => api.getOne('sales/contracts/items', '');

/**
 * Pega os dados do contrato
 */
export const getCurrentContract = (params) => {
  return api.getOne('sales/contracts/current', '', {
    include: 'items',
    ...params,
  });
};

/**
 * Pega a lista de contratos
 * @param sort
 * @param limit
 * @param offset
 * @return {Promise<AxiosResponse<never>>}
 */
export const getList = ({
  sort = '-created_at',
  limit = 50,
  offset = 1,
} = {}) => {
  return api.getList('sales/contracts', {
    filter: {
      by_main: true,
    },
    include: 'user,children',
    sort,
    limit,
    offset,
  });
};

/**
 * Pega o detalhe de um contrato
 */
export const getContract = ({ id }) =>
  api.getList(`sales/contracts/${id}`, {
    include:
      'credits,credits_extra,items,values,changes_description,deployment',
  });

/**
 * Preview do crédito apos ser cancelado
 */
export const creditsPreview = ({ id }) =>
  api.delete(`sales/contracts/${id}/credits-preview`);

/**
 * Preview da extenção de contrato
 * @param end_at
 * @return {Promise<AxiosResponse<*>>}
 */
export const extendPreview = ({ end_at }) =>
  api.create('sales/contracts/extend-preview', { end_at });

/**
 * Preview da extenção de contrato
 * @return {Promise<AxiosResponse<*>>}
 */
export const extend = ({ end_at, receiving_method, card }) =>
  api.create('sales/contracts/extend', {
    end_at,
    receiving_method,
    card_id: card,
  });

/**
 * Cancelar créditos
 */
export const cancelCredits = ({ id }) =>
  api.delete(`sales/contracts/${id}/credits`);

/**
 *
 * @param ITEM_TYPES{String} item
 * @return {Promise<AxiosResponse<*>>}
 */
export const changePreview1 = ({
  item = ITEM_TYPES.USERS,
  is_adding = true,
}) => {
  return api.create('sales/contracts/change-preview1', {
    item,
    is_adding,
  });
};

/**
 *
 * @param ITEM_TYPES{String} item
 * @return {Promise<AxiosResponse<*>>}
 */
export const changePreview2 = ({
  item = ITEM_TYPES.USERS,
  quantity,
  is_adding,
}) => {
  return api.create('sales/contracts/change-preview2', {
    item,
    quantity,
    is_adding,
  });
};

/**
 *
 * @param ITEM_TYPES{String} item
 * @return {Promise<AxiosResponse<*>>}
 */
export const changePreview2_2 = ({
  item = ITEM_TYPES.USERS,
  quantity,
  is_adding,
}) => {
  return api.create('sales/contracts/change-preview2-2', {
    item,
    quantity,
    is_adding,
  });
};

/**
 *
 * @param ITEM_TYPES{String} item
 */
export const contractChange = async ({
  item = ITEM_TYPES.USERS,
  receiving_method,
  installments,
  quantity,
  is_adding = true,
}) => {
  try {
    const response = await api.create('sales/contracts/change', {
      item,
      receiving_method,
      installments,
      quantity,
      is_adding,
    });

    return { receiving_method, item, response };
  } catch (err) {
    throw err;
  }
};

export const changeDate = ({ item, quantity }) =>
  api.create('sales/contracts/change-date', {
    item,
    quantity,
  });
