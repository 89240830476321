import api from '../index';
import { responseMessage } from 'lib/service-helpers';

const DEFAULT_CRM_NAME = 'rdstation';

/**
 * Pega os dados da modal de configuração
 */
export const get = (crmName = DEFAULT_CRM_NAME) =>
  api.get(`integrations/crm/${crmName}`, '').then((res) => {
    res.data = {
      groups_id: res?.data?.groups?.map(({ id }) => id),
      recipient_user_id: res?.data?.recipient_user_id,
    };
    return res;
  });

/**
 * Pega os dados da modal de configuração
 */
export const getLeadfy = () => {
  return api.get(`integrations/crm/leadfy`, '').catch((res) => {
    return {
      data: null,
    };
  });
};

/**
 * Atualiza os grupos
 */
export const update = ({
  groups_id,
  recipient_user_id,
  crmName = DEFAULT_CRM_NAME,
}) =>
  api
    .update(`integrations/crm/${crmName}`, { groups_id, recipient_user_id })
    .then(responseMessage('Atualizado as categorias'));

/**
 * Conecta com o CRM
 * @param values
 * @param crmName
 * @returns {Promise<AxiosResponse<any>>}
 */
export const connect = (values, crmName = DEFAULT_CRM_NAME) =>
  api.create('integrations/crm', { ...values, crm_name: crmName });

/**
 * Atualiza o codigo de autenticação do CRM
 * @param code
 * @param crmName
 * @returns {Promise<AxiosResponse<any>>}
 */
export const refreshCode = (code, crmName = DEFAULT_CRM_NAME) =>
  api
    .update(`integrations/crm/${crmName}`, { code })
    .then(responseMessage('Integração com RDStation finalizada'));

/**
 * Desconecta a integração com o CRM
 * @returns {Promise<AxiosResponse<any>>}
 */
export const disconnect = (crmName = DEFAULT_CRM_NAME) =>
  api
    .delete(`integrations/crm/${crmName}`)
    .then(responseMessage('Desconectado'));
