import Button from 'components/Button';
import Loading from 'components/Loading';
import { ModalFooter } from 'components/Modal';
import { useForm } from 'containers/ModalRoot/ModalPayment/contexts/form';
import { SectionHeader } from 'containers/ModalRoot/ModalPayment/styles';
import useReduxForm from 'hooks/useReduxForm';
import { useEffect, useState } from 'react';
import {
  FailedVerification,
  Instructions,
  PixWrapper,
  QrCode,
  Value,
} from './styles';
import Clipboard from 'react-clipboard.js';
import Alert from 'react-s-alert';
import * as creditsService from 'services/financial/credits';
import { openModalPaymentSuccess } from 'modules/modal';
import { useDispatch } from 'react-redux';
import { usePix } from '../../hooks/usePix';
import { useModalContractExtra } from 'containers/ModalRoot/ModalContractExtra/contexts/modalContractExtra';
import useFormValue from 'hooks/useFormValue';

const Pix = () => {
  const { item } = useModalContractExtra();
  const installments = useFormValue('installments');
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationFailed, setVerificationFailed] = useState(false);

  const { setPage } = useForm();

  const dispatch = useDispatch();

  const { initialize, getValues } = useReduxForm();

  useEffect(() => {
    const values = getValues();

    const init = function () {
      initialize({
        ...values,
        installments: null,
      });
    };

    init();
  }, []);

  const { price, code, imageUrl, contract, creditId } = usePix({
    item,
    installments,
    quantity: 1,
  });

  useEffect(() => {
    contract();
  }, []);

  async function handleVerifyPayment() {
    setIsVerifying(true);

    const verification = await creditsService.getOne(creditId);

    if (verification.data.received_at) {
      dispatch(openModalPaymentSuccess());
    } else {
      setVerificationFailed(true);
    }

    setIsVerifying(false);
  }

  if (!code) return <Loading isVisible isBlock />;

  return (
    <>
      <SectionHeader>Dados do Pix</SectionHeader>

      <PixWrapper>
        <QrCode>
          <img src={imageUrl} alt="QR Code Pix" />
          <Value>1 x R$ {price} (Nox Trading LTDA)</Value>
        </QrCode>

        {verificationFailed ? (
          <FailedVerification>
            <h2>Pagamento não encontrado</h2>
            <p>
              Após confirmação do seu banco, aguarde 30 segundos e clique em
              ”Verificar pagamento”.
            </p>
          </FailedVerification>
        ) : (
          <Instructions>
            <h2>Pagamento via QR Code</h2>
            <p>
              Abra o aplicativo do seu banco, vá a seção Pix e pagar com QR
              CODE.
            </p>

            <Clipboard
              data-clipboard-text={code}
              onClick={() => {
                Alert.success('Código copiado');
              }}
            >
              <Button tagName="span" color="tertiary" size="small">
                Copiar QR Code
              </Button>
            </Clipboard>
          </Instructions>
        )}
      </PixWrapper>

      <ModalFooter>
        <Button
          color="white"
          colorText="primary"
          onClick={() => setPage('plan')}
        >
          Voltar
        </Button>
        <div className="h-flex__cell--grow" />
        <Button
          type="button"
          color="success"
          disabled={isVerifying}
          onClick={handleVerifyPayment}
        >
          {isVerifying ? 'Verificando...' : 'Verificar pagamento'}
        </Button>
      </ModalFooter>
    </>
  );
};

export default Pix;
