import React from 'react';
// Components
import Wizard from 'components/Wizard';
import { withWizard } from 'HOC/withWizard';
// HOC

const STEPS = [
  {
    target: '.js-aside-accounts',
    title: 'Meus bancos',
    content: 'Permite cadastrar e gerenciar suas contas bancárias',
    disableOverlayClose: true,
    disableBeacon: true,
    placement: 'right-start',
  },
  {
    target: '.js-aside-cards',
    title: 'Meus Cartões',
    content:
      'Permite cadastrar e gerenciar seus cartões de crédito e débito, datas e limites de crédito',
    disableOverlayClose: true,
    spotlightClicks: false,
    disableBeacon: true,
    placement: 'right-start',
  },
  {
    target: '.js-aside-postings',
    title: 'Lançamentos',
    content: 'Exibe uma lista detalhada de todas as contas a pagar e a receber',
    disableOverlayClose: true,
    spotlightClicks: false,
    disableBeacon: true,
    placement: 'right-start',
  },
  {
    target: '.js-button-posting',
    title: 'Novo lançamento',
    content:
      'Clique aqui para lançar sua primeira conta a pagar ou a receber e começar a controlar suas finanças agora mesmo',
    disableOverlayClose: true,
    spotlightClicks: false,
    placement: 'left-end',
    // disableBeacon: true,
  },
];

function WizardDashboard({ onClose }) {
  return <Wizard steps={STEPS} onClose={onClose} />;
}

export default withWizard('wizard/dashboard-financial-1')(WizardDashboard);
