import { useRequest } from 'hooks/useRequest';
import * as accountsService from '../services/accounts';

export const useIndexCreditCardBills = () => {
  const { data, meta, isFetching, fetchData } = useRequest({
    request: ({ id, year }) =>
      accountsService.indexCreditCardBills({ id, year }),
    initialFetch: false,
  });

  return {
    bills: data,
    billsMeta: {
      account: meta?.account,
    },
    billsIsFetching: isFetching,
    fetchBills: fetchData,
  };
};

export const useShowCreditCardBill = () => {
  const { data, meta, isFetching, fetchData } = useRequest({
    request: ({ id, creditCardBill }) =>
      accountsService.showCreditCardBills({ id, creditCardBill }),
    initialFetch: false,
  });

  return {
    bill: data,
    billMeta: {
      account: meta?.account,
    },
    billIsFetching: isFetching,
    fetchBill: ({ id, creditCardBill }) => fetchData({ id, creditCardBill }),
  };
};

export const usePayBill = () => {
  const handlePayBill = ({ id, creditCardBill, ...values }) => {
    return accountsService.payCreditCardBill({
      id,
      creditCardBill,
      ...values,
    });
  };

  return { handlePayBill };
};
