import React, { useCallback } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

// Components
import { Input, InputPhone } from 'components/Form';
import Button from 'components/Button';
import { ModalClose } from 'components/Modal';
import useFormValue from 'hooks/useFormValue';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

function FormPhone({
  change,
  handleSubmit,
  handleClose,
  data,
  currentIndex,
  handleRemove,
  actions,
}) {
  const iso2 = useFormValue('iso2');
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      return handleSubmit(e);
    },
    [handleSubmit]
  );

  const hasRemove = handleRemove && !!data;

  return (
    <form onSubmit={onSubmit}>
      <ModalClose color="primary" handleClose={handleClose} />
      <Field
        defaultCountry={iso2 || 'br'}
        label="Telefone"
        name="number"
        component={InputPhone}
        onSelectFlag={(status, countryData) => {
          change(`ddi`, countryData.dialCode);
          change(`iso2`, countryData.iso2);
        }}
      />
       <Field
        type="text"
        name="description"
        label="Descrição"
        component={Input}
       />
      <Button type="submit" color="success" block>
        Concluir
      </Button>
      {hasRemove && (
        <div className="h-margin-top--5">
          <Button
            color="white"
            colorText="danger"
            block
            onClick={() =>
              handleRemove(data, { actions, currentIndex, handleClose })
            }
          >
            Excluir
          </Button>
        </div>
      )}
    </form>
  );
}

FormPhone.defaultProps = defaultProps;
FormPhone.propTypes = propTypes;

export default reduxForm({
  form: 'FormPhone',
  initialValues: {
    is_whatsapp: true,
    is_primary: true,
    iso2: 'br',
    ddi: 55,
  },
  enableReinitialize: true,
})(FormPhone);
