import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
// Services
import * as domainsService from 'services/settings/domains';
// Components
import PreRegisterDomainForm from './components/PreRegisterDomainForm';
import RegisterDomainForm from './components/RegisterDomainForm';
import RegisterDomainAlert from './components/RegisterDomainAlert';
import RegisterDomainSuccess from './components/RegisterDomainSuccess';

const ModalRegisterDomain = ({
  modalType,
  modalConfig,
  isOpen,
  handleClose,
  url,
  onSubmitSuccess,
}) => {
  const [component, setComponent] = useState('RegisterDomainAlert');
  const dispatch = useDispatch();

  const handleSubmitPreRegister = useCallback(
    async (values) => {
      await domainsService.checkDomain(values.url);
      setComponent('RegisterDomainForm');

      // Adiciona a url no formulário de registro de domínio
      dispatch(change('RegisterDomainForm', 'url', values.url));
      return values;
    },
    [dispatch]
  );

  const handleSubmitRegister = useCallback(
    async (values) => {
      const res = await domainsService.createPendingRegister(values);
      setComponent('RegisterDomainSuccess');
      if (onSubmitSuccess) onSubmitSuccess(res);
      return res;
    },
    [onSubmitSuccess]
  );

  const handleSubmitAlert = useCallback(() => {
    setComponent('PreRegisterDomainForm');
  }, []);

  const components = {
    PreRegisterDomainForm: {
      component: PreRegisterDomainForm,
      props: {
        onSubmit: handleSubmitPreRegister,
        onSubmitSuccess: async (values) => {
          // Store values for later use
        },
      },
    },
    RegisterDomainForm: {
      component: RegisterDomainForm,
      props: {
        width: 540,
        onSubmit: handleSubmitRegister,
        onSubmitSuccess: (res) => {
          if (onSubmitSuccess) onSubmitSuccess(res);
        },
      },
    },
    RegisterDomainAlert: {
      component: RegisterDomainAlert,
      props: {
        onSubmit: handleSubmitAlert,
      },
    },
    RegisterDomainSuccess: {
      component: RegisterDomainSuccess,
      props: {},
    },
  };

  const renderComponent = useCallback(() => {
    const Component = components[component].component;
    return (
      <Component
        {...components[component].props}
        initialValues={{ url }}
        handleClose={handleClose}
      />
    );
  }, [component, components, handleClose, url]);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
      style={{
        content: {
          maxWidth: component === 'RegisterDomainForm' ? 540 : 458,
        },
      }}
    >
      {renderComponent()}
    </Modal>
  );
};

ModalRegisterDomain.propTypes = {
  modalType: PropTypes.string.isRequired,
  modalConfig: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  url: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
};

export default ModalRegisterDomain;
