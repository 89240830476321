import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
`;

export const BtnLink = styled.button`
  cursor: pointer;
  color: ${(p) => p.theme.colors.secondary};
  text-decoration: underline;
`;
