import { getAll } from 'services/people';

/**
 * Lista
 * @param values
 */
export const indexFavorites = (values) => {
  return getAll({
    filter: {
      is_favorite: true,
      ...values,
    },
  });
};
