import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Field, formValueSelector, reduxForm } from 'redux-form';
// Components
import Button from 'components/Button';
import { FormError, Input, Select } from 'components/Form';
import Icon from 'components/Icon';
import { ModalFooter } from 'components/Modal';
import FieldGroups from 'containers/FieldGroups';
import useFormValue from 'hooks/useFormValue';
import { parseValueBool } from 'lib/formHelpers';
import { Row } from 'react-flexbox-grid';
import { STATUS_CRECI } from '../../../../../constants/constants';
import validate from './validate';
import FieldPhone from 'containers/FieldPhone';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

function UserForm({
  error,
  userId,
  handleClose,
  realtor,
  submitting,
  handleSubmit,
  initialValues: user,
  change,
}) {
  const isCreciVerified = user.creci_status === STATUS_CRECI.VERIFIED;

  const hasProperty = user.properties_exists;

  const shouldSendInstructions = useFormValue('should_send_instructions');

  const renderTooltipRealtor = () => {
    if (hasProperty || isRealtorFieldDisabled) {
      return (
        <span
          data-tip="Este usuário está como responsável por imóveis. Para poder alterar, clique em opções e transferir imóveis para outro corretor"
          data-for={'property'}
          className="h-color--secondary"
          style={{ display: 'inline-block' }}
        >
          <Icon name="info" />
        </span>
      );
    }

    return null;
  };

  useEffect(() => {
    const prevHasProperty = user.properties_exists;
    const prevIsCreciVerified = user.creci_status === STATUS_CRECI.VERIFIED;

    if (
      prevIsCreciVerified !== isCreciVerified ||
      prevHasProperty !== hasProperty
    ) {
      ReactTooltip.rebuild();
    }
  }, [user.properties_exists, user.creci_status, isCreciVerified, hasProperty]);

  const isRealtorFieldDisabled = useMemo(() => {
    // Se tiver imóveis e esse usuário tiver creci
    // Tem que desabilitar o campo
    if (user.properties_count > 0 && user.creci) {
      return true;
    }

    return false;
  }, [user]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [user]);

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Row>
        <Field
          xs={6}
          required
          label="Nome"
          name="name"
          component={Input}
          disabled={isCreciVerified}
        />
        <FieldGroups xs={6} required />
      </Row>
      <Row>
        <Field
          key="email"
          xs={6}
          label={
            <div>
              E-mail <span className="h-color--danger">*</span> (Para acessar o
              painel)
            </div>
          }
          name="email"
          component={Input}
        />
        <FieldPhone
          key="cellphone"
          name="cellphone"
          xs={6}
          required
          label="Celular / Whatsapp"
        />
      </Row>
      {!userId && (
        <Row>
          <Field
            type="password"
            xs={6}
            required
            label="Senha"
            name="password"
            component={Input}
          />
          <Field
            type="password"
            xs={6}
            required
            label="Confirmar senha"
            name="password_confirm"
            component={Input}
          />
        </Row>
      )}
      <Row>
        <Field
          canRemoveSelf={false}
          buttonTemplate
          xs={6}
          label={
            <div>
              É Corretor de imóveis? <span className="h-color--danger">*</span>{' '}
              {renderTooltipRealtor()}
            </div>
          }
          name="realtor"
          component={Select}
          labelKey="label"
          valueKey="value"
          placeholder=""
          options={[
            { label: 'Sim', value: true },
            { label: 'Não', value: false },
          ]}
          parse={parseValueBool}
          disabled={isRealtorFieldDisabled || isCreciVerified || hasProperty}
          style={{ width: 150 }}
        />
        {realtor && (
          <Field
            disabled={isCreciVerified || isRealtorFieldDisabled}
            xs={6}
            required
            label={'CRECI'}
            // label={<div>CRECI <span className="h-color--danger">*</span>   {this.renderTooltipCreci}</div>}
            name="creci"
            component={Input}
          />
        )}
      </Row>
      <FormError>{error}</FormError>
      <ModalFooter>
        <Button color="white" colorText="primary" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success" disabled={submitting}>
          Salvar
        </Button>
      </ModalFooter>
      <ReactTooltip id="property" effect="solid">
        Este usuário está como responsável por imóveis.
        <br />
        Para poder alterar, clique em opções
        <br />e transferir imóveis para outro corretor
      </ReactTooltip>
    </form>
  );
}

UserForm.defaultProps = defaultProps;
UserForm.propTypes = propTypes;

const selector = formValueSelector('UserForm');

const mapStateToProps = (state, ownProps) => ({
  name: selector(state, 'name'),
  realtor: selector(state, 'realtor'),
  isCreciVerified: selector(state, 'is_creci_verified'),
  currentUser: state.login.currentUser,
  initialValues: ownProps.initialValues,
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'UserForm',
    validate,
    enableReinitialize: true,
  })(UserForm)
);
