import { useEffect, useState } from 'react';

export const useDocument = (key) => {
  const [html, setHTML] = useState({});

  useEffect(() => {
    setHTML(localStorage.getItem(key));
  }, [key]);

  return html;
};
