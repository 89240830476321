import { useRequest } from 'hooks/useRequest';
import * as crmService from 'services/settings/crm-integration';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { openModalLeadfy } from 'modules/modal';
import { openConfirmation } from 'containers/ModalConfirmation/module';

export const useGetCrm = (crmName = 'leadfy') => {
  const dispatch = useDispatch();

  const { data, isFetching, fetchData } = useRequest({
    request: () => crmService.getLeadfy(crmName),
    initialState: {},
  });

  const connect = () => {
    dispatch(
      openModalLeadfy({
        onSubmitSuccess: () => {
          // Atualiza a integração com leadfy
          fetchData();
        },
      })
    );
  };

  const disconnect = () => {
    dispatch(
      openConfirmation({
        title: 'Desconectar leadfy',
        text: 'Voce deseja se descontar do leadfy',
        request: () => crmService.disconnect(crmName),
        onSuccess: () => {
          fetchData();
        },
      })
    );
  };

  const hasIntegration = useMemo(() => {
    return !!data?.id;
  }, [data]);

  return {
    crmData: data,
    connect,
    disconnect,
    hasIntegration,
    crmIsFetching: isFetching,
    fetchCrm: fetchData,
  };
};
