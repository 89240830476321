import { Header } from 'features/Financial/components/Header';
import { Financial } from 'features/Financial';
import Button from 'components/Button';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useGetAccounts } from '../../hooks/useAccounts';
import { TableAccounts } from './components/TableAccounts';
import { openModalStoreAccount } from 'features/Financial/modals/ModalStoreAccount';

export function ListAccounts() {
  const dispatch = useDispatch();
  const { accounts, fetchAccounts, isFetchingAccounts, metaAccounts } =
    useGetAccounts({ type: '1' });

  const fetchData = ({ offset = 1 }) => {
    fetchAccounts({
      sort: '-updated_at',
      offset,
    });
  };

  useEffect(() => {
    fetchData({});
  }, []);

  const onPageChange = ({ selected }) => {
    fetchData({
      offset: selected + 1,
    });
  };

  const handleClickOpenModal = () => {
    dispatch(
      openModalStoreAccount({
        type: 1,
        onSuccess: () => {
          fetchData({ offset: 1 });
        },
      })
    );
  };

  return (
    <>
      <Header.Header>
        <Header.Title>Contas</Header.Title>
        <Header.Actions>
          <Button size="small" color="secondary" onClick={handleClickOpenModal}>
            Criar conta bancária
          </Button>
        </Header.Actions>
      </Header.Header>
      <Financial.Content isFetching={isFetchingAccounts}>
        <TableAccounts
          data={accounts}
          meta={metaAccounts}
          onPageChange={onPageChange}
        />
      </Financial.Content>
    </>
  );
}
