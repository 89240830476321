import api, { apiStorage } from './';
import { responseMessage } from 'lib/service-helpers';
// Constants
import { STATUS } from './deals';
import { API_URL, HTTP } from 'lib/HTTP';
import { TYPE_PERSONS } from 'constants/options';
import { plural } from 'lib/text';

export const PEOPLE_RELATIONS = {
  calendars: 'calendars',
  sent_properties: 'sent_properties',
  pending_nutritions: 'pending_nutritions',
};

const transformResponse = (res) => {
  const newResponse = res;
  let { owner, reception_source, type } = res.data;

  if (type)
    newResponse.data.type_name = TYPE_PERSONS.find(
      (typePerson) => typePerson.value === type
    ).label;

  if (owner) newResponse.data.owner_id = owner.id;
  if (reception_source)
    newResponse.data.reception_source_id = reception_source.id;

  return newResponse;
};

/**
 * Pega uma lista de clientes
 * @param params
 * @return {*}
 */
export const getSearch = (params) => {
  if (params?.filter?.status === 'ALL_CLIENTS') {
    params.filter = {
      ...params.filter,
      status: null,
    };
  }

  return api.getSearch2('people', {
    sort: 'by_name',
    ...params,
    include: params.include || 'user',
    filter: {
      ...params?.formValues?.filter,
      ...params.filter,
      interacted_greater_equals:
        params.filter && params.filter.interacted_greater_equals
          ? params.filter.interacted_greater_equals + ' 00:00'
          : null,
      interacted_lower_equals:
        params.filter && params.filter.interacted_lower_equals
          ? params.filter.interacted_lower_equals + ' 23:59'
          : null,
    },
  });
};

/**
 * Pega informacoes especificas de um cliente
 * @param id
 * @return {*}
 */
export const getOne = (id, params) =>
  api
    .getOne('people', typeof id === 'object' ? id.id : id, params)
    .then(transformResponse);

/**
 * Pega todas as clientes de todos os usuários
 * @param id
 * @return {*}
 */
export const getAll = (params) =>
  api.getList('people', {
    sort: 'name',
    include: 'user',
    with_cellphone_number: true,
    ...params,
  });

/**
 * Validação assincrona dos campos de pessoa
 * @param values
 * @return {people/validates}
 */
export const peopleValidate = (values) => api.create('people/has', values);

/**
 * Busca todas as clientes que estao fazendo aniversário na semana
 * @param params
 * @return {*}
 */
export const getPeopleWithBirthday = (params) => {
  let filter = {};

  if (params && params.filter) filter = params.filter;

  return getAll({
    ...params,
    filter: {
      ...filter,
      by_week_birthday: true,
    },
  });
};

/**
 * Remove um cliente
 * @param id
 * @return {boolean|AxiosPromise|*}
 */
export const remove = (id) =>
  api
    .delete('people', `${id}?force=true`)
    .then(responseMessage('Cliente removido'))
    .catch(responseMessage('Erro ao remover'));

/**
 * Reativa uma pessoa inativada
 * @param id
 * @returns {Promise<AxiosResponse<*>>}
 */
export const reactivateStatus = (id) =>
  api
    .update('people', transformPerson({ id, status: '1' }))
    .then(responseMessage('Pessoa reativada'));

/**
 * Inativa uma pessoa ativa
 * @param id
 * @returns {Promise<AxiosResponse<*>>}
 */
export const inactiveStatus = (id) =>
  api
    .update('people', transformPerson({ id, status: '0' }))
    .then(responseMessage('Pessoa inativada'));

/**
 * Reativa um cliente
 * @param id
 * @return {AxiosPromise|*}
 */
export const reactivate = (id) => api.reactivate('people', id);

const transformPerson = (person) => {
  if (person?.relateds) {
    person.relateds = person.relateds.map(({ id, description }) => ({
      id,
      description,
    }));
  }

  return person;
};

/**
 * Adiciona um cliente
 * @param person
 * @return {*|AxiosPromise}
 */
export const add = (person) => {
  return api
    .create('people', transformPerson(person))
    .then(responseMessage('Cliente cadastrado'));
};

/**
 * Atualiza os dados do cliente
 * @param person
 */
export const update = (person) =>
  api
    .update('people', transformPerson(person))
    .then(responseMessage('Cliente editado!'));

/**
 * Pega todos os tipos de clientes
 * @return Promise
 */
export const getGroups = (params) =>
  api.getList('groups', {
    ...params,
    sort: 'name',
  });

/**
 * Pega um grupo
 * @return Promise
 */
export const getGroup = (id) => api.getOne('groups', id);

/**
 * Pega todos os grupos que foram cadastrados pelo usuário
 * @param params
 * @returns {Promise}
 */
export const getGroupsCustom = (params) =>
  getGroups({ count: 'people', with_defaults: false });

/**
 * Pega um grupo especifico pelo id
 * @param id
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getGroupById = async (id) => {
  try {
    const groups = await getGroups({ filter: { id } });
    return { data: groups[0] };
  } catch {
    return { data: {} };
  }
};

/**
 * Adiciona um tipo para o cliente
 * no painel a gente está tratando tipo como categoria
 * @param typeName {String}
 * @return {*|AxiosPromise}
 */
export const addGroup = (typeName) => api.create('groups', { name: typeName });

/**
 * Remove um grupo de cliente
 * @param id
 */
export const removeGroup = (id) =>
  api.delete('groups', id).then(responseMessage('Grupo removido'));

/**
 * Atualiza os dados do grupo
 * @param group
 * @returns {Promise<T>}
 */
export const updateGroup = (group) =>
  api.update('groups', group).then(responseMessage('Grupo atualizado'));

/**
 * Transferir grupo
 */
export const groupTransfer = (from, to) =>
  api.create(`groups/${from}/transfers/${to}`);

/**
 * Pega um evento especifico de um cliente
 * @param eventId
 * @param peopleId
 * @param params
 */
export const getEvent = ({ event_id, person_id }) =>
  api.getOne(`people/${person_id}/calendars`, event_id);

/**
 * Pega todos os eventos de um cliente em especifico
 * @param id
 */
export const getEvents = (id, params) =>
  HTTP.get(`${API_URL}people/${id}/calendars`, { params });

/**
 * Cadastra um evento para um cliente
 * @param person_id
 * @param params
 */
export const createEvent = ({ people_id, ...params }) =>
  api.create(`calendars`, {
    ...params,
    people_id,
  });

/**
 * Edita o evento de um cliente
 * @param person_id
 * @param params
 */
export const updateEvent = ({ people, ...params }) => {
  return api.update(`people/${people.id}/calendars`, params);
};

export const removeEvent = ({ id, people }) =>
  api.delete(`people/${people.id}/calendars`, id);

// Reativar evento do cliente
export const reactivateEvent = ({ id, ...params }) =>
  api.reactivate(`calendars/${id}/reactivate`, '');

export const cancelEvent = (event) => removeEvent(event);
//updateEvent({
//  id: event.id,
//  people: event.people,
//  status: EVENT_CANCELED,
//}).then(() => removeEvent(event))

export const finishEvent = (event) =>
  api.update(`calendars/${event.id}/finish`, {});
//export const finishEvent = event => (
//  updateEvent({
//    id: event.id,
//    people: event.people,
//    status: EVENT_FINISHED,
//  })
//);

/**
 * Pega todas as notas do cliente
 * @param id - id do cliente
 */
export const getAllNotes = (people_id) =>
  api.getList(`people/${people_id}/notes`);

/**
 * Busca uma nota especifica do cliente
 * @param noteId
 */
export const getOneNote = ({ id, people_id }) =>
  HTTP.get(`${API_URL}people/${people_id}/notes/${id}`);

/**
 * Adicionar uma nota npara a pessoa
 * @param note
 */
export const addNote = ({ people_id, ...rest }) =>
  api.create(`people/${people_id}/notes`, { ...rest });

/**
 * Remove uma nota
 */
export const removeNote = ({ id, people_id }) =>
  api.delete(`people/${people_id}/notes/${id}`);

/**
 * Altera uma nota de um cliente especifica
 * @param note
 */
export const updateNote = ({ people_id, ...rest }) =>
  api.update(`people/${people_id}/notes`, { ...rest });

/**
 * Pega todos os documentos do cliente
 * @param people_id - id do cliente
 */
export const getAllDocuments = (people_id) =>
  api.getList(`people/${people_id}/documents`);

/**
 * Busca um documento especifico
 * @param id - id da nota
 * @param people_id - id do cliente
 */
export const getOneDocument = ({ id, people_id }) =>
  HTTP.get(`${API_URL}people/${people_id}/documents/${id}`);

/**
 * Adicionar um documento para a pessoa
 * @param note
 */
export const addDocument = ({ people_id, document }) => {
  return apiStorage.uploadFile(`people/${people_id}/documents`, {
    people_id,
    file: document,
  });
};

// Remove uma documento
export const removeDocument = ({ id, people_id }) =>
  apiStorage.delete(`people/${people_id}/documents/${id}`);

/**
 * Altera uma nota de um cliente especifica
 * @param note
 */
export const updateDocument = ({ people_id, ...rest }) =>
  api.update(`people/${people_id}/documents`, { ...rest });
export const reactivateDocument = ({ id, people_id }) =>
  api.reactivate(`people/${people_id}/documents`, id);
export const requestPermission = (people_id) =>
  api.create(`/people/${people_id}/owner-requests`);

/**
 * Origem de captação
 */
export const addReceptionSource = (params) =>
  api.create('reception-source', params);
export const updateReceptionSource = (params) =>
  api.update('reception-source', params);
export const getReceptionSources = (params) =>
  api.getList('reception-source', {
    ...params,
    sort: '-created_at',
  });

// Negócios
export const getDeals = (personId, params) =>
  api.getList(`people/${personId}/deals`, params);

// Pega todos os negócios abertos
export const getOpenedDeals = (personId) =>
  getDeals(personId, { filter: { status: STATUS.OPENED } });

// Pega todos os negócios fechados
export const getClosedDeals = (personId) =>
  getDeals(personId, { filter: { status: STATUS.CLOSED } });

/**
 * Matched
 */
export const getPeopleMatched = (personId, params) =>
  api.getList(`people/${personId}/matcheds`, params);

export const discardMatched = (personId, matchedId) =>
  api
    .delete(`people/${personId}/matcheds/${matchedId}`)
    .then(responseMessage('Imóvel descartado'));

export const discardMatchedes = (personId, matcheds_id) =>
  api
    .delete(`people/${personId}/matcheds`, '', {
      params: {
        matcheds_id,
      },
    })
    .then(responseMessage(`'${matcheds_id.length} imóveis descartados'`));

export const discardMatchedsById = (people_id, user_id) =>
  api
    .delete(`properties/matcheds`, '', {
      params: {
        people_id,
        user_id,
      },
    })
    .then(
      responseMessage(
        plural(people_id.length, 'pessoa descartada', 'pessoas descartadas')
      )
    );

/**
 * Remove várias clientes compatíveis
 * @param propertyId
 * @param matchedIds
 * @returns {Promise<AxiosResponse<any>>}
 */
export const removeMultipleMatcheds = (propertyId, matchedIds) =>
  api.delete(`properties/${propertyId}/matcheds`, '', {
    params: {
      only_ids: matchedIds,
    },
  });

export const updateMatched = (matched) =>
  api.update(`properties/matcheds`, matched);

/**
 * Pega um detalhes do matched
 * @param matchedId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getMatched = (matchedId) =>
  api.getOne(`properties/matcheds`, matchedId, { include: 'lost_deal' });

/**
 * Busca pela contagem das relações
 * @param {String} personId - id do cliente que você quer buscar o count das relações
 * @param {Array} relations - as relações que você quer que retorne
 * @return {*|{params}}
 */
export const peopleRelationCounts = (personId, relations) =>
  api.getList(`people/${personId}/relation-counts`, relations);

/**
 * Busca clientes pelo perfil de busca
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const searchPeopleByProfile = (params) => {
  return api.getList(`people/by-search-profiles`, params);
};

export const exportPeopleXLSX = (params) => {
  return api.get('people/xlsx-by-search-profiles');
};

export const hasPhones = async ({ people_id = null, number, ddi, iso }) => {
  let _params = { number, ddi, iso };

  // Adiciona caso venha o id do cliente
  if (people_id) {
    _params = { ..._params, people_id };
  }

  try {
    const {
      data: { has_phone, is_valid, person },
    } = await api.get('people/has-phones', _params);
    return { has_phone, is_valid, person };
  } catch (e) {
    return false;
  }
};

export const peopleCrm = ({
  filter,
  sort = 'by_name',
  offset = 1,
  limit = 100,
}) => {
  return api.get('people', {
    filter: {
      having: ['deals'],
      ...filter,
    },
    with_properties_available: true,
    count: 'deals,matcheds_new,matcheds_sent,matcheds_discarted',
    sort: sort || 'by_name',
    offset,
    limit,
  });
};

export const peopleCrmMatched = ({
  filter,
  sort = 'by_name',
  offset = 1,
  limit = 100,
}) => {
  if (!filter?.by_user_id_or_null) return null;

  return api.get('people', {
    filter,
    count: 'deals,matcheds_new,matcheds_sent,matcheds_discarted',
    sort,
    offset,
    limit,
  });
};

export const getPeopleByStage = ({
  stageId,
  user_id,
  filter,
  limit = 10,
  offset,
}) => {
  return api.get(`people/by-stage/${stageId}`, {
    filter,
    user_id,
    limit,
    offset,
  });
};

/**
 * Atualiza uma lista ou uma pessoa em específico
 * @param peopleId
 * @param isFavorite
 * @returns {Promise<AxiosResponse<*>>}
 */
export const updateIsFavorite = ({ peopleId, isFavorite = false }) => {
  if (Array.isArray(peopleId)) {
    return api.patch(`people/is-favorite`, {
      people_id: peopleId,
      is_favorite: isFavorite,
    });
  }

  return api.patch(`people/${peopleId}/is-favorite`, {
    is_favorite: isFavorite,
  });
};
