import React from 'react';
import MainTitle from 'components/MainTitle';
import Button from 'components/Button';
import Can from 'containers/Can';
import Alert from 'components/Alert';
import SAlert from 'react-s-alert';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeModal,
  openModalContractExtra,
  openModalUser,
} from 'modules/modal';
import { useChangeContract } from 'hooks/useChangeContract';
import BoxHelp from 'components/BoxHelp';
import { useCurrentItems, usePreview1 } from 'hooks/api/contracts';
import { isFetchingSelector, usersSelector } from 'modules/users';

function Header() {
  const { fetchPreview1, ITEM_TYPES } = usePreview1();
  const users = useSelector(usersSelector);
  const isFetching = useSelector(isFetchingSelector);
  const { currentItems, fetchCurrentItems } = useCurrentItems();
  const dispatch = useDispatch();

  const { handleOpenModal } = useChangeContract({
    item: ITEM_TYPES.USERS,
    onSuccess: () => {
      fetchCurrentItems();
      dispatch(openModalUser());
    },
  });

  const handleOpenModalUser = async () => {
    handleOpenModal();
  };

  const handleOpenModalManage = async () => {
    const { data: preview } = await fetchPreview1({
      item: ITEM_TYPES.USERS,
      is_adding: false,
    });

    dispatch(
      openModalContractExtra({
        item: ITEM_TYPES.USERS,
        currentItems,
        preview,
        onSuccess: () => {
          fetchCurrentItems();
          SAlert.success('Contrato alterado');
          dispatch(closeModal());
        },
      })
    );
  };

  return (
    <>
      <MainTitle
        title="Usuários"
        text="Gerenciamento de corretores e usuários do sistema."
      >
        <Button to="/config/users/permissions">Gerenciar permissões</Button>
        <Can run="EDIT_USER">
          <Button color="secondary" onClick={handleOpenModalUser}>
            Adicionar Usuário
          </Button>
        </Can>
      </MainTitle>
      <BoxHelp
        storageKey="users"
        topics={[
          {
            text: 'Gerenciar permissões do grupo',
            videoUrl: 'https://www.youtube.com/watch?v=aZNXAlnZP2c',
            link: 'https://scribehow.com/shared/Gerenciar_permissoes_do_grupo__24-Us4iwRiqcTwSiESsb2A',
          },
          {
            text: 'Validar CRECI',
            videoUrl: 'https://www.youtube.com/watch?v=swMEkbAQpFM',
            link: 'https://scribehow.com/shared/Validar_CRECI__lOOBpqy6TJ6lrbYMWReHcw',
          },
          {
            text: 'Cadastrar e convidar usuário',
            link: 'https://scribehow.com/shared/Cadastrar_e_Convidar_usuario_para_o_sistema_Tecimob__FUhQyvDuR0-kJZKDeUZJ8g',
          },
          {
            text: 'Criar cartão virtual',
            link: 'https://scribehow.com/shared/Criar_cartao_virtual__K6Lq9kcQRMiBcUd_2exzrA',
          },
        ]}
        helpLink="https://tecimob.com.br/ajuda/usuarios-2/"
      />

      {!isFetching && currentItems?.users_quantity > 0 ? (
        <Alert color="secondary" style={{ marginBottom: 30 }}>
          <div className="h-flex">
            <div className="h-flex__cell--grow">
              Você está utilizando <strong>{users.length}</strong> dos{' '}
              <strong>{currentItems?.users_quantity}</strong> assentos de
              usuários contratados.
            </div>
            <button
              className="h-link h-flex__cell--shrink"
              onClick={handleOpenModalManage}
            >
              Gerenciar total de assentos contratados
            </button>
          </div>
        </Alert>
      ) : null}
    </>
  );
}

export default Header;
