// import styles from './styles'

import React from 'react';
import { reduxForm } from 'redux-form';
import { CustomFieldDateRange } from 'pages/Crm/pages/History/components/Filter/styles';
import { Fields } from 'features/Financial';
import * as S from './styles';
import useFormValue from 'hooks/useFormValue';
import { defaultFinancialRanges } from '../../../../../../ranges';

function Filter({ handleSubmit, onAddPosting }) {
  const values = useFormValue([
    'situation',
    'people_id',
    'account_id',
    'start_at',
    'end_at',
  ]);

  return (
    <S.Form onSubmit={handleSubmit}>
      <Fields.Situations />
      {/*<FieldPerson*/}
      {/*  creatable={false}*/}
      {/*  label={null}*/}
      {/*  placeholder="Todos os clientes"*/}
      {/*/>*/}
      <Fields.Account
        creatable={false}
        label={null}
        placeholder="Todas as contas bancárias"
      />
      <CustomFieldDateRange
        staticRanges={defaultFinancialRanges}
        isInline
        canClear={false}
        startDateName="start_date"
        endDateName="end_date"
      />
    </S.Form>
  );
}

export const FilterMonthly = reduxForm({
  form: 'FilterMonthly',
})(Filter);
