import { useMemo } from 'react';
import { ThemeProvider } from 'styled-components';
import * as Card from './styles';
// Components
import CmdControlClick from 'components/CmdControlClick';
import Status from 'containers/ModalRoot/ModalProperty/components/ModalDefault/components/Status';
import FeaturedInfos from './components/FeaturedInfos';

const propTypes = {};
const defaultProps = {};

const currentDate = new Date();

function CardVertical({
  canShowStages,
  handleClickProperty,
  handleClickChecked,
  message,
  children,
  property,
  handleOnDelete,
  imageRenderer,
  onSelect,
  isSelected,
  property: {
    isChecked,
    type,
    transaction,
    calculated_price,
    address_formatted,
    street_address,
    street_number,
    title_formatted,
    complement_address,
    cover_image,
    informations,
    images,
    user,
    reference,
    is_booked,
  } = {},
}) {
  const imageUrl = useMemo(() => {
    if (cover_image) {
      return cover_image?.file_url;
    }
    return images?.[0]?.file_url;
  }, [cover_image, images]);

  const isSelectable = !!onSelect;

  const isSell = useMemo(() => {
    return transaction === 1 || transaction === '1';
  }, [transaction]);

  const transactionName = useMemo(() => {
    switch (transaction) {
      case 1:
      case '1':
        return 'Venda';
      case 2:
      case '2':
        return 'Aluguel';
      default:
        return 'Indefinido';
    }
  }, [transaction]);

  // const address = useMemo(() => {
  //   let _address = '';

  //   if (!!street_address) {
  //     _address += street_address;
  //     if (!!street_number) {
  //       _address += ' ' + street_number;
  //     }
  //   }

  //   return _address;
  // }, [street_address, street_number]);

  // const title = useMemo(() => {
  //   if (informations?.apartment_number) {
  //     const number = informations?.apartment_number;

  //     return `${title_formatted} - N.º ${number?.value}`;
  //   }

  //   return title_formatted;
  // }, [title_formatted, informations]);

  const delivery_forecast = useMemo(() => {
    if (property?.delivery_forecast === currentDate?.getFullYear())
      return ' - Entrega este ano';

    if (property?.delivery_forecast > currentDate?.getFullYear()) {
      return ` - Entrega em ${property.delivery_forecast}`;
    }
  }, [property]);

  const handleClickSelect = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onSelect({ isSelectable, property });
  };

  return (
    <ThemeProvider
      theme={{
        isChecked,
        isSell,
        isSelectable,
        isSelected,
      }}
    >
      <CmdControlClick
        onCmdControlClick={() => {
          window.open(`/properties/show/${property.id}`);
        }}
        onClick={handleClickProperty}
        component={Card.Wrapper}
      >
        <Card.RowTop>
          <Card.WrapImage>
            {isSelectable && (
              <Card.Check checked={isSelected} onClick={handleClickSelect} />
            )}
            <Card.PropertyImage
              src={imageUrl}
              alt={`Capa do imóvel ref.: ${reference}`}
            />
            {/*{is_booked && <Card.LabelImage>Reservado</Card.LabelImage>}*/}
          </Card.WrapImage>
          <Card.Content>
            <Card.TextWrapper>
              <Card.Header>
                <div style={{ flex: '1 0', minWidth: '1px' }}>
                  <Card.Title>
                    {property?.reference} - {property?.title_formatted}{' '}
                    <Status separator="-" property={property} />
                  </Card.Title>
                  <Card.WrapText>
                    <Card.Text key={property?.id + 'situation'}>
                      {property?.situation?.title}
                      {delivery_forecast}
                    </Card.Text>
                    {property?.street_address && (
                      <Card.Text key={property?.id + 'address'}>
                        {property?.street_address}
                        {property?.street_number &&
                          `, ${property?.street_number}`}
                      </Card.Text>
                    )}
                    <Card.Text key={property?.id + 'formated'}>
                      {property?.address_formatted}
                    </Card.Text>
                    {informations?.apartment_number?.value && (
                      <Card.Text key={property?.id + 'type'}>
                        Unidade {informations?.apartment_number?.value}
                      </Card.Text>
                    )}
                    {informations?.lot_number?.value && (
                      <Card.Text key={property?.id + 'lot_number'}>
                        Unidade {informations?.lot_number?.value}
                      </Card.Text>
                    )}
                  </Card.WrapText>
                </div>
                {handleOnDelete && (
                  <div>
                    <Card.Delete
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOnDelete(property)();
                      }}
                    />
                  </div>
                )}
                {handleClickChecked && (
                  <div>
                    <Card.Checkbox
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClickChecked(e);
                      }}
                      checked={isChecked}
                    />
                  </div>
                )}
              </Card.Header>
              <FeaturedInfos property={property} />
              <Card.Price>
                {calculated_price} - {transactionName}
              </Card.Price>
            </Card.TextWrapper>
            {children && (
              <Card.Actions
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {children}
              </Card.Actions>
            )}
          </Card.Content>
        </Card.RowTop>
        {message && (
          <Card.RowBottom>
            <Card.Text>{message}</Card.Text>
            {/*<Card.Spacer />*/}
            {/*<Card.Text>Ref.: {reference}</Card.Text>*/}
          </Card.RowBottom>
        )}
        {isSelected && <Card.BgSelected onClick={handleClickSelect} />}
        {isChecked && <Card.BgSelected onClick={handleClickChecked} />}
      </CmdControlClick>
    </ThemeProvider>
  );
}

CardVertical.propTypes = propTypes;
CardVertical.defaultProps = defaultProps;

export default CardVertical;
