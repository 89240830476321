// import styles from './styles'

import React from 'react';
import { formValueSelector, reduxForm } from 'redux-form';
import * as S from './styles';
import { FieldLabel } from 'components/Form';
import { CATEGORY_TYPE_OPTIONS } from '../../../../fields/FieldCategoryType';

export const selector = formValueSelector('FilterPostings');

function Filter({ handleSubmit, reset, onAddPosting }) {
  return (
    <S.Form onSubmit={handleSubmit}>
      <FieldLabel
        label="Tipo de lançamento"
        name="category_type"
        options={CATEGORY_TYPE_OPTIONS}
        styleFormGroup={{ minWidth: '110px' }}
      />
      <FieldLabel
        label="Situação"
        name="situation"
        options={[
          { label: 'Pago', value: 'Pago' },
          { label: 'Em aberto', value: 'Aberto' },
          { label: 'Vencido', value: 'Vencido' },
        ]}
        styleFormGroup={{ minWidth: '110px' }}
      />
      <FieldLabel
        label="Ocorrência"
        name="repetition_type"
        options={[
          { label: 'Única', value: 'unic' },
          { label: 'Recorrente', value: 'recorrente' },
          { label: 'Parcelado', value: 'parcelado' },
        ]}
        styleFormGroup={{ minWidth: '110px' }}
      />
    </S.Form>
  );
}

export const FilterPostings = reduxForm({
  form: 'FilterPostings',
  enableReinitialize: false,
})(Filter);
