import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  right: -59px;
  font-size: 16px;
  height: 40px;
  top: 200px; /* header height + 100px */
  transform: rotate(-90deg);
  background: #0a4ed6;
  color: white;
  font-weight: bold;
  line-height: 1.5rem;
  padding: 0 15px;
  border-radius: 15px 15px 0px 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 6;

  @media only screen and (max-width: 1366px) {
    font-size: 14px;
    height: 30px;
    right: -55px;
  }
`;
