import { createSelector } from 'reselect';
// Helpers
import { createModule } from '../lib/reducer-helpers';

// Selectors
export const authsSelector = (state) => state.authentications;
export const authSelector = (name) =>
  createSelector(authsSelector, (auths) => auths[name]);

// Actions
const createAction = createModule('authentications');

const ADD_AUTH = createAction('ADD_AUTH');
const REMOVE_AUTH = createAction('REMOVE_AUTH');
const RESET_AUTHENTICATIONS = createAction('RESET_AUTHENTICATIONS');

// Tipos de autenticação que existe no painel
export const TYPES_AUTH = {
  GOOGLE_CALENDAR: 'calendar',
  GOOGLE_ANALYTICS: 'analytics',
  ORULO: 'orulo',
  RD_STATION: 'rdstation',
  LEADFY: 'leadfy',
};

// Initial State
const initialState = JSON.parse(localStorage.getItem('authentications')) || {
  [TYPES_AUTH.GOOGLE_CALENDAR]: false,
  [TYPES_AUTH.GOOGLE_ANALYTICS]: false,
  [TYPES_AUTH.ORULO]: false,
  [TYPES_AUTH.RD_STATION]: false,
  [TYPES_AUTH.LEADFY]: false,
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_AUTH:
      return {
        ...state,
        [action.name]: true,
      };
    case REMOVE_AUTH:
      return {
        ...state,
        [action.name]: false,
      };
    case RESET_AUTHENTICATIONS:
      return initialState;
    default:
      return state;
  }
}

/**
 * Adiciona a autenticação
 * @param name
 */
export const addAuth = (name) => {
  const authentications = localStorage.getItem('authentications');
  localStorage.setItem('authentications', { ...authentications, [name]: true });
  return { type: ADD_AUTH, name };
};

/**
 * Remove a autenticação
 * @param name
 */
export const removeAuth = (name) => {
  const authentications = localStorage.getItem('authentications');
  localStorage.setItem('authentications', {
    ...authentications,
    [name]: false,
  });

  return { type: REMOVE_AUTH, name };
};

/**
 * Seta uma autenticação
 * @param name - keyname
 * @param hasAuth - se tem ou não autenticação
 */
export const setAuth = (name, hasAuth) =>
  hasAuth ? addAuth(name) : removeAuth(name);

/**
 * Reset authentications
 */
export const resetAuthentications = () => ({
  type: RESET_AUTHENTICATIONS,
});
