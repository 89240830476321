import * as S from './styles';
import Button from 'components/Button';
import { MdEdit, MdFavorite, MdFavoriteBorder } from 'react-icons/md';
import { FaWhatsapp } from 'react-icons/fa';
import { useMemo } from 'react';
import { whatsappSendUrl } from 'lib/share-helpers';
import { updateIsFavorite } from 'services/people';

export function ModalActions({ person, onToggleFavorite }) {
  const whatsappUrl = useMemo(() => {
    return whatsappSendUrl(person.whatsapp_number, '', '');
  }, [person]);

  const handleClickToggleFavorite = async () => {
    const response = await updateIsFavorite({
      peopleId: person.id,
      isFavorite: !person.is_favorite,
    });

    // Atualiza a modal
    if (onToggleFavorite) onToggleFavorite(response);
  };

  return (
    <S.Wrapper>
      <Button color="white" size="small" className="btn">
        <MdEdit />
      </Button>
      {person.whatsapp_number ? (
        <Button
          color="white"
          size="small"
          className="btn"
          tagName="a"
          href={whatsappUrl}
          target="_blank"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <FaWhatsapp color="#31D084" />
        </Button>
      ) : null}
      <Button
        color="white"
        size="small"
        className="btn"
        onClick={(e) => {
          e.stopPropagation();
          handleClickToggleFavorite();
        }}
      >
        {person.is_favorite ? (
          <MdFavorite color="#F84343" />
        ) : (
          <MdFavoriteBorder color="#F84343" />
        )}
      </Button>
    </S.Wrapper>
  );
}
