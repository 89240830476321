import { Financial, StatusGuard } from 'features/Financial';
import { Routes } from './routes';

export default function FinancialPage() {
  return (
    <Financial.Layout>
      <StatusGuard>
        <Routes />
      </StatusGuard>
    </Financial.Layout>
  );
}
