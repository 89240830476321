// import styles from './styles'

import React, { useEffect } from 'react';
import { SelectMultiple } from 'components/Form';
import { Field } from 'redux-form';
import { parseSelect } from 'lib/formHelpers';
import { useGetAllCategories } from '../../hooks/useCategories';
import * as S from './styles';

function FieldCategory({
  isDebit,
  label = 'Categoria',
  name = 'category_id',
  options,
  ...props
}) {
  const { allCategories, fetchCategories } = useGetAllCategories();

  useEffect(() => {
    if (isDebit !== undefined) {
      fetchCategories({ filter: { is_debit: isDebit, only_parents: false } });
    } else {
      fetchCategories({ filter: { only_parents: false } });
    }
  }, [fetchCategories, isDebit]);

  return (
    <Field
      multi={false}
      name={name}
      label={label}
      component={SelectMultiple}
      options={allCategories}
      labelKey="title"
      valueKey="id"
      parse={parseSelect('id')}
      valueRenderer={(option) => {
        return (
          <S.Wrapper title={option.title}>
            <S.Ball color={option.color} />
            <S.Text>{option.title}</S.Text>
          </S.Wrapper>
        );
      }}
      optionRenderer={(option) => {
        if (option.isCategory) {
          return (
            <S.Wrapper
              key={option.id}
              style={{
                padding: 12,
                margin: '-8px -10px',
                background: '#f3f6fa',
                fontWeight: 600,
              }}
            >
              <S.Ball color={option.color} />
              <S.Text>{option.title}</S.Text>
            </S.Wrapper>
          );
        }

        return (
          <S.Subcategory
            key={option.id}
            className={option.is_subtype && 'h-margin-left--15'}
          >
            <S.Ball color={option.color} />
            <S.Text>{option.title}</S.Text>
          </S.Subcategory>
        );
      }}
      {...props}
    />
  );
}

export { FieldCategory };
