import { useMemo } from 'react';

export function useModalPosting({ type = 1, posting, moviment }) {
  const isEditing = useMemo(() => !!moviment?.id, [moviment]);

  const configs = useMemo(() => {
    if (isEditing) {
      return {
        isEditing: true,
        titleModal: 'Editar lançamento',
        date_field_name: 'billing_date',

        // Campo necessário pra saber se deve ou não mostrar
        // o campo de people_id e repeatment_type
        canShowRepeatmentType: moviment.posting.installments !== 1,
      };
    }

    // Verifica se o tipo da modal é receita
    if (type === 1) {
      return {
        titleModal: 'Nova receita',
        repetition_type_label: 'Receita recorrente',
        date_field_name: 'date',
      };
    }

    return {
      titleModal: 'Nova despesa',
      repetition_type_label: 'Despesa recorrente',
      date_field_name: 'date',
    };
  }, [type, posting, moviment]);

  return { configs };
}
