import styled from 'styled-components';

export const Box = styled.div`
  padding: 40px;
  background: #ffffff;
  border-radius: 4px;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 55px;
  justify-content: space-between;

  button {
    margin-top: 25px;
  }
`;

export const BoxLeft = styled.div`
  max-width: 403px;
`;

export const BoxRight = styled.div`
  width: 327px;
  flex-shrink: 0;
`;

export const Title = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
`;

export const Text = styled.p`
  line-height: 1.5em;
`;

export const List = styled.ul`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 15px;
  margin-bottom: 20px;

  li {
    display: flex;
    gap: 10px;
    font-weight: 500;
  }
`;

export const Ball = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #9be6c2;
  color: #09121f;
  display: grid;
  place-items: center;
`;

export const WrapVideo = styled.div`
  cursor: pointer;
  h2 {
    font-size: 20px;
  }
`;
