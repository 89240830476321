// import * as S from './styles'

import ListOptions from 'components/ListOptions';
import leadfy from './icon-leadfy.png';
import React, { useMemo } from 'react';
import { useGetCrm } from 'pages/Integrations/hooks/useCrm';
import Button from 'components/Button';

export function LeadfyIntegration() {
  const { hasIntegration, connect, disconnect } = useGetCrm();

  const renderOptions = useMemo(
    () => () => {
      if (hasIntegration)
        return (
          <>
            <Button color="danger" onClick={disconnect}>
              Desconectar
            </Button>
          </>
        );

      return (
        <Button onClick={connect} color="success">
          Conectar
        </Button>
      );
    },
    [hasIntegration, connect, disconnect]
  );

  return (
    <ListOptions.item
      image={leadfy}
      imageStyle={{
        width: 42,
      }}
      title="Leadfy"
      text="Envie automaticamente todos os contatos recebidos no Tecimob diretamente para o Leadfy."
      renderOptions={renderOptions}
    />
  );
}
