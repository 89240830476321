// import * as S from './styles'

import { Financial, Header } from '../../../../index';
import React from 'react';
import { useDashboardContext } from '../../hooks/useDashboardContext';

export function HeaderActions() {
  const { refresh } = useDashboardContext();

  return (
    <Header.Actions>
      <Financial.AddPosting onSuccess={refresh} />
    </Header.Actions>
  );
}
