import React from 'react';
import PropTypes from 'prop-types';
import { MdAdd, MdFavorite, MdPerson, MdSearch } from 'react-icons/md';
// Components
// import ItemDropdown, { Item } from '../../ItemDropdown';
import { useDispatch } from 'react-redux';
import { openModalPerson, openModalPersonAdd } from 'modules/modal';
import MenuHeader, { Item } from 'components/MenuHeader';
import { useLocation } from 'react-router';

function MenuPeople() {
  const dispatch = useDispatch();

  const location = useLocation();

  // Verifica se a URL começa exatamente com "/reports" e não inclui outra rota antes
  const isActive = () => location.pathname.startsWith('/people/');

  return (
    <MenuHeader title="Clientes" to="/people/show" handleActive={isActive}>
      <Item
        icon={MdAdd}
        iconColor="secondary"
        title="Novo Cliente"
        text="Cadastre um novo cliente"
        onClick={() => {
          dispatch(
            openModalPersonAdd(null, (person) => {
              dispatch(openModalPerson(person));
            })
          );
        }}
      />
      <Item
        to={`/people/show`}
        title="Meus Clientes"
        text="Veja todos os seus clientes"
        icon={MdPerson}
        iconColor="success"
      />
      <Item
        to={`/crm/favorites`}
        title="Clientes Favoritos"
        text="Ver lista de favoritos"
        icon={MdFavorite}
        iconColor="#AE52CE"
      />
      <Item
        to="/profile/search"
        title="Pesquisar por perfil"
        text="Encontre o cliente ideal"
        icon={MdSearch}
        iconColor="caution"
      />
    </MenuHeader>
  );

  // return (
  //   <ItemDropdown active="people" label="Clientes" to="/people/show">
  //     {({ close }) => (
  //       <>
  //         <Item onClick={close}>
  //           <LinkWithIcon
  //             href="#"
  //             title="Novo Cliente"
  //             text="Cadastre um novo cliente."
  //             icon={MdAdd}
  //             iconColor="secondary"
  //             onClick={() => {
  //               dispatch(
  //                 openModalPersonAdd(null, (person) => {
  //                   dispatch(openModalPerson(person));
  //                 })
  //               );
  //             }}
  //           />
  //         </Item>
  //         <Item onClick={close}>
  //           <LinkWithIcon
  //             to={`/people/show`}
  //             title="Meus Clientes"
  //             text="Veja todos os seus clientes"
  //             icon={MdPerson}
  //             iconColor="success"
  //           />
  //         </Item>
  //       </>
  //     )}
  //   </ItemDropdown>
  // );
}

MenuPeople.defaultProps = {
  funnelId: null,
};

MenuPeople.propTypes = {
  funnelId: PropTypes.string,
};

export default MenuPeople;
