import React from 'react';
import PropTypes from 'prop-types';
// Components
import Button from '../../../../../components/Button';
import ModalTemplate from '../../../../../components/Modal/components/ModalTemplate';

const propTypes = {
  handleClose: PropTypes.func,
};
const defaultProps = {
  handleClose: () => {},
};

const RegisterDomainAlert = ({ handleClose, onSubmit }) => (
  <ModalTemplate
    title="Aviso importante"
    handleClose={handleClose}
    footerActions={
      <>
        <Button
          color="white"
          className="h-color--secondary"
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <span className="h-flex__cell--grow"></span>
        <Button color="success" onClick={onSubmit}>
          Continuar
        </Button>
      </>
    }
  >
    <p className="h-margin-bottom--15">
      Todos os domínios com a terminação .br são oficialmente registrados no
      órgão federal Registro.br, que impõe uma taxa anual de R$ 40,00.
    </p>

    <p>
      Nossa função é simplesmente facilitar a interação entre você e o
      Registro.br. O domínio será registrado diretamente em seu nome, garantindo
      que você tenha a liberdade de utilizá-lo em qualquer outra plataforma,
      conforme desejar.
    </p>
  </ModalTemplate>
);

RegisterDomainAlert.propTypes = propTypes;
RegisterDomainAlert.defaultProps = defaultProps;

export default RegisterDomainAlert;
