import React, { useMemo } from 'react';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { Step } from 'containers/ModalRoot/ModalPayment/styles';
import { Row } from 'react-flexbox-grid';
import { Field } from 'redux-form';
import { InputDate } from 'components/Form';
import Button from 'components/Button';
import moment, { format } from 'lib/moment';
import { plural } from 'lib/text';
import useFormValue from 'hooks/useFormValue';
import styled from 'styled-components';
import { useModalPayment } from 'containers/ModalRoot/ModalUpdateContractDate/contexts/payment';

// import { Wrapper } from './styles';

export const Content = styled.div`
  .react-datepicker-wrapper {
    width: 120px;
  }
`;

function UpdateContract({ handleClose }) {
  const { currentContract, updatePreviewPrice, previewPrice } =
    useModalPayment();

  const { setPage } = useModalPayment();

  const end_at = useFormValue('end_at');

  const diff = useMemo(() => {
    const date1 = moment(end_at, format.date);
    const date2 = moment(currentContract.end_at, format.date);
    const days = date1.diff(date2, 'days');

    return plural(days, 'dia.', 'dias.');
  }, [currentContract, end_at]);

  const minDate = useMemo(() => {
    return moment(currentContract.end_at, format.date)
      .add(1, 'hour')
      .add(1, 'day');
  }, [currentContract]);

  if (!currentContract) return false;

  return (
    <ModalTemplate
      title="Alteração de contrato"
      text="Mudança na data de vencimento"
      handleClose={handleClose}
      titleActions={
        <Step>
          <p>
            Etapa
            <br />
            1/2
          </p>
        </Step>
      }
    >
      <Content>
        <p>
          <strong>Altere a data de vencimento do seu contrato!</strong>
          <br />
          Escolha sempre uma data após a data de renovação atual
          <br />
          Sua atual data de renovação é{' '}
          <strong>{currentContract.end_at}</strong>
        </p>
        <Row className="h-margin-top--15">
          {minDate && (
            <Field
              xs={12}
              label="Para qual data você gostaria de alterar?"
              name="end_at"
              component={InputDate}
              minDate={minDate}
              onChange={updatePreviewPrice}
            />
          )}
        </Row>
        {previewPrice && (
          <>
            <p>
              A diferença entre as datas é de <strong>{diff}</strong>
            </p>
            <p>
              O custo mensal do seu plano é de{' '}
              {currentContract?.values?.monthly_price}
            </p>
            <br />
            <p>
              O custo para alterar a data do seu contrato é de{' '}
              <strong>{previewPrice}</strong>
            </p>
          </>
        )}
      </Content>
      <ModalFooter>
        <Button type="button" onClick={handleClose} color="white">
          Voltar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button
          disabled={!previewPrice}
          type="button"
          color="success"
          onClick={() => {
            setPage('Payment');
          }}
        >
          Prosseguir para pagamento
        </Button>
      </ModalFooter>
    </ModalTemplate>
  );
}

export default UpdateContract;
