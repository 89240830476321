import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(${(p) => p.theme.columns}, 1fr);
  margin-top: 10px;

  + .FormError {
    margin-top: 10px;
  }
`;

export const Item = styled.div``;

export const Color = styled.div`
  ${(p) => {
    return p.theme.isActive
      ? css`
          outline: 1px solid #0084f4;
          outline-offset: 2px;
        `
      : null;
  }}
  width: 20px;
  height: 20px;
  border-radius: 100%;
  cursor: pointer;
  background: ${(p) => p.color};
`;
