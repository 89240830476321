import Avatar from 'components/Avatar';
import { openModalProperty } from 'modules/modal';
import { AiFillCar, FaBed, MdFullscreen } from 'react-icons/all';
import { useDispatch } from 'react-redux';
import { transformProperty } from '../../helpers';
import { Content, PropertyPrice, Rooms, Wrapper } from './styles';

function PropertyItem({ property, onClick }) {
  const dispatch = useDispatch();
  const data = transformProperty(property);

  const handleClick = () => {
    dispatch(openModalProperty({ property }));
    if (onClick) onClick();
  };

  return (
    <>
      <Wrapper typeTransaction={property.transaction} onClick={handleClick}>
        {data.image ? (
          <Avatar image={data.image} text={data.image_alt} />
        ) : null}
        <Content>
          <div style={{ fontWeight: '700', color: '#5C5260' }}>
            {data.title}
          </div>
          <div style={{ color: '#5C5260', fontWeight: '400' }}>
            {data.address}
          </div>
          <Rooms>
            {data.bedroom && (
              <div data-tip={data.bedroom_tip} data-place="right">
                <FaBed /> {data.bedroom}
              </div>
            )}
            {data.garage && (
              <div>
                <AiFillCar /> {data.garage}
              </div>
            )}
            {data.primary_area && (
              <div data-tip={data.primary_area_tip}>
                <MdFullscreen /> {data.primary_area}
              </div>
            )}
          </Rooms>
        </Content>
      </Wrapper>
      <PropertyPrice typeTransaction={property.transaction}>
        <span>R$ {data.price}</span>
      </PropertyPrice>
    </>
  );
}

export default PropertyItem;
