import styled from 'styled-components';

export const RadioList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;

  .RadioButton {
    margin-top: 0 !important;
  }

  .FormGroup {
    margin-bottom: 0;
  }
`;

export const Wrapinput = styled.div`
  width: 100%;

  .Select-option:first-child .ReactSelect__option {
    background-color: #e8f1ff !important;
  }

  .Select-menu-outer,
  .Select-menu {
    max-height: 395px !important;
    z-index: 999999999;
  }
`;
