// import styles from './styles'
import { Financial } from 'features/Financial';
import Button from 'components/Button';

export function Nodata({ onAddAccount }) {
  return (
    <Financial.Nodata title="Você ainda não possui nenhuma conta bancária cadastrada.">
      <Button color="secondary" onClick={onAddAccount({})}>
        Criar conta bancária
      </Button>
    </Financial.Nodata>
  );
}
