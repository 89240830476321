import {
  alterItem,
  createModule,
  getStatePath,
  removeItem,
  selectItem,
  toogleSelected,
  unselectItem,
} from 'lib/reducer-helpers';
import { arrayMove } from 'react-sortable-hoc';
import { createSelector } from 'reselect';

const initialState = {
  data: [],
  meta: {
    totalData: 0,
    isFetching: false,
    isAllSelected: false,
  },
  selected: [],
};

/**
 *
 * @param moduleName
 * @return {Object} - retorna um objeto contendo actionTypes, actionCreators e o reducer
 */
export default (moduleName) => {
  const createAction = createModule(moduleName);

  // Action Types
  const REQUEST = createAction('REQUEST');
  const RECEIVE = createAction('RECEIVE');
  const ADD = createAction('ADD');
  const ADD_UP = createAction('ADD_UP');
  const ALTER = createAction('ALTER');
  const ALTER_ALL = createAction('ALTER_ALL');
  const REMOVING = createAction('REMOVING');
  const REMOVE = createAction('REMOVE');
  const RESET = createAction('RESET');
  const ORDER = createAction('ORDER');
  const SET_META = createAction('SET_META');

  const ADD_SELECTED = createAction('ADD_SELECTED');
  const REMOVE_SELECTED = createAction('REMOVE_SELECTED');
  const TOGGLE_SELECTED = createAction('TOGGLE_SELECTED');

  // Selectors
  const getAll = (state) => getStatePath(state, moduleName)?.data;
  const getSelected = (state) => getStatePath(state, moduleName)?.selected;
  const isFetching = (state) =>
    getStatePath(state, moduleName)?.meta?.isFetching;

  const getAllWithSelected = createSelector(
    [getAll, getSelected],
    (allData, selected) => {
      return allData
        ? allData.map((data) => {
            if (selected.indexOf(data.id) !== -1) {
              return { ...data, isSelected: true };
            }

            return { ...data, isSelected: false };
          })
        : [];
    }
  );

  return {
    selectors: {
      getAll,
      getSelected,
      isFetching,
      getAllWithSelected,
    },
    actionTypes: {
      REQUEST,
      RECEIVE,
      ADD,
      ADD_UP,
      ALTER,
      ALTER_ALL,
      REMOVE,
      ADD_SELECTED,
      REMOVE_SELECTED,
      TOGGLE_SELECTED,
      ORDER,
      SET_META,
    },
    actionCreators: {
      request: () => ({ type: REQUEST }),
      receive: (payload) => ({ type: RECEIVE, payload }),
      add: (item) => ({ type: ADD, item }),
      addUp: (item) => ({ type: ADD_UP, item }),
      alter: (item, cb, identifier) => ({ type: ALTER, item, cb, identifier }),
      alterAll: (payload) => ({ type: ALTER_ALL, payload }),
      removing: (id, key = 'id') => ({ type: REMOVING, id, key }),
      remove: (id, key = 'id') => ({ type: REMOVE, id, key }),
      addSelected: (item) => ({ type: ADD_SELECTED, item }),
      removeSelected: (item) => ({ type: REMOVE_SELECTED, item }),
      toggleSelected: (item) => ({ type: TOGGLE_SELECTED, item }),
      order: (oldIndex, newIndex) => ({ type: ORDER, oldIndex, newIndex }),
      reset: () => ({ type: RESET }),
      setMeta: (meta) => ({ type: SET_META, meta }),
    },
    reducer: (state = initialState, action) => {
      switch (action.type) {
        case REQUEST:
          return {
            ...state,
            meta: { ...state.meta, isFetching: true },
          };
        case RECEIVE:
          return {
            ...state,
            data: action.payload,
            meta: { ...state.meta, isFetching: false },
          };
        case ADD:
          return {
            ...state,
            data: [...state.data, action.item],
          };
        case ADD_UP:
          return {
            ...state,
            data: [action.item, ...state.data],
          };
        case ORDER:
          return {
            ...state,
            data: [...arrayMove(state.data, action.oldIndex, action.newIndex)],
          };
        case ALTER:
          return {
            ...state,
            data: alterItem(
              state.data,
              action.item,
              action.cb,
              action.identifier
            ),
          };
        case ALTER_ALL:
          return {
            ...state,
            data: state.data.map((item) => ({
              ...item,
              ...action.payload,
            })),
          };
        case REMOVING:
          return {
            ...state,
            data: state.data.map((value) => {
              if (value[action.key] === action.id) {
                return { ...value, isRemoving: true };
              }
              return value;
            }),
          };
        case REMOVE:
          return {
            ...state,
            data: removeItem(
              state.data,
              { [action.key]: action.id },
              action.key
            ),
            selected: unselectItem(state.selected, action.item),
          };
        case ADD_SELECTED:
          return {
            ...state,
            selected: selectItem(state.selected, action.item),
          };
        case REMOVE_SELECTED:
          return {
            ...state,
            selected: unselectItem(state.selected, action.item),
          };
        case TOGGLE_SELECTED:
          return {
            ...state,
            selected: toogleSelected(state.selected, action.item),
          };
        case SET_META:
          return {
            ...state,
            meta: {
              ...state.meta,
              ...action.meta,
            },
          };
        case RESET:
          return initialState;
        default:
          return state;
      }
    },
  };
};
