import useIntegrations from 'containers/ModalRoot/ModalPayment/hooks/useIntegrations';
import Plan from 'lib/Plan';
import { useEffect, useState } from 'react';

const plan = new Plan();

// Cria um formatador de valor
export const formatter = new Intl.NumberFormat('pt-BR', {
  useGrouping: true,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const usePlan = ({
  users,
  months,
  mailboxes,
  prices,
  dwv,
  orulo,
  is_financial_required,
}) => {
  const [values, setValues] = useState({
    netMonthlyPrice: null,
    grossMonthlyPrice: null,
    totalNetValue: null,
    netValue: null,
    grossValue: null,
    totalGrossValue: null,
    percentage: null,
    totalPercentage: null,
    totalDiscount: null,
    planName: null,
    installmentsSlip: null,
    totalInstallmentsSlip: null,
    installmentsCreditCard: null,
    totalInstallmentsCreditCard: null,
    oruloPrice: null,
    dwvPrice: null,
  });

  const { hasOrulo, hasDwv } = useIntegrations();

  const updatePrices = ({ users, months, mailboxes, prices }) => {
    plan.users = users;
    plan.months = months;
    plan.subscriptionPrice = prices?.subscription_price;
    plan.userPrice = parseFloat(prices?.user_price);
    plan.mailboxes = mailboxes;
    plan.mailboxPrice = parseFloat(prices?.mailbox_price);
    plan.minValueSlip = prices?.bs_min_value;
    plan.minValueCreditCard = prices?.cc_min_value;
    plan.creditCardInstallments = {
      3: prices?.cc_max_installments_3m,
      6: prices?.cc_max_installments_6m,
      12: prices?.cc_max_installments_12m,
    };
    plan.slipInstallments = {
      3: prices?.bs_max_installments_3m,
      6: prices?.bs_max_installments_6m,
      12: prices?.bs_max_installments_12m,
    };
    plan.discount12m = prices?.discount_12m;
    plan.discount6m = prices?.discount_6m;
    plan.oruloPrice = orulo || hasOrulo ? prices.orulo_price : 0;
    plan.dwvPrice = dwv || hasDwv ? prices.dwv_price : 0;
    plan.deploymentPrice = prices?.deploymentPrice;

    // Atualiza dados do financeiro
    plan.financialPrice = prices.financial_price;
    plan.isFinancialRequired = is_financial_required;

    setValues({
      netMonthlyPrice: formatter.format(plan.getNetMonthlyPayment()),
      grossMonthlyPrice: formatter.format(plan.getGrossMonthlyPayment()),
      totalNetValue: formatter.format(plan.getNetValue()),
      netValue: plan.getNetValue(),
      grossValue: plan.getGrossValue(),
      totalGrossValue: formatter.format(plan.getGrossValue()),
      percentage: plan.getDiscountPercentage(),
      totalPercentage: plan.getDiscountPercentage() * 100,
      totalDiscount: formatter.format(plan.getDiscount()),
      planName: plan.getName(),
      installmentsSlip: plan.getInstallmentsSlip(),
      totalInstallmentsSlip: formatter.format(plan.getPriceInstallmentsSlip()),
      installmentsCreditCard: plan.getInstallmentsCreditCard(),
      totalInstallmentsCreditCard: formatter.format(
        plan.getPriceInstallmentsCreditCard()
      ),
      oruloPrice: formatter.format(plan.getOruloPrice()),
      dwvPrice: formatter.format(plan.getDwvPrice()),
    });
  };

  useEffect(() => {
    updatePrices({
      users,
      months,
      mailboxes,
      prices,
    });
  }, [users, months, mailboxes, prices, dwv, orulo]);

  return [values, setValues];
};
