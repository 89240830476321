import React, { useCallback, useMemo, useState } from 'react';
import moment from 'lib/moment';
import { ptBR } from 'react-date-range/dist/locale';
import { FormLabel, Input } from 'components/Form';
import { DateRangePicker } from 'react-date-range';
import { createStaticRanges } from 'react-date-range/dist/defaultRanges';

import Popover from 'components/Popover';
import { Field } from 'redux-form';
import { useDispatch } from 'react-redux';
import useReduxForm from 'hooks/useReduxForm';
import useFormValue from 'hooks/useFormValue';
import GroupControl, { GroupItem } from 'components/GroupControl';
import { Header, Remove, Wrapper } from './styles';
import { defaultStaticRanges, DEFINED_RANGES } from './helpers';

function FieldDateRange({
  label = '',
  isInline = false,
  startDateName = 'start_date',
  endDateName = 'end_date',
  format = 'DD/MM/YYYY',
  canClear = true,
  staticRanges = defaultStaticRanges,
  onChange,
  className,
  popperOptions,
  disabled,
  dateRangeProps,
}) {
  const dispatch = useDispatch();
  const startDate = useFormValue(startDateName);
  const endDate = useFormValue(endDateName);
  const { change } = useReduxForm();

  const [isOpen, setIsOpen] = useState(false);

  const hasDate = useMemo(() => {
    return !!startDate || !!endDate;
  }, [startDate, endDate]);

  const [state, setState] = useState([
    {
      startDate: startDate ? moment(startDate, format).toDate() : new Date(),
      endDate: endDate ? moment(endDate, format).toDate() : new Date(),
      key: 'selection',
    },
  ]);

  const setRanges = useCallback(
    (ranges) => {
      try {
        const {
          selection: { startDate, endDate },
        } = ranges;

        dispatch(change(startDateName, moment(startDate).format(format)));
        dispatch(change(endDateName, moment(endDate).format(format)));
      } catch {
        dispatch(change(startDateName, null));
        dispatch(change(endDateName, null));
      }
    },
    [dispatch, change]
  );

  const clear = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      setRanges(null);
    },
    [setRanges]
  );

  const handleOpenPopover = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const handleClosePopover = useCallback(() => {
    setIsOpen(false);
    setRanges({ selection: state[0] });
  }, [state]);

  const handleChange = useCallback(
    (ranges) => {
      setState([ranges.selection]);
      setRanges(ranges);

      const { startDate, endDate } = ranges.selection;
      if (startDate && endDate && startDate.getTime() !== endDate.getTime()) {
        setIsOpen(false);
      }

      if (onChange) onChange(ranges);
    },
    [onChange]
  );

  return (
    <div className={className}>
      <Header>
        <FormLabel>{label}</FormLabel>
        {hasDate && canClear && !isInline && (
          <Remove onClick={clear}>Limpar</Remove>
        )}
      </Header>
      <Wrapper>
        <Popover
          isControlled
          isOpen={isOpen}
          width={'auto'}
          popperOptions={{
            className: 'Popover Popover--date-range',
            placement: 'auto',
            style: { padding: 0 },
            ...popperOptions,
          }}
          locale={ptBR}
          ranges={state}
          onChange={handleChange}
          staticRanges={createStaticRanges(staticRanges)}
          inputRanges={[]}
          component={DateRangePicker}
          onClose={handleClosePopover}
          onOpen={handleOpenPopover}
          {...dateRangeProps}
        >
          <GroupControl>
            <GroupItem>
              <Field
                readonly
                disabled={disabled}
                name={startDateName}
                component={Input}
                placeholder="DD/MM/AAAA"
              />
            </GroupItem>
            <GroupItem divider size={10} />
            <GroupItem>
              <Field
                readonly
                disabled={disabled}
                name={endDateName}
                component={Input}
                placeholder="DD/MM/AAAA"
              />
            </GroupItem>
            {hasDate && canClear && isInline && (
              <Remove onClick={clear}>Limpar</Remove>
            )}
          </GroupControl>
        </Popover>
      </Wrapper>
    </div>
  );
}

export { DEFINED_RANGES };

export default FieldDateRange;
