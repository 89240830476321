import * as accountsService from 'features/Financial/services/accounts';
import { useRequest } from 'hooks/useRequest';
import { useDispatch } from 'react-redux';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { useMemo } from 'react';

export function useGetAccounts({ type = 1 }) {
  const { data, meta, isFetching, fetchData } = useRequest({
    request: ({ offset, ...params }) =>
      accountsService.indexAccount({ type, offset, ...params }),
    initialFetch: false,
  });

  const allAccounts = useMemo(() => {
    if (!data || !Array.isArray(data)) {
      return [];
    }

    // Separar contas e cartões
    const accounts = data.filter((item) => item.type === 1);
    const cards = data.filter((item) => item.type === 2);

    // Construir a estrutura de dados conforme solicitado
    return [
      { title: 'Contas', value: 'accounts', optGroup: true },
      ...accounts,
      { title: 'Cartões', value: 'cards', optGroup: true },
      ...cards,
    ];
  }, [data]);

  return {
    accounts: data,
    allAccounts, // Incluímos o novo objeto aqui
    metaAccounts: meta,
    isFetchingAccounts: isFetching,
    fetchAccounts: fetchData,
  };
}

export function useStoreAccount({ type = 1 }) {
  const storeAccount = (account) => {
    if (account.id) {
      return accountsService.updateAccount({ type, ...account });
    }

    return accountsService.storeAccount({ type, ...account });
  };

  return { storeAccount };
}

export function useAjustAccount({ type = 1 }) {
  const ajustAccount = (values) => {
    return accountsService.updateAccountBalance({ type, ...values });
  };

  return { ajustAccount };
}

export function useAccountTransfer() {
  const accountTransfer = (values) => {
    return accountsService.transferValue(values);
  };

  return { accountTransfer };
}

export function useDeleteAccount() {
  const dispatch = useDispatch();

  const getConfig = (account) => {
    if (account.type === 1 || account.type === '1') {
      return {
        title: 'Excluir conta bancária?',
        text: 'Tem certeza que deseja excluir esta conta bancária?',
      };
    }

    return {
      title: 'Excluir cartão de crédito?',
      text: 'Ao excluir um cartão de crédito, todos os dados vinculados a ele serão perdidos. deseja continuar?',
    };
  };

  const deleteAccount =
    ({ account, onSuccess }) =>
    () => {
      const config = getConfig(account);

      dispatch(
        openConfirmation({
          title: config.title,
          text: config.text,
          submitButtonText: 'Excluir',
          request: () => accountsService.destroyAccount(account),
          onSuccess,
        })
      );
    };

  return { deleteAccount };
}
