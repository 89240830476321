import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Col, Row } from 'react-flexbox-grid';
// Components
import { Input } from 'components/Form';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';
import iconLeadfy from 'containers/ModalRoot/ModalLeadfy/leadfy.svg';

function FormRd({ handleClose, handleSubmit }) {
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col xs={3}>
          <img
            src={iconLeadfy}
            alt="Icon Leadfy"
            style={{ margin: '0 auto' }}
          />
        </Col>
        <Col xs>
          <Row style={{ marginBottom: '-15px' }}>
            <Field
              xs={12}
              label="Informe o seu ID de usuário"
              name="client_id"
              component={Input}
            />
          </Row>
        </Col>
      </Row>

      <ModalFooter>
        <Button type="button" color="white" colorText="secondary">
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Conectar
        </Button>
      </ModalFooter>
    </form>
  );
}

FormRd.defaultProps = {};
FormRd.propTypes = {};

export default reduxForm({
  form: 'FormRd',
  enableReinitialize: true,
})(FormRd);
