import { useRequest } from 'hooks/useRequest';
import { indexFavorites } from '../../../services/favorite';

export const useFavorites = () => {
  const {
    data: favorites,
    meta: favoritesMeta,
    isFetching: isFetchingFavorites,
    fetchData: fetchFavorites,
  } = useRequest({
    request: indexFavorites,
    initialFetch: false,
  });

  return {
    favorites,
    favoritesMeta,
    isFetchingFavorites,
    fetchFavorites,
  };
};
