import styled from 'styled-components';

export const Box = styled.div`
  padding: 40px;
  background: #ffffff;
  border-radius: 4px;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 55px;
  justify-content: space-between;

  button {
    margin-top: 25px;
  }
`;

export const BoxLeft = styled.div`
  max-width: 364px;
`;

export const BoxRight = styled.div`
  flex-shrink: 0;

  svg {
    font-size: 90px;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
`;

export const Text = styled.p`
  line-height: 1.5em;
`;

export const Icon = styled.div`
  width: 90px;
  height: 90px;
  display: grid;
  place-items: center;
  border: 6px solid #d3dceb;
  border-radius: 50%;

  svg {
    font-size: 50px;
  }
`;
