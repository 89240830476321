// Components
import Label from 'components/Label';
import StageLabel from 'components/StageLabel';
import { TYPE_NETWORK } from 'constants/options';

const propTypes = {};
const defaultProps = {
  size: 'small',
};

const getNetworkColor = (networkType) => {
  try {
    return TYPE_NETWORK[networkType].color;
  } catch {
    return '#0063C0';
  }
};

/**
 * Retorna a cor com base o status do imóvel
 * @param property
 * @return {Object}
 */
export const colorStatus = (property) => {
  switch (property.status) {
    case 'Excluído':
      return {
        color: 'danger',
        text: 'Inativado',
      };
    case 'Vendido':
      return {
        color: 'success',
        text: 'Vendido',
      };
    case 'Alugado':
      return {
        color: 'caution',
        text: 'Alugado',
      };
    default:
      return { color: '', text: '' };
  }
};

const LabelStatus = ({ canShowBooked, canShowStages, property, ...props }) => {
  if (!property) return null;

  const { color, text } = colorStatus(property);

  if (
    property.status === 'Excluído' ||
    property.status === 'Vendido' ||
    property.status === 'Alugado'
  ) {
    return (
      <Label color={color} {...props}>
        {text}
      </Label>
    );
  }

  if (property?.is_blocked) {
    return <Label color="danger">Bloqueado</Label>;
  }

  if (property.created_on_site && property.is_draft) {
    return (
      <>
        <Label
          color="caution"
          className="h-margin-left--5 h-margin-right--5"
          {...props}
        >
          Rascunho
        </Label>
        <Label color="tertiary">Cadastrado via site</Label>
      </>
    );
  }

  if (property.is_draft)
    return (
      <Label color="caution" {...props}>
        Rascunho
      </Label>
    );

  if (property.is_under_negotiation) {
    return <Label color="caution">Em atendimento</Label>;
  }

  if (canShowStages && property?.stages?.length > 0) {
    return <StageLabel stages={property?.stages} max={2} />;
  }

  if (property.status === 'Disponível') {
    if (property.network_type === 'Órulo') {
      return <Label color="secondary">Órulo</Label>;
    }

    if (property.is_on_network && property.network_type === 'Guru') {
      return (
        <Label
          colorText={getNetworkColor(property.network_type)}
          color={getNetworkColor(property.network_type)}
        >
          Guru
        </Label>
      );
    }

    return null;
  }

  return (
    <Label color={color} {...props}>
      {text}
    </Label>
  );
};

LabelStatus.propTypes = propTypes;
LabelStatus.defaultProps = defaultProps;

export default LabelStatus;
