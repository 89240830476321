import * as Panel from '../Panel';
import { MdOutlineReceipt } from 'react-icons/all';

export function EmptyState() {
  return (
    <Panel.Wrapper>
      <Panel.Content>
        <Panel.Empty>
          <MdOutlineReceipt />
          <h2>Você ainda não cadastrou seu cartão de crédito</h2>
          <p>Tente começar adicionando seus cartões.</p>
        </Panel.Empty>
      </Panel.Content>
      <Panel.Footer>
        <Panel.FullButton to="/financial/credit-card">
          Gerenciar cartões
        </Panel.FullButton>
      </Panel.Footer>
    </Panel.Wrapper>
  );
}
