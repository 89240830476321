import React from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// Components
import FixedBar from 'components/FixedBar';
import Button from 'components/Button';
import { useHistory } from 'react-router';
import Loading from 'components/Loading';
import ListOptions from 'components/ListOptions';
import Container from 'components/Container';
import { FieldBool } from 'components/Form';

const Wrapper = styled.form`
  position: relative;
`;

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

function FormDwv({ handleSubmit, submitting }) {
  const history = useHistory();

  return (
    <Wrapper onSubmit={handleSubmit}>
      {submitting && <Loading isVisible isFullComponent />}
      <Container
        padding
        className="h-margin-top--15"
        style={{ marginBottom: 90 }}
      >
        <ListOptions>
          <ListOptions.item title={<h4>Dados de publicação</h4>} />
          <ListOptions.item
            title="Mostrar Imóveis no site?"
            renderOptions={() => <FieldBool name="is_published" />}
          />
          <ListOptions.item
            title="Mostrar Logradouro?"
            renderOptions={() => <FieldBool name="is_street_shown" />}
          />
          <ListOptions.item
            title="Mostrar número?"
            renderOptions={() => <FieldBool name="is_street_number_shown" />}
          />
          <ListOptions.item
            title="Mostrar Bairro?"
            renderOptions={() => <FieldBool name="is_neighborhood_shown" />}
          />
          <ListOptions.item
            title="Mostrar nome do condomínio?"
            renderOptions={() => <FieldBool name="is_condominium_shown" />}
          />
          <ListOptions.item
            title="Mostrar mapa no site?"
            renderOptions={() => <FieldBool name="is_map_shown" />}
          />
          <ListOptions.item
            title="Mostrar localização exata?"
            renderOptions={() => <FieldBool name="is_exact_map_shown" />}
          />
          <ListOptions.item
            title="Mostrar complemento?"
            renderOptions={() => <FieldBool name="is_complement_shown" />}
          />
          <ListOptions.item
            title="Mostrar o número da unidade?"
            renderOptions={() => <FieldBool name="is_apartment_number_shown" />}
          />
        </ListOptions>
      </Container>

      <FixedBar style={{ left: '240px' }}>
        <FixedBar.item>
          <Button
            type="button"
            color="white"
            colorText="secondary"
            onClick={history.goBack}
          >
            Voltar
          </Button>
        </FixedBar.item>
        <FixedBar.spacer />
        <FixedBar.item>
          <Button color="success" type="submit">
            Salvar
          </Button>
        </FixedBar.item>
      </FixedBar>
    </Wrapper>
  );
}

FormDwv.defaultProps = defaultProps;
FormDwv.propTypes = propTypes;

export default reduxForm({
  form: 'FormDwv',
  enableReinitialize: true,
})(FormDwv);
