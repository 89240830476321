import { Financial } from 'features/Financial';
import * as S from './styles';
import { MdAccessTime } from 'react-icons/md';

export function AccessAvailableSoon() {
  return (
    <Financial.Content>
      <S.Box>
        <S.Wrapper>
          <S.BoxLeft>
            <S.Title>O acesso será liberado em Breve!</S.Title>
            <S.Text>
              Estamos preparando tudo! Assim que o módulo financeiro estiver
              pronto, você será avisado pela barra de notificação e pelo e-mail.
            </S.Text>
          </S.BoxLeft>
          <S.BoxRight>
            <MdAccessTime fill="#D3DCEB" />
          </S.BoxRight>
        </S.Wrapper>
      </S.Box>
    </Financial.Content>
  );
}
