import { ModalFooter, ModalTemplate } from 'components/Modal';
import Modal from 'react-modal';
import { openModal } from 'modules/modal';
import { useShowMoviment } from '../../hooks/useMoviments';
import { useEffect } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import * as S from './styles';
import Button from 'components/Button';
import { DetailActions } from './components/DetailActions';

export const openModalMovimentDetail =
  ({ movimentId, postingId, onSuccess }) =>
  (dispatch) => {
    dispatch(
      openModal({
        type: 'ModalMovimentDetail',
        size: 470,
        position: 'center',
        props: {
          movimentId,
          postingId,
          onSuccess,
        },
      })
    );
  };

export function ModalMovimentDetail({
  modalConfig,
  isOpen,
  handleClose,
  modalType,

  movimentId,
  postingId,
  onSuccess,
}) {
  const { moviment, movimentIsFetching, fetchMoviment } = useShowMoviment();

  useEffect(() => {
    if (movimentId && postingId) {
      fetchMoviment({ postingId, movimentId });
    }
  }, [movimentId, postingId]);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title="Detalhes"
        titleActions={
          <DetailActions
            moviment={moviment}
            onSuccess={onSuccess}
            handleClose={handleClose}
          />
        }
        handleClose={handleClose}
      >
        <Row>
          <Col xs={6}>
            <S.Box>
              <S.Title>Descrição</S.Title>
              <S.Value>{moviment?.posting?.description || '--'}</S.Value>
            </S.Box>
          </Col>
          <Col xs={6}>
            <S.Box>
              <S.Title>Recorrência</S.Title>
              <S.Value>
                {moviment?.posting?.recurrency_type?.title || '--'}
              </S.Value>
            </S.Box>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <S.Box>
              <S.Title>Valor</S.Title>
              <S.Value>{moviment?.value || '--'}</S.Value>
            </S.Box>
          </Col>
          <Col xs={6}>
            <S.Box>
              <S.Title>Data</S.Title>
              <S.Value>{moviment?.billing_date || '--'}</S.Value>
            </S.Box>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <S.Box>
              <S.Title>Conta</S.Title>
              <S.Value>{moviment?.account?.title || '--'}</S.Value>
            </S.Box>
          </Col>
          <Col xs={6}>
            <S.Box>
              <S.Title>Categoria</S.Title>
              <S.Value>{moviment?.posting?.category?.title || '--'}</S.Value>
            </S.Box>
          </Col>
        </Row>
        {moviment.notes ? (
          <Row>
            <Col xs={12}>
              <S.Box>
                <S.Value>Observações</S.Value>
                <S.Textarea>{moviment.notes}</S.Textarea>
              </S.Box>
            </Col>
          </Row>
        ) : null}
        <ModalFooter>
          <span className="h-flex__cell--grow" />
          <Button color="white" onClick={handleClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalTemplate>
    </Modal>
  );
}
