// import styles from './styles'

import Button from 'components/Button';
import Dropdown, { Item, List } from 'components/Dropdown';
import { useModalPosting } from '../../hooks/useModalPosting';
import { MdAdd } from 'react-icons/md';

export function AddPosting({ initialValues, onSuccess }) {
  const { handleOpenModalPosting } = useModalPosting();

  return (
    <Dropdown>
      <Button
        type="button"
        color="secondary"
        size="small"
        className="js-button-posting button-action"
      >
        <MdAdd /> Novo lançamento
      </Button>
      <List>
        <Item>
          <button
            type="button"
            onClick={() => {
              handleOpenModalPosting({
                type: 1,
                posting: initialValues,
                onSuccess,
              });
            }}
          >
            Nova receita
          </button>
        </Item>
        <Item>
          <button
            type="button"
            onClick={() => {
              handleOpenModalPosting({
                type: 2,
                posting: initialValues,
                onSuccess,
              });
            }}
          >
            Nova despesa
          </button>
        </Item>
      </List>
    </Dropdown>
  );
}
