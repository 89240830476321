import _ from 'lodash';
import { createSelector } from 'reselect';
// Widgets.js
import { createModule } from '../lib/reducer-helpers';

export const STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
  UPLOADING: 'uploading',
};

const createAction = createModule('fileUpload');

export const getFiles = (state) => state.fileUpload.files;
export const getIsUploading = (state) => state.fileUpload.isUploading;
export const getFilesUploading = createSelector([getFiles], (files) => {
  return files.filter((file) => file.status === STATUS.UPLOADING);
});

export const getFilesFinished = createSelector([getFiles], (files) => {
  return files.filter((file) => file.status !== STATUS.UPLOADING);
});

export const getFilesErrors = createSelector([getFiles], (files) => {
  return files.filter((file) => file.status === STATUS.ERROR);
});

export const getIsOpen = (state) => state.fileUpload.isOpen;
export const getIsMinimized = (state) => state.fileUpload.isMinimized;

// Actions
const ADD_FILE = createAction('ADD_FILE');
const REMOVE_FILE = createAction('REMOVE_FILE');
const CHANGE_STATUS = createAction('CHANGE_STATUS');
const RESET = createAction('RESET');
const OPEN = createAction('OPEN');
const CLOSE = createAction('CLOSE');
const MINIMIZE = createAction('MINIMIZE');
const MAXIMIZE = createAction('MAXIMIZE');
const UPLOAD_START = createAction('UPLOAD_START');
const UPLOAD_END = createAction('UPLOAD_END');

const initialState = {
  files: [],
  isOpen: false,
  isMinimized: true,
  isUploading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_FILE:
      return {
        ...state,
        files: [...state.files, action.file],
        isOpen: true,
      };
    case REMOVE_FILE:
      return {
        ...state,
        files: _.reject(state.files, { name: action.fileName }),
      };
    case CHANGE_STATUS:
      return {
        ...state,
        files: state.files.map((file) => {
          if (file.preview === action.file.preview) {
            return { ...file, status: action.status };
          }
          return file;
        }),
      };
    case OPEN:
      return { ...state, isOpen: true };
    case CLOSE:
      return { ...state, isOpen: false };
    case MINIMIZE:
      return { ...state, isMinimized: true };
    case MAXIMIZE:
      return { ...state, isMinimized: false };
    case UPLOAD_START:
      return { ...state, isUploading: true };
    case UPLOAD_END:
      return { ...state, isUploading: false };
    case RESET:
      return initialState;
    default:
      return state;
  }
}

export const Actions = {
  addFile: (file) => ({
    type: ADD_FILE,
    file: {
      ...file,
      name: file.name,
      type: file.type,
      status: STATUS.UPLOADING,
    },
  }),
  removeFile: (fileName) => ({ type: REMOVE_FILE, fileName }),
  uploadSuccess: (file) => ({
    type: CHANGE_STATUS,
    file,
    status: STATUS.SUCCESS,
  }),
  uploadError: (file) => ({ type: CHANGE_STATUS, file, status: STATUS.ERROR }),
  open: () => ({ type: OPEN }),
  close: () => ({ type: CLOSE }),
  uploadStart: () => ({ type: UPLOAD_START }),
  uploadEnd: () => ({ type: UPLOAD_END }),
  minimize: () => ({ type: MINIMIZE }),
  maximize: () => ({ type: MAXIMIZE }),
  reset: () => ({ type: RESET }),
};
