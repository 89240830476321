import { Financial } from 'features/Financial';
import * as S from './styles';
import { useDispatch } from 'react-redux';
import Button from 'components/Button';
import { MdDone } from 'react-icons/md';
import { financialStartFreeTrial } from '../../../../modules/statusGuard';

export function RequestAccessGranted() {
  const dispatch = useDispatch();

  return (
    <Financial.Content>
      <S.Box>
        <S.Wrapper>
          <S.BoxLeft>
            <S.Title>Acesso ao Módulo Financeiro liberado!</S.Title>
            <S.Text>
              O Módulo Financeiro está disponível para o seu período de teste
              gratuito. Aproveite agora para explorar todas as funcionalidades e
              facilitar a gestão das suas finanças!
            </S.Text>
            <Button
              color="success"
              onClick={() => {
                dispatch(financialStartFreeTrial());
              }}
            >
              Iniciar teste de 7 dias
            </Button>
          </S.BoxLeft>
          <S.BoxRight>
            <S.Icon>
              <MdDone fill="#D3DCEB" />
            </S.Icon>
          </S.BoxRight>
        </S.Wrapper>
      </S.Box>
    </Financial.Content>
  );
}
