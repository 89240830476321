import Table from 'components/Table';
import * as S from './styles';
import { Nodata } from './Nodata';
import {
  useModalMovimentDestroy,
  useModalMovimentDetail,
  useModalPosting,
} from 'features/Financial';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';
import { useMovimentPay } from '../../../../hooks/useMoviments';
import { useHistory } from 'react-router';
import classnames from 'classnames';
import { MOVIMENT_TYPES } from '../../../../constants';
import { useModalBillPay } from '../../../../modals/ModalBillPay/useModalBillPay';
import { MdAttachMoney } from 'react-icons/md';

export function TableMoviments({ data }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleOpenModalPosting } = useModalPosting();
  const { handleOpenModalMovimentDestroy } = useModalMovimentDestroy();
  const { handleOpenModalMovimentDetail } = useModalMovimentDetail();
  const { handleMovimentPay } = useMovimentPay();
  const { handleOpenModalBillPay } = useModalBillPay();

  const handleClickCell = (e) => {
    e.stopPropagation();
  };

  const hasCreditCardId = (item, classNames) => {
    return classnames(classNames, {
      'h-link': item.credit_card_id,
    });
  };

  if (!data || data.length <= 0) {
    return <Nodata />;
  }

  return (
    <S.WrapperTable>
      <Table
        data={data}
        renderTitle={() => (
          <tr>
            <th width={100}>Data</th>
            <th width={120}>Ocorrência</th>
            <th>Descrição</th>
            <th width={110} className="Table__cell--money">
              Valor
            </th>
            <th width={80} />
          </tr>
        )}
        renderItem={(item) => {
          // Verificação se é uma fatura
          const isBill = item.moviment_type === MOVIMENT_TYPES.BILL;

          return (
            <tr
              key={item.id}
              onClick={() => {
                if (!item.credit_card_id) {
                  handleOpenModalMovimentDetail({
                    movimentId: item.id,
                    postingId: item.posting_id,
                  });
                } else {
                  history.push(
                    `/financial/credit-card/${item.credit_card_id}/${item.id}`
                  );
                }
              }}
            >
              <td>
                <S.Date situation={item.situation}>{item.billing_date}</S.Date>
              </td>
              <td>{item.ocurrency}</td>
              <td className={hasCreditCardId(item)}>
                <S.Ball
                  color={item?.category.color}
                  data-tip={item?.category.title}
                />
                {item?.description}
              </td>
              <td className={'Table__cell--money h-text-nowrap'}>
                {item.value}
              </td>
              <td onClick={handleClickCell}>
                {item.situation === 'Vencido' || item.situation === 'Aberto' ? (
                  <S.Situation
                    variant={item.situation}
                    onClick={() => {
                      // Não pode despagar quando for uma fatura de cartão
                      // E a situação for igual a Pago
                      if (isBill && item.situation === 'Pago') return false;

                      // Se for uma fatura abre a modal de pagamento de cartão
                      if (isBill) {
                        handleOpenModalBillPay({
                          moviment: item,
                          onSuccess: () => {
                            // Atualiza a listagem
                            dispatch(submit('FilterPostings'));
                          },
                        });
                      } else {
                        // Abre a modal de pagamento de movimento
                        handleMovimentPay({
                          moviment: item,
                          onSuccess: () => {
                            // Atualiza a listagem
                            dispatch(submit('FilterPostings'));
                          },
                        });
                      }
                    }}
                  >
                    <MdAttachMoney fill="#31D084" />
                    Pagar
                  </S.Situation>
                ) : (
                  <S.Text>Pago</S.Text>
                )}
              </td>
            </tr>
          );
        }}
      />
    </S.WrapperTable>
  );
}
