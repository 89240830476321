import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
// Components
import Button from 'components/Button';
import { Input } from 'components/Form';
import Select from 'components/Form/components/Select';
import ModalFooter from 'components/Modal/components/ModalFooter';
import ModalTemplate from 'components/Modal/components/ModalTemplate';
import validate from './validade';
// Container
import {
  CityField,
  CountryField,
  NeighborhoodField,
  StateField,
} from 'containers/LocationFields';
// Helpers
import { TYPE_PERSONS } from 'constants/options';
import FieldCep from 'containers/FieldCep';
import { useCurrentRealEstate } from 'hooks/useCurrentRealEstate';
import { normalizeCnpj, normalizeCpf, normalizePhone } from 'lib/formHelpers';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const RegisterDomainForm = ({
  handleClose,
  handleSubmit,
  url,
  typePerson,
  country_id,
  state_id,
  city_id,
  change,
}) => {
  const realEstate = useCurrentRealEstate();

  useEffect(() => {
    change('name', realEstate.name);
    change('person_type', realEstate.type);
    change('email', realEstate.contact_email);

    change('country_id', realEstate?.relations?.country_id);
    change('state_id', realEstate?.relations?.state_id);
    change('city_id', realEstate?.relations?.city_id);
    change('neighborhood_id', realEstate?.neighborhood_id);

    if (realEstate.type === '1' || realEstate.type === 1) {
      change('cpf', realEstate.cpf_cnpj);
    } else {
      change('cnpj', realEstate.cpf_cnpj);
    }
    change('cep', realEstate.address_cep);
  }, [realEstate]);

  return (
    <ModalTemplate
      title={`Registrar domínio - ${url}`}
      handleClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <Row>
          <Field
            xs={5}
            name="name"
            component={Input}
            label="Nome do responsável"
            required
            placeholder="Nome do responsável"
          />
          <Field
            xs={3}
            label="Cliente"
            name="person_type"
            component={Select}
            options={TYPE_PERSONS}
            buttonTemplate
            required
            onChange={() => {
              change('cpf', null);
              change('cnpj', null);
            }}
          />
          <Field
            required
            xs={4}
            disabled={!typePerson}
            name={typePerson === '1' ? 'cpf' : 'cnpj'}
            component={Input}
            label={typePerson === '1' ? 'CPF' : 'CNPJ'}
            normalize={typePerson === '1' ? normalizeCpf : normalizeCnpj}
            placeholder={
              typePerson === '1' ? '000.000.000-00' : '00.000.000/0000-00'
            }
          />
        </Row>
        <Row>
          <Field
            xs={6}
            name="phone"
            component={Input}
            label="Telefone"
            required
            normalize={normalizePhone}
            placeholder="(00) 0 0000-0000"
          />
          <Field
            xs={6}
            name="email"
            component={Input}
            label="E-mail"
            required
            placeholder="E-mail"
          />
        </Row>
        <Row>
          <FieldCep
            xs={3}
            onChangeLocation={(location) => {
              change('country_id', location.country.id || null);
              change('state_id', location.state.id || null);
              change('city_id', location.city.id || null);
              change('neighborhood_id', location.neighborhood.id || null);
              change('street_address', location.street_address || '');
            }}
          />
          <CountryField xs={3} name="country_id" />
          <StateField xs={3} name="state_id" countryId={country_id} />
          <CityField xs={3} name="city_id" stateId={state_id} />
          <NeighborhoodField
            xs={12}
            label="Bairro"
            name="neighborhood_id"
            cityId={city_id}
          />
        </Row>
        <Row>
          <Field
            xs={9}
            name="street_address"
            component={Input}
            label="Logradouro"
            required
            placeholder="Logradouro"
          />
          <Field
            xs={3}
            name="street_number"
            component={Input}
            label="Número"
            required
            placeholder="Núm"
          />
        </Row>
        <ModalFooter>
          <Button color="white" colorText="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <span className="h-flex__cell--grow" />
          <Button type="submit" color="success">
            Solicitar Registro
          </Button>
        </ModalFooter>
      </form>
    </ModalTemplate>
  );
};

RegisterDomainForm.propTypes = propTypes;

const selector = formValueSelector('RegisterDomainForm');

const mapStateToProps = (state) => ({
  url: selector(state, 'url'),
  typePerson: selector(state, 'person_type'),
  country_id: selector(state, 'country_id'),
  state_id: selector(state, 'state_id'),
  city_id: selector(state, 'city_id'),
});

const wrapperRegisterForm = reduxForm({
  form: 'RegisterDomainForm',
  validate,
  enableReinitialize: true,
})(RegisterDomainForm);

export default connect(mapStateToProps)(wrapperRegisterForm);
