import * as S from './styles';

export function MetaInformations({ meta }) {
  return (
    <S.Wrapper>
      <ul>
        <li>
          <S.Title>Despesas pagas</S.Title>
          <S.Title>{meta.debits}</S.Title>
        </li>
        <li>
          <S.Text>Despesas em aberto</S.Text>
          <S.Text>{meta.futureDebits}</S.Text>
        </li>
      </ul>
      <ul>
        <li>
          <S.Title>Recebido</S.Title>
          <S.Title>{meta.credits}</S.Title>
        </li>
        <li>
          <S.Text>Recebimentos em aberto</S.Text>
          <S.Text>{meta.futureCredits}</S.Text>
        </li>
      </ul>
      <S.Divider />
      <ul>
        <li>
          <S.Highlight>Saldo hoje</S.Highlight>
          <S.Highlight>{meta.balance}</S.Highlight>
        </li>
        <li>
          <S.Text>Saldo previsto</S.Text>
          <S.Text>{meta.futureBalance}</S.Text>
        </li>
      </ul>
    </S.Wrapper>
  );
}
