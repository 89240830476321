import * as categoriesService from 'features/Financial/services/categories';
import { useRequest } from 'hooks/useRequest';
import { useDispatch } from 'react-redux';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { useMemo } from 'react';

export function useGetCategories() {
  const { data, meta, isFetching, fetchData } = useRequest({
    request: categoriesService.indexCategory,
    initialFetch: false,
  });

  return {
    categories: data,
    metaCategories: meta,
    isFetchingCategories: isFetching,
    fetchCategories: fetchData,
  };
}

export function useGetAllCategories() {
  const { categories, fetchCategories, metaCategories, isFetchingCategories } =
    useGetCategories();

  const allCategories = useMemo(() => {
    const flattenCategories = [];

    categories.forEach((category) => {
      flattenCategories.push({
        ...category,
        isCategory: true,
        children: undefined,
      });

      // Adiciona as subcategorias (children) com isSubtype: true
      if (category.children && category.children.length > 0) {
        category.children.forEach((subCategory) => {
          flattenCategories.push({
            ...subCategory,
            category,
            isSubcategory: true,
          });
        });
      }
    });

    return flattenCategories;
  }, [categories]);

  return {
    allCategories,
    categories,
    fetchCategories,
    metaCategories,
    isFetchingCategories,
  };
}

export function useStoreCategories({ isDebit, isEditing }) {
  const storeCategory = (category) => {
    if (isEditing && category.id) {
      return categoriesService.updateCategory({
        ...category,
        is_debit: isDebit,
      });
    }
    if (category.parent_id) {
      return categoriesService.storeCategory({
        ...category,
        is_debit: isDebit,
      });
    }

    return categoriesService.storeCategory({ ...category, is_debit: isDebit });
  };

  return { storeCategory };
}

export function useDeleteCategories() {
  const dispatch = useDispatch();

  const deleteCategory =
    ({ category, onSuccess }) =>
    () => {
      const isSubcategory = category.parent_id;

      dispatch(
        openConfirmation({
          title: isSubcategory ? 'Deletar sub-categoria' : 'Deletar categoria',
          text: (
            <p>
              Voce realmente deseja deletar a{' '}
              {isSubcategory ? 'sub-categoria' : 'categoria'}:{' '}
              <strong>{category.title}</strong>
            </p>
          ),
          request: () => categoriesService.deleteCategory(category),
          onSuccess,
        })
      );
    };

  return { deleteCategory };
}
