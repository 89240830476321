import { useCallback, useState } from 'react';
import Modal from 'react-modal';
// Components
import { ModalFooter, ModalTemplate } from '../../../components/Modal';
import Form from './components/Form';
// Services
import Button from 'components/Button';
import * as crmIntegration from 'services/settings/crm-integration';
import { Col, Row } from 'react-flexbox-grid';
import iconLeadfy from './leadfy.svg';

export default function ModalLeadfy({
  modalConfig,
  isOpen,
  modalType,
  handleClose,

  onSubmitSuccess,
}) {
  const [isShowForm, setShowForm] = useState(false);

  const handleSubmit = useCallback(async (values) => {
    try {
      await crmIntegration.connect(values, 'leadfy');
      return values;
    } catch (e) {
      return e;
    }
  }, []);

  const handleSuccess = useCallback(({ client_id, ...rest }) => {
    // Fecha a modal
    handleClose();

    if (onSubmitSuccess) onSubmitSuccess({ client_id, ...rest });
  }, []);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title="Integração com gestor de leads Leadfy"
        handleClose={handleClose}
      >
        {isShowForm ? (
          <Form
            onSubmit={handleSubmit}
            onSubmitSuccess={handleSuccess}
            handleClose={handleClose}
          />
        ) : (
          <>
            <Row>
              <Col xs={3}>
                <img
                  src={iconLeadfy}
                  alt="Icon Leadfy"
                  style={{ margin: '0 auto' }}
                />
              </Col>
              <Col xs>
                <p>
                  Ao ativar este recurso, todos os contatos recebidos no
                  Tecimob, independentemente do canal de origem, serão
                  automaticamente enviados para o Leadfy. Isso garante que seus
                  leads sejam centralizados em uma única plataforma, facilitando
                  o acompanhamento e a gestão.
                </p>
              </Col>
            </Row>
            <ModalFooter>
              <span className="h-flex__cell--grow" />
              <Button onClick={() => setShowForm(true)} color="success">
                Continuar
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalTemplate>
    </Modal>
  );
}
