import React, { useCallback, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import MainTitle from 'components/MainTitle';
import SeeExample from 'pages/EnterpriseStore/components/SeeExample';
import {
  FieldBool,
  Input,
  RadioButton,
  SelectBox,
  SelectMultiple as Select,
  Textarea,
} from 'components/Form';
import { Field, reduxForm } from 'redux-form';
import { FORM_NAME } from 'pages/EnterpriseStore/constants';
import FormFixedBar from 'pages/EnterpriseStore/components/FormFixedBar';
import Content from 'pages/EnterpriseStore/components/Content';
import ListOptions from 'components/ListOptions';
import { MdInfo } from 'react-icons/md';
import Tooltip from 'react-tooltip';
import { useEnterprise } from 'pages/EnterpriseStore/context';
import { useRequest } from 'hooks/useRequest';
import { get, update } from 'services/buildings/contactForm';
import useFormValue from 'hooks/useFormValue';
import Profile from './components/Profile';
import FormLabel from 'components/Form/components/FormLabel';
import {
  getRealtors,
  isFetchingRealtorsSelector,
  realtorsSelector,
} from 'modules/realtors';
import { useDispatch, useSelector } from 'react-redux';
import { parseSelect } from 'lib/formHelpers';

const ContactForm = ({ handleSubmit, initialize, change }) => {
  const dispatch = useDispatch();
  const realtors = useSelector(realtorsSelector);
  const isFetchingRealtor = useSelector(isFetchingRealtorsSelector);

  const form_new_lead_option = useFormValue('form_new_lead_option');
  const form_already_registered_option = useFormValue(
    'form_already_registered_option'
  );
  const contact_form_active = useFormValue('contact_form_active');
  const contact_form_register = useFormValue('contact_form_register');

  const { enterpriseId } = useEnterprise();
  const { data, isFetching } = useRequest({
    request: get,
    params: enterpriseId,
  });

  const toString = (value) => {
    if (typeof value === 'boolean') return value?.toString();
    if (!value) return '';
    return value?.toString();
  };

  const emptySelect = (fieldName) => () => {
    change(fieldName, null);
  };

  const handleChangePhoneShow = useCallback((value) => {
    change('contact_form_btn_whatsapp_show', value);

    if (!value) {
      change('contact_form_btn_email_show', true);
    }
  }, []);

  const className = useMemo(() => {
    return classnames({
      'h-disabled': !contact_form_active,
    });
  }, [contact_form_active]);

  useEffect(() => {
    dispatch(getRealtors());
    initialize(data);
  }, [data]);

  useEffect(() => {
    Tooltip.rebuild();
  }, [isFetching]);

  return (
    <form onSubmit={handleSubmit}>
      <MainTitle
        title="Formulário de contato"
        text="Defina como será o formulário de contato presente no seu hotsite."
      >
        <SeeExample />
      </MainTitle>
      <Content padding isFetching={isFetching}>
        <ListOptions>
          <ListOptions.item
            title="Deseja ativar o formulário de contatos?"
            text="Será exibido um formulário padrão que coleta nome, telefone, e-mail e mensagem"
            renderOptions={() => <FieldBool name="contact_form_active" />}
          />

          {contact_form_active ? (
            <>
              <ListOptions.item
                title="Registrar os contatos no sistema?"
                text="Defina se os contatos recebidos pelo hotsite serão registrados no sistema."
                renderOptions={() => <FieldBool name="contact_form_register" />}
              >
                {contact_form_register ? (
                  <div style={{ padding: '10px 20px' }}>
                    <div className="h-margin-top--10">
                      <FormLabel>
                        Novos clientes que fizerem contato, devem ser
                        encaminhados para:
                      </FormLabel>
                      <Field
                        label="Um corretor específico"
                        name="form_new_lead_option"
                        value="1"
                        type="radio"
                        component={RadioButton}
                        onChange={emptySelect('form_new_lead_user_ids')}
                        format={toString}
                      />
                      {form_new_lead_option?.toString?.() === '1' && (
                        <Field
                          multi={false}
                          name="form_new_lead_user_ids"
                          component={Select}
                          options={realtors}
                          isLoading={isFetchingRealtor}
                          valueKey="id"
                          labelKey="name"
                          parse={parseSelect('id')}
                          style={{
                            width: '230px',
                            margin: '10px 0',
                          }}
                        />
                      )}
                      <Field
                        label="Executar rodízio entre TODOS os corretores"
                        name="form_new_lead_option"
                        value="2"
                        type="radio"
                        component={RadioButton}
                        onChange={emptySelect('form_new_lead_user_ids')}
                        format={toString}
                      />
                      <Field
                        label="Executar rodízio entre corretores SELECIONADOS"
                        name="form_new_lead_option"
                        value="3"
                        type="radio"
                        component={RadioButton}
                        onChange={emptySelect('form_new_lead_user_ids')}
                        format={toString}
                      />
                      {form_new_lead_option?.toString?.() === '3' ? (
                        <Field
                          minOptions={1}
                          name="form_new_lead_user_ids"
                          component={SelectBox}
                          options={realtors}
                          isLoading={isFetchingRealtor}
                          valueKey="id"
                          labelKey="name"
                          style={{ width: 250, marginTop: '10px' }}
                        />
                      ) : null}
                    </div>
                    <div className="h-margin-top--10">
                      <FormLabel>
                        Clientes que já possuam um corretor responsável, devem
                        ser encaminhados para:
                      </FormLabel>
                      <Field
                        label="Para o corretor responsável pelo cliente"
                        name="form_already_registered_option"
                        value="1"
                        type="radio"
                        component={RadioButton}
                        onChange={emptySelect(
                          'form_already_registered_user_ids'
                        )}
                        format={toString}
                      />
                      <Field
                        label="Um corretor específico"
                        name="form_already_registered_option"
                        value="2"
                        type="radio"
                        component={RadioButton}
                        onChange={emptySelect(
                          'form_already_registered_user_ids'
                        )}
                        format={toString}
                      />
                      {form_already_registered_option?.toString?.() === '2' ? (
                        <Field
                          multi={false}
                          name="form_already_registered_user_ids"
                          component={Select}
                          options={realtors}
                          isLoading={isFetchingRealtor}
                          valueKey="id"
                          labelKey="name"
                          parse={parseSelect('id')}
                          style={{
                            width: '230px',
                            margin: '10px 0',
                          }}
                        />
                      ) : null}
                      <Field
                        label="Executar rodízio entre TODOS os corretores"
                        name="form_already_registered_option"
                        value="3"
                        type="radio"
                        component={RadioButton}
                        onChange={emptySelect(
                          'form_already_registered_user_ids'
                        )}
                        format={toString}
                      />
                      <Field
                        label="Executar rodízio entre corretores SELECIONADOS"
                        name="form_already_registered_option"
                        value="4"
                        type="radio"
                        component={RadioButton}
                        onChange={emptySelect(
                          'form_already_registered_user_ids'
                        )}
                        format={toString}
                      />
                      {form_already_registered_option?.toString?.() === '4' ? (
                        <Field
                          minOptions={1}
                          name="form_already_registered_user_ids"
                          component={SelectBox}
                          options={realtors}
                          isLoading={isFetchingRealtor}
                          valueKey="id"
                          labelKey="name"
                          style={{ width: 250, marginTop: '10px' }}
                        />
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </ListOptions.item>

              {contact_form_register ? (
                <>
                  <ListOptions.item
                    title="Mostrar dados do corretor no site?"
                    text="Será exibido os dados do corretor selecionado ou da vez no rodízio."
                    renderOptions={() => (
                      <>
                        <FieldBool
                          label="Foto?"
                          name="contact_form_picture_show"
                        />
                        <FieldBool
                          label="Nome?"
                          name="contact_form_name_show"
                        />
                        <FieldBool
                          label={
                            <>
                              Telefone?{' '}
                              <MdInfo
                                style={{ display: 'inline-block' }}
                                className="h-color--secondary"
                                data-tip="Necessário para ativar o contato por whatsapp"
                              />
                            </>
                          }
                          name="contact_form_phone_show"
                          onChange={handleChangePhoneShow}
                        />
                        <FieldBool
                          label="E-mail?"
                          name="contact_form_email_show"
                        />
                      </>
                    )}
                  />
                  <ListOptions.item
                    title="Mostrar o botão do WhatsApp"
                    text="Manter um botão de WhatsApp no formulário."
                    renderOptions={() => (
                      <FieldBool name="contact_form_btn_whatsapp_show" />
                    )}
                  />
                  <ListOptions.item
                    title="Mostrar o botão do E-mail?"
                    text="Manter um botão de E-mail no formulário."
                    renderOptions={() => (
                      <FieldBool name="contact_form_btn_email_show" />
                    )}
                  />
                </>
              ) : (
                <>
                  <ListOptions.item
                    title="Qual e-mail irá receber os contatos feitos neste formulário?"
                    text="Todo contato no formulário deste hotsite será enviado a este endereço"
                    renderOptions={() => (
                      <Field
                        type="email"
                        name="contact_form_destiny_email"
                        component={Input}
                      />
                    )}
                  />
                </>
              )}
            </>
          ) : null}
        </ListOptions>
      </Content>

      <Content padding style={{ marginTop: '20px' }}>
        <ListOptions>
          <ListOptions.item
            title="Texto padrão do formulário"
            text="Informe o texto que será apresentado como padrão no formulário de contato."
          >
            <Field
              type="simple"
              className="h-margin-top--10"
              name="contact_form_text"
              component={Textarea}
            />
          </ListOptions.item>
        </ListOptions>
      </Content>

      {contact_form_active ? <Profile formName={FORM_NAME} /> : null}

      <FormFixedBar />
    </form>
  );
};

export default reduxForm({
  form: FORM_NAME,
  onSubmit: update,
})(ContactForm);
