import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import styled from 'styled-components';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

// Components
import { ModalFooter, ModalTemplate } from 'components/Modal';
import api from 'services';
import { Input } from 'components/Form';
import Button from 'components/Button';
import iconDanger from '../../assets/icon-danger.png';
import { useRequest } from 'hooks/useRequest';
import { Link } from 'react-router-dom';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export const Form = styled.form`
  .Modal__header {
    background: #f00;
    color: #fff;
  }
`;

export const WrapperImage = styled.div`
  img {
    margin: 0 auto;
  }
`;

const Text = styled.div`
  h2 {
    font-size: 14px;
    margin-bottom: 4px;
  }

  p + h2 {
    margin-top: 20px;
  }

  p {
    font-size: 14px;
  }
`;

function FormRemoveAccount({ submitting, handleSubmit, handleClose }) {
  const { data } = useRequest({
    request: () => api.delete('real-estates/me-preview'),
  });

  return (
    <Form onSubmit={handleSubmit}>
      <ModalTemplate title="Exclusão de conta" handleClose={handleClose}>
        <Row>
          <Col xs={3}>
            <WrapperImage>
              <img src={iconDanger} alt="" />
            </WrapperImage>
          </Col>
          <Col xs={9}>
            <Text>
              <h2>Exclusão de conta</h2>
              <p>
                A exclusão da sua conta é um processo irreversível, serão
                deletados todos os dados existentes no sistema, incluíndo dados
                de clientes, crm, fotos e imóveis.
              </p>
              <h2>Backup</h2>
              <p>
                Recomendamos que você baixe uma cópia dos seus dados antes de
                prosseguir. Para acessar a área de backup{' '}
                <Link
                  to="/config/bkp/show"
                  className="h-link"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  clique aqui
                </Link>
                .
              </p>

              {data.show_balance && (
                <>
                  <h2>Saldo</h2>
                  <p>
                    Atualmente você possui um saldo{' '}
                    <strong>{data.balance}</strong> que será enviado ao
                    administrador da conta.
                  </p>
                </>
              )}

              <h2>Confirmação</h2>
              <p className="h-margin-bottom--10">
                Para prosseguir com a exclusão, escreva ”
                <strong>
                  <i>excluir minha conta</i>
                </strong>
                ” na caixa abaixo:
              </p>
              <Row>
                <Field xs={5} name="confirm" component={Input} />
              </Row>
            </Text>
          </Col>
        </Row>

        <ModalFooter>
          <Button
            type="button"
            color="white"
            colorText="secondary"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <span className="h-flex__cell--grow" />
          <Button type="submit" color="danger" disabled={submitting}>
            Excluir minha conta e deletar meus dados
          </Button>
        </ModalFooter>
      </ModalTemplate>
    </Form>
  );
}

FormRemoveAccount.defaultProps = defaultProps;
FormRemoveAccount.propTypes = propTypes;

export default reduxForm({
  form: 'FormRemoveAccount',
  enableReinitialize: true,
})(FormRemoveAccount);
