import React from 'react';
import PropTypes from 'prop-types';
// Components
import Button from '../../../../../components/Button';
import ModalTemplate from '../../../../../components/Modal/components/ModalTemplate';
// Assets
import successImage from './image-success.png';
import * as S from './styles';

const propTypes = {
  handleClose: PropTypes.func,
};
const defaultProps = {
  handleClose: () => {},
};

const RegisterDomainSucess = ({ handleClose }) => (
  <ModalTemplate
    handleClose={handleClose}
    footerActions={
      <>
        <span className="h-flex__cell--grow"></span>
        <Button color="white" onClick={handleClose}>
          Fechar
        </Button>
      </>
    }
  >
    <S.Content>
      <img
        className="h-image-center h-margin-bottom--15"
        src={successImage}
        alt="Dados enviados com sucesso"
      />
      <S.Text>
        <h2 className={'h-text-center h-margin-bottom--15'}>Parabéns!</h2>
        <p className={'h-text-center h-margin-bottom--10'}>
          Seu domínio foi enviado para registro!
          <br /> Estará pronto em até 1 dia útil.
        </p>
        <p className="h-text-center h-margin-bottom--10">
          Você receberá um aviso informando a ativação
        </p>
      </S.Text>
    </S.Content>
  </ModalTemplate>
);

RegisterDomainSucess.propTypes = propTypes;
RegisterDomainSucess.defaultProps = defaultProps;

export default RegisterDomainSucess;
