import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
// Components
import Button from 'components/Button';
import FormUpload from 'pages/Site/pages/ContentSite/pages/Slides/components/FormUpload';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const FormUploadSlides = ({
  onChange,
  style,
  styleButtonCreateSlide,
  handleClickCreateSlide,
  handleClickSendSlide,
}) => (
  <div style={{ display: 'inline-block', ...style }}>
    <Row>
      <Col xs={0}>
        <Button
          style={styleButtonCreateSlide}
          type="button"
          to="/site/content-site/slides/create"
          color="secondary"
          onClick={handleClickCreateSlide}
        >
          Criar Slide
        </Button>
      </Col>
      <Col xs={0}>
        <FormUpload
          onChange={onChange}
          handleClickSendSlide={handleClickSendSlide}
        />
      </Col>
    </Row>
  </div>
);

FormUploadSlides.defaultProps = defaultProps;
FormUploadSlides.propTypes = propTypes;

export default FormUploadSlides;
